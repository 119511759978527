import { useEffect, useState } from "react";
import { JobChannel } from "../../components/channel/job-channel.component";
import { Tables } from "@/lib/supabase";
import {
	ChatApp,
	ChatPreviewCard,
	ChatPreviews,
} from "../../components/chat/chat";
import { useCentralStore } from "../../store/Central";
import { useNavigate } from "react-router-dom";

export interface SelectedChannel {
	jobId: number | null;
	lastMessageCreatedBy: string | null;
	medOrganizationId: string | null;
	jobClientId: string | null;
}

export const Channels: React.FC<{
	latestChannels: Tables<"message_channels">[];
	organizationId: string;
	userId: string;
}> = ({ latestChannels, organizationId, userId }) => {
	const { clientsLookup, profilesForChat } = useCentralStore((state) => ({
		clientsLookup: state.clientsLookup,
		profilesForChat: state.profilesForChat,
	}));
	const [selectedChannel, setSelectedChannel] =
		useState<SelectedChannel | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (latestChannels.length > 0) {
			setSelectedChannel({
				jobId: latestChannels[0].job_id,
				lastMessageCreatedBy: latestChannels[0].last_message_created_by,
				medOrganizationId: latestChannels[0].job_shared_with,
				jobClientId: latestChannels[0].job_client_id,
			});
		}
	}, [latestChannels]);

	if (latestChannels.length === 0) {
		return <div></div>;
	}
	return (
		<ChatApp className="h-[calc(100vh-220px)]">
			<ChatPreviews>
				{latestChannels.map((channel, index) => {
					return (
						<ChatPreviewCard
							key={index}
							title={
								clientsLookup[
									(channel as any).job_client_id ?? ""
								]?.first_name +
								" " +
								clientsLookup[
									(channel as any).job_client_id ?? ""
								]?.last_name
							}
							subtitle={channel.job_title ?? ""}
							messageAt={channel.last_message_at ?? ""}
							messageContent={
								channel.last_message_content as string
							}
							isSender={
								channel.last_message_created_by === userId
							}
							active={selectedChannel?.jobId === channel.job_id}
							onClick={() => {
								setSelectedChannel({
									jobId: channel.job_id,
									lastMessageCreatedBy:
										channel.last_message_created_by,
									medOrganizationId: channel.job_shared_with,
									jobClientId: channel.job_client_id,
								});
							}}
						/>
					);
				})}
			</ChatPreviews>
			{selectedChannel && (
				<JobChannel
					profileLookup={profilesForChat}
					organizationId={organizationId}
					jobId={selectedChannel?.jobId}
					recipientOrgId={selectedChannel?.medOrganizationId}
					goTo={() => {
						navigate(
							`/client/${selectedChannel.jobClientId}/job/${selectedChannel?.jobId}`
						);
					}}
				/>
			)}
		</ChatApp>
	);
};
