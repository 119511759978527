import { StateCreator } from "zustand";
import {
	PrintersConfig,
	ProfileOfConnectRelationship,
	ProfilesForChat,
	State,
} from "./types";
import { initialState } from "./utils";
import { supabase } from "../../../../lib/supabase";
import { useCentralStore } from ".";
import { Logger } from "../../../../lib/logger/Logger";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import {
	GuarantorEntityType,
	BankAccountEntityType,
	SupabaseTableEnum,
	SupabaseShareEntityViewEnum,
} from "@/lib/supabase/supabaseTypes";
import { JobStatusEnum } from "@/lib/types/job";
import { useDesktopsStore } from "../Desktops";
import { showNotification } from "./selectors";
import { useTemplatesStore } from "../Templates/templates.store";
import { useUserManagementStore } from "../UserManagement";
import { getAvatarUrl } from "@/lib/utils/get-avatar-url";

export interface CentralSlice extends State {
	getSession: () => Promise<boolean>;
	// Get profile and get organization are separated to be able to fetch them separately
	getProfile: () => Promise<void>;
	initialize: () => Promise<void>;
	updateSearchConfig: (search_config_job_list: {
		statuses: Record<JobStatusEnum, number>;
	}) => Promise<void>;
	updatePrintersConfig: (printersConfig: PrintersConfig) => Promise<void>;
	getOrganization: () => Promise<void>;
	getTariffs: () => Promise<void>;
	getArticles: () => Promise<void>;
	getGuarantorLookup: () => Promise<void>;
	getBankAccounts: () => Promise<void>;
	handleSignOut: () => Promise<void>;
	setMFADialog: (open: boolean) => void;
	verifyMFA: () => Promise<{
		mfaVerified: boolean;
		mfaAuthenticated: boolean;
		mfaEnrolled: boolean;
	}>;
	setupMFA: (verifyCode: string, factorId: string) => Promise<void>;
	updatePrivacy: (privacy: boolean) => Promise<void>;
	getConnectedProfiles: () => Promise<void>;
	deleteTariff: (tariffId: number) => Promise<void>;
	deleteArticle: (articleId: number) => Promise<void>;
	getProfilesPerConnectRelationship: () => Promise<void>;
	getProfilesForChat: (organizationId: string) => Promise<void>;
}

export const createCentralStore: StateCreator<CentralSlice> = (set, get) => ({
	...initialState,
	initialize: async () => {
		const {
			initialized,
			getSession,
			getTariffs,
			getArticles,
			getClients,
			getProfile,
			getOrganization,
			getGuarantorLookup,
			fetchGridConfig,
			verifyMFA,
			getBankAccounts,
			getConnectedProfiles,
			getProfilesPerConnectRelationship,
			getProfilesForChat,
		} = useCentralStore.getState();

		// If the initialize function is already run before
		// prevent it from running again and causing rerenders
		if (initialized) {
			Logger.warn("Central store already initialized");
			return;
		}

		const loggedIn = await getSession();

		// Only continue if the user is logged in
		if (!loggedIn) {
			Logger.warn("[Sign in] User is not logged in");
			return;
		}
		const { mfaAuthenticated } = await verifyMFA();

		if (!mfaAuthenticated) {
			Logger.error("MFA not authenticated");
			return;
		}
		Logger.log("[Sign in] User is logged in", loggedIn);

		await getProfile();
		await getOrganization();
		await getTariffs();
		await getArticles();
		await getClients();
		await getGuarantorLookup();
		await fetchGridConfig();
		await getBankAccounts();
		await getProfilesForChat(get().organization?.id ?? "");

		set({ initialized: true });

		await getConnectedProfiles();
		await getProfilesPerConnectRelationship();

		await useTemplatesStore.getState().fetchTemplates();
		await useDesktopsStore
			.getState()
			.fetchDesktops(get().organization?.id ?? "");
		await useUserManagementStore.getState().getMembers();
	},
	getSession: async () => {
		Logger.log("[Sign in] Retrieving session");
		try {
			const { data } = await supabase.auth.getSession();

			if (!data || !data.session) {
				Logger.error("No session found");
				return false;
			}

			const { user: userInformation } = data.session;
			const {
				id: userId,
				email: userEmail,
				user_metadata: userMetaData,
				app_metadata: appMetaData,
			} = userInformation;
			const { is_connect_user: isConnectUser } = userMetaData;
			const { role } = appMetaData;

			set({
				userId,
				userEmail,
				isConnectUser: isConnectUser || false,
				role,
			});

			return true;
		} catch (error) {
			Logger.error(error, {}, "Error retrieving session");
			return false;
		}
	},
	getProfile: async () => {
		Logger.log("[Sign in] Getting profile");
		const { userId } = get();
		if (!userId) {
			Logger.error("[Sign in] User ID is not set");
			return;
		}
		const { data, error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.select()
			.eq("id", userId);

		if (error) {
			Logger.error(error);
			await get().handleSignOut();
			return;
		}

		if (data.length === 0) {
			Logger.error("[Sign in] No profile found");
			await get().handleSignOut();
			return;
		}

		const profile = data[0];
		Logger.log("[Sign in] Profile", profile);
		set({
			profile,
		});
		if (profile?.search_config_job_list) {
			set({
				searchConfig: profile.search_config_job_list as {
					statuses: Record<JobStatusEnum, number>;
				},
			});
		}
		if (profile?.printers_config) {
			set({
				printersConfig: profile.printers_config as PrintersConfig,
			});
		}
	},
	getOrganization: async () => {
		const profile = get().profile;
		if (!profile || !profile.organization_id) return;

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.ORGANIZATIONS)
				.select()
				.eq("id", profile?.organization_id)
		);

		if (error) {
			Logger.error(error);
			return;
		}
		if (data) {
			set({
				organization: data[0],
			});
		}
	},
	updateSearchConfig: async (searchConfigJobList: {
		statuses: Record<JobStatusEnum, number>;
	}) => {
		const userId = useCentralStore.getState().userId;
		if (!userId) return Logger.error("Can't update search config");

		const { error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.update({
				search_config_job_list: searchConfigJobList,
			})
			.eq("id", userId);

		if (error) {
			Logger.error("Can't update search config");
			return;
		}

		set({
			searchConfig: searchConfigJobList,
		});
	},
	updatePrintersConfig: async (printersConfig: PrintersConfig) => {
		const userId = useCentralStore.getState().userId;
		if (!userId) {
			showNotification({
				message: "Fehler beim Aktualisieren der Drucker-Konfiguration",
				type: "error",
			});
			Logger.error("Can't update printers config");
			return;
		}

		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.PROFILES)
				.update({
					printers_config: printersConfig,
				})
				.eq("id", userId)
		);

		if (error) {
			showNotification({
				message: "Fehler beim Aktualisieren der Drucker-Konfiguration",
				type: "error",
			});
			Logger.error("Can't update printers config");
			return;
		}

		set({
			printersConfig,
		});
	},
	getTariffs: async () => {
		const organizationId = get().organization?.id;

		if (!organizationId) {
			Logger.error("Organization id is not set for tariffs");
			return;
		}
		const { data: defaultData, error } = await supabase
			.from(SupabaseTableEnum.TARIFFS_DEFAULT)
			.select("*");

		if (error) {
			Logger.error(error);
			return;
		}

		const { data: userTariffs, error: userTariffsError } = await supabase
			.from(SupabaseTableEnum.TARIFFS)
			.select("*")
			.eq("organization_id", organizationId);

		if (userTariffsError) {
			Logger.error(userTariffsError);
			return;
		}

		set({
			tariffs: {
				// default tariffs are tariffs from tariffs_default table
				...defaultData.reduce((acc, curr) => {
					// @ts-expect-error acc isn't a fixed map
					acc[curr.code_e] = {
						...curr,
						custom: false,
					};
					return acc;
				}, {}),
				// user tariffs are user-added tariffs from tariffs table
				// They must override default tariffs
				...userTariffs.reduce((acc, curr) => {
					// @ts-expect-error acc isn't a fixed map
					acc[curr.code_e] = {
						...curr,
						custom: true,
					};
					return acc;
				}, {}),
			},
		});
	},
	getArticles: async () => {
		const organizationId = get().organization?.id;

		if (!organizationId) {
			Logger.error("Organization id is not set");
			return;
		}
		const { data: defaultArticles, error } = await supabase
			.from(SupabaseTableEnum.ARTICLES_DEFAULT)
			.select("*");

		if (error) {
			Logger.error(error);
			return;
		}

		const { data: userArticles, error: userArticlesError } = await supabase
			.from(SupabaseTableEnum.ARTICLES)
			.select("*")
			.eq("organization_id", organizationId);

		if (userArticlesError) {
			Logger.error(userArticlesError);
			return;
		}

		set({
			articles: {
				...defaultArticles.reduce((acc, curr) => {
					// @ts-expect-error acc isn't a fixed map
					acc[curr.code_e] = {
						...curr,
						custom: false,
					};
					return acc;
				}, {}),
				// User articles must override default articles
				...userArticles.reduce((acc, curr) => {
					// @ts-expect-error acc isn't a fixed map
					acc[curr.code_e] = {
						...curr,
						custom: true,
					};
					return acc;
				}, {}),
			},
		});
	},
	getGuarantorLookup: async () => {
		const organizationId = get().organization?.id;

		if (!organizationId) {
			Logger.error("Organization id is not set");
			return;
		}

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.GUARANTORS)
				.select("*")
				.eq("organization_id", organizationId)
				.order("code", { ascending: true })
		);

		if (error) {
			Logger.error(error);
			return;
		}

		const guarantorLookup = data?.reduce(
			(
				acc: Record<string, GuarantorEntityType>,
				curr: GuarantorEntityType
			) => {
				acc[curr.id] = curr;
				return acc;
			},
			{}
		);
		set({
			guarantorLookup,
		});
	},
	getBankAccounts: async () => {
		const organizationId = get().organization?.id;
		if (!organizationId) {
			Logger.error("Organization id is not set");
			return;
		}
		const { data, error } = await supabase
			.from(SupabaseTableEnum.BANK_ACCOUNTS)
			.select("*")
			.eq("organization_id", organizationId);

		if (error) {
			Logger.error(error);
			return;
		}

		set({
			bankAccountsLookup: data.reduce(
				(
					acc: Record<string, BankAccountEntityType>,
					curr: BankAccountEntityType
				) => {
					acc[curr.id] = curr;
					return acc;
				},
				{}
			),
		});
	},
	handleSignOut: async () => {
		try {
			const { error } = await supabase.auth.signOut();
			if (error) throw error;

			set(initialState);

			window.location.replace("/");
		} catch (error) {
			Logger.error(error, {}, "Error signing out:");
			showNotification({
				message: "Fehler beim Abmelden. Bitte versuchen Sie es erneut.",
				type: "error",
			});
		}
	},
	setMFADialog: (open: boolean) => {
		set({
			mfaDialogOpen: open,
		});
	},
	verifyMFA: async () => {
		const { data, error } =
			await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
		let mfaVerified = false,
			mfaAuthenticated = false,
			mfaEnrolled = false;
		if (error) {
			Logger.error(error);
			return {
				mfaAuthenticated,
				mfaEnrolled,
				mfaVerified,
			};
		}

		if (data.nextLevel === "aal2" && data.nextLevel !== data.currentLevel) {
			mfaEnrolled = true;
			mfaAuthenticated = false;
			set({
				mfaEnrolled,
				mfaAuthenticated,
			});
		} else if (
			data.nextLevel === "aal1" &&
			data.nextLevel === data.currentLevel
		) {
			mfaEnrolled = false;
			mfaAuthenticated = true;
			set({
				mfaEnrolled: false,
				mfaAuthenticated: true,
			});
		} else if (
			data.nextLevel === "aal2" &&
			data.nextLevel === data.currentLevel
		) {
			mfaEnrolled = true;
			mfaAuthenticated = true;
			set({
				mfaEnrolled: true,
				mfaAuthenticated: true,
			});
		}
		mfaVerified = true;
		set({
			mfaVerified,
		});

		return {
			mfaVerified,
			mfaAuthenticated,
			mfaEnrolled,
		};
	},
	setupMFA: async (verifyCode: string, factorId: string) => {
		const challenge = await supabase.auth.mfa.challenge({ factorId });
		if (challenge.error) {
			throw challenge.error;
		}

		const challengeId = challenge.data.id;

		const verify = await supabase.auth.mfa.verify({
			factorId,
			challengeId,
			code: verifyCode,
		});
		if (verify.error) {
			throw verify.error;
		}
		set({
			mfaEnrolled: true,
		});
		showNotification({
			message: "MFA erfolgreich eingerichtet",
			type: "success",
		});
	},
	updatePrivacy: async (privacy: boolean) => {
		const profile = get().profile;
		if (!profile) return;

		set({
			profile: {
				...profile,
				data_privacy_mode: privacy ? new Date().toISOString() : null,
			},
		});

		const { error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.update({
				data_privacy_mode: privacy ? new Date().toISOString() : null,
			})
			.eq("id", profile.id);

		if (error) {
			Logger.error(error, {}, "Error updating Privacy");
			set({
				profile,
			});
		}
	},
	getConnectedProfiles: async () => {
		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.CONNECTED_PROFILES_FOR_LAB)
			.select();
		if (error) {
			Logger.error(
				error,
				{},
				"Error fetching connected profiles for lab"
			);
			return;
		}

		const connectedProfiles = data.reduce((acc, row) => {
			if (row.profile_id) {
				acc[row.profile_id] = row;
			}
			return acc;
		}, get().connectedProfiles);

		set({
			connectedProfiles,
		});
	},
	getProfilesPerConnectRelationship: async () => {
		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.CR_WITH_PROFILES)
			.select();
		if (error) {
			Logger.error(
				error,
				{},
				"Error fetching profiles per connect relationship"
			);
			return;
		}
		set({
			profilesPerConnectRelationship: data.reduce(
				(acc, curr) => {
					if (curr.connect_relationship_id) {
						const newProfile: ProfileOfConnectRelationship = {
							first_name: curr.first_name ?? "",
							last_name: curr.last_name ?? "",
							email: curr.email ?? "",
							profile_id: curr.profile_id ?? "",
						};
						if (!acc[curr.connect_relationship_id]) {
							acc[curr.connect_relationship_id] = [newProfile];
						} else {
							acc[curr.connect_relationship_id].push(newProfile);
						}
					}
					return acc;
				},
				{} as Record<string, ProfileOfConnectRelationship[]>
			),
		});
	},
	deleteTariff: async (tariffId: number) => {
		const { error } = await supabase
			.from(SupabaseTableEnum.TARIFFS)
			.delete()
			.eq("id", tariffId);

		if (error) {
			showNotification({
				message: "Fehler beim Löschen des Tarifs",
				type: "error",
			});
			Logger.error(error, {}, "Error deleting tariff");
			return;
		}
		await get().getTariffs();
		showNotification({
			message: "Tarif erfolgreich gelöscht",
			type: "success",
		});
	},
	deleteArticle: async (articleId: number) => {
		const { error } = await supabase
			.from(SupabaseTableEnum.ARTICLES)
			.delete()
			.eq("id", articleId);

		if (error) {
			showNotification({
				message: "Fehler beim Löschen des Artikels",
				type: "error",
			});
			Logger.error(error, {}, "Error deleting article");
			return;
		}
		await get().getArticles();
		showNotification({
			message: "Artikel erfolgreich gelöscht",
			type: "success",
		});
	},
	getProfilesForChat: async (organizationId: string) => {
		// First get profiles from the organization
		const { data: profiles, error: profilesError } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.select("*")
			.eq("organization_id", organizationId);

		if (profilesError) {
			Logger.error(profilesError, {}, "Error fetching profiles");
			return;
		}

		// Get connected profiles with their emails from cr_with_profiles
		const { data: connectedProfiles, error } = await supabase
			.from(SupabaseShareEntityViewEnum.CONNECTED_PROFILES_FOR_LAB)
			.select();
		if (error) {
			Logger.error(error, {}, "Error fetching connected profiles");
			return;
		}

		// Process organization profiles
		const orgProfilePromises = profiles.map(async (row) => ({
			profile_id: row.id ?? "",
			first_name: row.first_name ?? "",
			last_name: row.last_name ?? "",
			email: row.email ?? "",
			avatar_url: row.avatar_url
				? await getAvatarUrl(organizationId, row.avatar_url)
				: "",
		}));

		// Process connected profiles
		const connectedProfilePromises = connectedProfiles.map(async (row) => ({
			profile_id: row.profile_id ?? "",
			first_name: row.first_name ?? "",
			last_name: row.last_name ?? "",
			email: row.email ?? "",
			avatar_url: row.avatar_url
				? await getAvatarUrl(row.med_id ?? "", row.avatar_url)
				: "",
		}));

		// Wait for all avatar URLs to be generated
		const [orgProfiles, connectedProfilesWithUrls] = await Promise.all([
			Promise.all(orgProfilePromises),
			Promise.all(connectedProfilePromises),
		]);

		const profilesForChat = [...orgProfiles, ...connectedProfilesWithUrls];

		const profilesLookup = profilesForChat.reduce(
			(acc, profile) => {
				acc[profile.profile_id] = profile;
				return acc;
			},
			{} as Record<string, ProfilesForChat>
		);

		set({
			profilesForChat: profilesLookup,
		});
	},
});
