import { StateCreator } from "zustand";
import { supabase } from "../../supabase/supabaseClient";
import {
	SupabaseTableEnum,
	SupabaseViewEnum,
} from "../../supabase/supabaseTypes";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { Logger } from "../../logger/Logger";
import { Tables } from "../../supabase/supabaseTypesImport";
import { TextMessage, MessageType } from "./message.types";
import { produce } from "immer";

export const getChannelKeyFromChannelId = (channelId: ChannelId) => {
	return `${channelId?.type === ChannelType.JOB ? channelId?.id : ""}_${
		channelId?.type === ChannelType.PATIENT ? channelId?.id : ""
	}_${
		channelId?.type === ChannelType.CONNECT_RELATIONSHIP
			? channelId?.id
			: ""
	}`;
};

export const getChannelKeyFromRow = (
	row: Tables<"messages"> | Tables<"message_channels">
) => {
	return `${row.job_id ?? ""}_${(row as any)?.patient_id ?? ""}_${
		(row as any)?.connect_relationship_id ?? ""
	}`;
};

// IMPORTANT: These enum values correspond directly to database column names.
// Each value is used to construct column names by appending '_id'
// (e.g., 'patient' becomes 'patient_id' in the database)
export enum ChannelType {
	PATIENT = "patient",
	JOB = "job",
	CONNECT_RELATIONSHIP = "connect_relationship",
}

export type ChannelId =
	| {
			type: ChannelType.PATIENT;
			id: string;
	  }
	| {
			type: ChannelType.JOB;
			id: number;
	  }
	| {
			type: ChannelType.CONNECT_RELATIONSHIP;
			id: string;
	  }
	| null;

type ChannelReplyTexts = {
	[key: string]: string;
};

export interface MessageSlice {
	channelId: ChannelId;
	channelRecipientOrgId: string | null;
	changeChannel: (channelId: ChannelId, recipientOrgId: string) => void;
	replyTexts: ChannelReplyTexts;
	messagesLoading: boolean;
	// For the overview of all chats, we store and subscribe to all messages
	messages: Record<string, Tables<"messages">[]>;
	updateMessages: (
		payload: RealtimePostgresChangesPayload<{ [key: string]: any }>
	) => void;
	fetchMessages: (channelId: ChannelId) => void;
	sendTextMessage: (text: string) => void;
	fetchLatestChannels: () => void;
	latestChannels: Tables<"message_channels">[];
	getReplyText: () => string;
	setReplyText: (text: string) => void;
}

const initialState: MessageSlice = {
	messagesLoading: false,
	messages: {},
	channelId: null,
	channelRecipientOrgId: null,
	replyTexts: {},
	latestChannels: [],
	setReplyText: () => {},
	getReplyText: () => "",
	changeChannel: () => {},
	updateMessages: () => {},
	fetchMessages: () => {},
	fetchLatestChannels: () => {},
	sendTextMessage: () => {},
};

export const createMessageStore: StateCreator<
	MessageSlice,
	[],
	[],
	MessageSlice
> = (set, get) => ({
	...initialState,

	setReplyText: (text: string) => {
		const channelId = get().channelId;
		if (!channelId) return;

		const channelKey = getChannelKeyFromChannelId(channelId);

		set(
			produce((state) => {
				state.replyTexts[channelKey] = text;
			})
		);
	},

	getReplyText: () => {
		const channelId = get().channelId;
		if (!channelId) return "";

		const channelKey = getChannelKeyFromChannelId(channelId);
		return get().replyTexts[channelKey] || "";
	},

	changeChannel: async (channelId: ChannelId, recipientOrgId: string) => {
		set({ channelId, channelRecipientOrgId: recipientOrgId });
		get().fetchMessages(channelId);
	},

	updateMessages: (payload) => {
		if (payload.eventType !== "INSERT") {
			Logger.warn("Event type not Insert for updating reply");
			return;
		}

		set(
			produce((state) => {
				const channelKey = getChannelKeyFromRow(
					payload.new as Tables<"messages">
				);

				state.messages[channelKey] = [
					...(state.messages[channelKey] || []),
					payload.new,
				];

				const index = state.latestChannels.findIndex(
					(channel: Tables<"message_channels">) =>
						getChannelKeyFromRow(channel) === channelKey
				);

				if (index === -1) {
					get().fetchLatestChannels();
				} else {
					const channelToMove = state.latestChannels[index];
					state.latestChannels.splice(index, 1);
					state.latestChannels.unshift({
						...channelToMove,
						last_message_at: payload.new.created_at,
						last_message_content: payload.new.content,
					});
				}
			})
		);
	},

	fetchMessages: async (channelId) => {
		set({ messagesLoading: true });
		if (!channelId) return;

		let eqOperator = "job_id";
		if (channelId.type === "patient") {
			eqOperator = "patient_id";
		} else if (channelId.type === "job") {
			eqOperator = "job_id";
		} else if (channelId.type === "connect_relationship") {
			eqOperator = "connect_relationship_id";
		}

		const { data, error } = await supabase
			.from(SupabaseTableEnum.MESSAGES)
			.select()
			.eq(eqOperator, channelId.id)
			.order("created_at", { ascending: true });

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			set({
				messages: {
					...get().messages,
					[getChannelKeyFromChannelId(channelId)]: data,
				},
			});
		}

		set({
			messagesLoading: false,
		});
	},

	fetchLatestChannels: async () => {
		const { data, error } = await supabase
			.from(SupabaseViewEnum.MESSAGE_CHANNELS)
			.select("*")
			.order("last_message_at", { ascending: false })
			.limit(50);

		if (error) {
			Logger.error(error);
			return;
		}

		set({
			latestChannels: data,
		});
	},

	sendTextMessage: async (text: string) => {
		const channelId = get().channelId;
		if (!channelId) return;

		const channelKey = getChannelKeyFromChannelId(channelId);

		const recipientOrgId = get().channelRecipientOrgId;
		if (!recipientOrgId) {
			Logger.error("No recipient org id found");
			return;
		}

		if (!channelId || !channelId.type || !channelId.id) {
			Logger.error("No channel id found");
			return;
		}

		const cleanedText = text
			.replace(/<p><br><\/p>(\s*<p><br><\/p>)+/g, "<p><br></p>") // reduce multiple <p><br></p> to one
			.replace(/^(\s*<p><br><\/p>)+/, "") // remove empty paragraphs at the beginning
			.replace(/(\s*<p><br><\/p>)+$/, ""); // remove empty paragraphs at the end

		const isOnlyHtmlTags =
			cleanedText.replace(/<[^>]*>/g, "").trim().length === 0;

		if (isOnlyHtmlTags) {
			set(
				produce((state) => {
					state.replyTexts[channelKey] = "";
				})
			);
			return;
		}

		const messageContent: TextMessage = {
			type: MessageType.TEXT,
			text: cleanedText,
		};

		// Note that sender_id and sender_org_id are set on the database
		const { error } = await supabase
			.from(SupabaseTableEnum.MESSAGES)
			.insert({
				content: JSON.stringify(messageContent),
				[`${channelId.type}_id`]: channelId.id,
				recipient_org_id: recipientOrgId,
			});

		if (error) {
			Logger.error("Error sending text message", {}, error);
		}

		// We don't set state here, because the realtime subscription will update the messages

		set(
			produce((state) => {
				state.replyTexts[channelKey] = "";
			})
		);
	},
});
