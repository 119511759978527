import { supabase } from "../supabase";
import { StorageBucketsEnum } from "../../dentlab/src/types/enums";
import { Logger } from "../logger/Logger";

export const getAvatarUrl = async (
	organizationId: string,
	avatarPath: string | null
) => {
	if (!avatarPath || !organizationId) return "";

	const { data, error } = await supabase.storage
		.from(StorageBucketsEnum.PUBLIC_IMAGES)
		.createSignedUrl(`${organizationId}/${avatarPath}`, 3600, {
			transform: {
				quality: 35,
			},
		});

	if (error) {
		Logger.error(error);
		return "";
	}

	return data?.signedUrl ?? "";
};
