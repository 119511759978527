import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useCentralStore } from "../../store/Central";
import { CustomDateRangePicker } from "../../components/custom-date-range-picker/custom-date-range-picker.component";
import { PageTitle } from "@/components/src/page-title/page-title.component";
import { Loading } from "@/components/src/animations/loading";
import { supabase } from "@/lib/supabase/supabaseClient";
import { Logger } from "@/lib/logger/Logger";
import { showNotification } from "../../store/Central/selectors";
import { CustomToggleButtonGroup } from "../../components/custom-toggle-button/custom-toggle-button-group.component";
import { CustomSelect } from "../../components/custom-select.component";
import {
	ClientEntityType,
	SupabaseRpcEnum,
} from "@/lib/supabase/supabaseTypes";
import { JobStatusEnum } from "@/lib/types/job";
import { ProfilesForChat } from "../../store/Central/types";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { CustomMultiSelect } from "../../components/custom-multi-select/custom-multi-select.component";
import { useUserManagementStore } from "../../store/UserManagement";
import { Member } from "../../store/UserManagement/user-management.store";

interface FilterOption {
	id: string;
	name: string;
}

type SortDirection = "asc" | "desc" | null;
type SortConfig = {
	column: keyof JobItemStatsRow | "total" | null;
	direction: SortDirection;
};

const formatNumber = (num: number, decimals?: number) => {
	return num.toLocaleString("de-CH", {
		minimumFractionDigits: decimals ?? 2,
		maximumFractionDigits: decimals ?? 2,
	});
};

export const StatisticsPage = () => {
	const { organizationId, clientsLookup } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
		clientsLookup: state.clientsLookup,
	}));

	const { membersLookup } = useUserManagementStore((state) => ({
		membersLookup: state.membersLookup,
	}));

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [timeGrouping, setTimeGrouping] = useState<number>(0);
	const [grouping, setGrouping] = useState<number>(0);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<JobItemStatsRow[]>([]);
	const [selectedSecondFilter, setSelectedSecondFilter] = useState<string[]>(
		[]
	);
	const [selectedStatus, setSelectedStatus] = useState<JobStatusEnum[]>([]);
	const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
	const [selectedStatistics, setSelectedStatistics] = useState<string>("0");
	const [processedData, setProcessedData] = useState<Record<
		string,
		JobItemStatsRow[]
	> | null>(null);

	const statusOptions = [
		{ label: "Geliefert", value: JobStatusEnum.COMPLETED },
		{
			label: "Verbucht (Einzelrechnung)",
			value: JobStatusEnum.BOOKED_SINGLE,
		},
		{
			label: "Verbucht (Sammelrechnung)",
			value: JobStatusEnum.BOOKED_MONTHLY,
		},
		{
			label: "Bezahlt (Sammelrechnung)",
			value: JobStatusEnum.PAID_MONTHLY,
		},
		{ label: "Bezahlt (Einzelrechnung)", value: JobStatusEnum.PAID_SINGLE },
	];

	const statisticsOptions = [
		{ label: "Labor", value: 0 },
		{ label: "Auftraggeber", value: 1 },
		{ label: "Techniker", value: 2 },
		{ label: "Tarife & Artikel", value: 3 },
	];

	const timeGroupingOptions = [
		{ label: "TAG", value: 0 },
		{ label: "WOCHE", value: 1 },
		{ label: "MONAT", value: 2 },
		{ label: "JAHR", value: 3 },
	];

	const groupingOptions = [
		{
			label:
				statisticsOptions.find(
					(option) =>
						Number(option.value) === Number(selectedStatistics)
				)?.label ?? "Labor",
			value: 0,
		},
		{ label: "Zeitraum", value: 1 },
	];

	useEffect(() => {
		let options: FilterOption[] = [];
		if (selectedStatistics === "1") {
			options = Object.entries(clientsLookup).map(([id, client]) => ({
				id: id,
				name:
					client.company_name ??
					`${client.title} ${client.first_name} ${client.last_name}`,
			}));
		} else if (selectedStatistics === "2") {
			options = Object.entries(membersLookup)
				.filter(([id, profile]) => (profile.first_name !== "-" && profile.last_name !== "-"))
				.map(([id, profile]) => ({
					id: id,
					name: `${profile.first_name} ${profile.last_name}`,
				}));
		}
		setFilterOptions(options);
	}, [selectedStatistics, clientsLookup, membersLookup]);

	useEffect(() => {
		fetchData();
	}, [selectedStatistics, organizationId, startDate, endDate]);

	useEffect(() => {
		if (!data || data.length === 0) {
			setProcessedData(null);
			return;
		}

		// First filter by status if needed
		let statusFilteredData = [...data];
		if (selectedStatus.length > 0) {
			statusFilteredData = statusFilteredData.filter((row) =>
				selectedStatus.includes(row.status)
			);
		}

		// Then filter by selected second filter if any
		if (
			selectedSecondFilter.length > 0 &&
			statusFilteredData &&
			selectedStatistics !== "0"
		) {
			statusFilteredData = statusFilteredData.filter((row) =>
				selectedSecondFilter.includes(
					row.client_id || row.technician_id || ""
				)
			);
		}

		let result = {};

		// grouping === 1 means we are grouping by date
		if (grouping === 1) {
			result = groupByTimeWithEntities(
				statusFilteredData,
				timeGrouping,
				selectedStatistics
			);
		} else {
			// Bestehende Gruppierung nach Statistik
			if (selectedStatistics === "0") {
				result = groupByDate(statusFilteredData, timeGrouping);
			} else if (selectedStatistics === "1") {
				result = groupByClient(statusFilteredData, timeGrouping);
			} else if (selectedStatistics === "2") {
				result = groupByTechnician(statusFilteredData, timeGrouping);
			} else if (selectedStatistics === "3") {
				result = groupByCode(statusFilteredData, timeGrouping);
			}
		}

		setProcessedData(result);
	}, [
		timeGrouping,
		data,
		selectedSecondFilter,
		selectedStatus,
		selectedStatistics,
		grouping,
	]);

	const changeDate = (value: { start: Date | null; end: Date | null }) => {
		Logger.info("changeDate", value);

		if (value.start !== null && value.end !== null) {
			if (
				// Typescript doesn't allow for difference between dates to be calculated, so we have to suppress the ts error
				// @ts-expect-error cast
				((value.end - value.start) as number) >
				1000 * 60 * 60 * 24 * 366
			) {
				return;
			}
		}
		setStartDate(value.start);
		setEndDate(value.end);
	};

	const fetchData = async () => {
		setData([]);
		setProcessedData(null);
		if (!organizationId) {
			Logger.error("No organization id");
			return;
		}

		if (startDate === null || endDate === null) {
			return;
		}

		setLoading(true);
		Logger.info("fetching data");

		let rpcFunction = SupabaseRpcEnum.GET_JOB_ITEMS_CLIENT_STATS;

		if (selectedStatistics === "0" || selectedStatistics === "1") {
			rpcFunction = SupabaseRpcEnum.GET_JOB_ITEMS_CLIENT_STATS;
		} else if (selectedStatistics === "2") {
			rpcFunction = SupabaseRpcEnum.GET_JOB_ITEMS_TECHN_STATS;
		} else if (selectedStatistics === "3") {
			rpcFunction = SupabaseRpcEnum.GET_JOB_ITEMS_CODE_STATS;
		}

		let allData: JobItemStatsRow[] = [];
		let currentStartDate = new Date(startDate);
		const finalEndDate = new Date(endDate);

		while (currentStartDate <= finalEndDate) {
			// Create dates at start and end of day in Swiss time
			const swissStartDate = new Date(currentStartDate);
			swissStartDate.setHours(0, 0, 0, 0);

			const swissEndDate = new Date(finalEndDate);
			swissEndDate.setHours(23, 59, 59, 999);

			const { data: chunkData, error } = (await supabase.rpc(
				rpcFunction as any,
				{
					start_date: swissStartDate.toLocaleDateString("sv"),
					end_date: swissEndDate.toLocaleDateString("sv"),
				}
			)) as { data: JobItemStatsRow[] | null; error: any };

			if (error) {
				Logger.error(error);
				showNotification({
					message: "Fehler beim Laden der Daten",
					type: "error",
				});
				break;
			}

			if (!chunkData || chunkData.length === 0) {
				break;
			}

			// Sort by date to ensure we can find the last date
			const sortedChunkData = [...(chunkData as JobItemStatsRow[])].sort(
				(a, b) =>
					new Date(a.date).getTime() - new Date(b.date).getTime()
			);

			// Remove any duplicates from the previous chunk
			const newData = sortedChunkData.filter(
				(row) =>
					!allData.some(
						(existing) =>
							existing.date === row.date &&
							(selectedStatistics === "2"
								? "technician_id" in row &&
									existing.technician_id === row.technician_id
								: "client_id" in row &&
									existing.client_id === row.client_id)
					)
			);

			allData = [...allData, ...newData];

			// If we got less than 1000 rows, we have all the data
			if (chunkData.length < 1000) {
				break;
			}

			// Set the start date to the day after the last received date
			const lastDate = new Date(
				sortedChunkData[sortedChunkData.length - 1].date
			);
			currentStartDate = new Date(lastDate);
			currentStartDate.setDate(currentStartDate.getDate() + 1);
		}

		Logger.info("retrieved data", allData);
		setData(allData);
		setLoading(false);
	};

	return (
		<div className="flex flex-col px-12 py-6 h-full">
			<PageTitle title={[{ name: "Statistik" }]} />
			<div className="flex flex-col gap-6 py-4">
				<div className="sticky top-0 bg-white z-10 pb-4">
					<div className="flex flex-row items-center gap-6">
						<div className="flex flex-col items-start mt-4">
							<CustomSelect
								inputLabel="Statistik"
								options={statisticsOptions.map((option) => ({
									label: option.label,
									value: String(option.value),
								}))}
								value={selectedStatistics}
								onChange={(value) => {
									if (value !== selectedStatistics) {
										setSelectedSecondFilter([]);
									}
									setSelectedStatistics(value);
									if (value === "0") {
										setGrouping(0);
									}
								}}
								props={{
									sx: {
										height: "38px",
										minWidth: "150px",
									},
								}}
							/>
						</div>
						<div className="flex flex-col items-start">
							<CustomDateRangePicker
								handleDateChange={changeDate}
								dateVal={{ start: startDate, end: endDate }}
							/>
						</div>
					</div>
					<div className="flex flex-row items-center gap-6 mt-4">
						{selectedStatistics !== "0" && (
							<div className="flex flex-col items-start">
								<p className="text-sm text-gray-500">
									Gruppierung wählen
								</p>
								<CustomToggleButtonGroup
									value={grouping}
									onChange={(value) =>
										setGrouping(value ?? 0)
									}
									options={groupingOptions}
									props={{
										sx: {
											height: "38px",
											"& .MuiToggleButton-root": {
												padding: "6px 16px",
												borderColor:
													"rgba(0, 0, 0, 0.37)",
											},
										},
									}}
								/>
							</div>
						)}
						<div className="flex flex-col items-start">
							<p className="text-sm text-gray-500">
								Zeitraum gruppieren nach
							</p>
							<CustomToggleButtonGroup
								value={timeGrouping}
								onChange={(value) =>
									setTimeGrouping(value ?? 0)
								}
								options={timeGroupingOptions}
								props={{
									sx: {
										height: "38px",
										"& .MuiToggleButton-root": {
											padding: "6px 16px",
											borderColor: "rgba(0, 0, 0, 0.37)",
										},
									},
								}}
							/>
						</div>
						<div className="flex flex-row items-start gap-6">
							<div className="flex flex-col items-start">
								<p className="text-sm text-gray-500">
									Auftragsstatus filtern
								</p>
								<CustomMultiSelect
									data={statusOptions}
									textField="name"
									valueField="value"
									value={selectedStatus}
									onChange={setSelectedStatus}
									width="200px"
									maxHeight="30vh"
								/>
							</div>
							{selectedStatistics !== "0" &&
								selectedStatistics !== "3" && (
									<div className="flex flex-col items-start">
										<p className="text-sm text-gray-500">
											{selectedStatistics === "1"
												? "Auftraggeber filtern"
												: "Techniker filtern"}
										</p>
										<CustomMultiSelect
											data={filterOptions}
											textField="name"
											valueField="id"
											value={selectedSecondFilter}
											onChange={setSelectedSecondFilter}
											width="200px"
											maxHeight="30vh"
										/>
									</div>
								)}
						</div>
					</div>
				</div>
				<TableWithJobItemsStats
					membersLookup={membersLookup}
					grouping={grouping}
					selectedStatistics={selectedStatistics}
					startDate={startDate}
					endDate={endDate}
					processedData={processedData}
					loading={loading}
					clientsLookup={clientsLookup}
				/>
			</div>
		</div>
	);
};

interface JobItemStatsRow {
	date: string;
	displayDate?: string;
	client_id?: string;
	technician_id?: string;
	code_e?: string;
	status: number;
	total_jobs: number;
	total_job_items?: number;
	amount?: number;
	type?: number;
	amount_tariff: number;
	amount_article_material: number;
	amount_article_postage: number;
	amount_article_external_work: number;
	amount_article_discounts: number;
	amount_article_fixed_rate: number;
	amount_warranty: number;
	organization_id: string;
}

const formatDateByGrouping = (
	date: Date,
	groupingKey: number
): { groupKey: string; displayDate: string } => {
	let groupKey = "";
	let displayDate = "";

	if (groupingKey === 0) {
		// Daily grouping
		groupKey = date.toISOString().split("T")[0];
		displayDate = new Date(date).toLocaleDateString("de-CH");
	} else if (groupingKey === 1) {
		// Weekly grouping
		const thursday = new Date(date.getTime());
		thursday.setDate(date.getDate() + 4 - (date.getDay() || 7)); // Sunday is 0, Monday is 1, etc. --> Tuesday is 2 so Thursday is  2 days after Tuesday
		const firstDayOfYear = new Date(thursday.getFullYear(), 0, 1);
		const weekNumber = Math.ceil(
			((thursday.getTime() - firstDayOfYear.getTime()) / 86400000 + 1) / 7
		);

		const weekStart = new Date(date);
		weekStart.setDate(date.getDate() - (date.getDay() || 7) + 1);
		const weekEnd = new Date(weekStart);
		weekEnd.setDate(weekStart.getDate() + 6);

		groupKey = `${thursday.getFullYear()}-W${String(weekNumber).padStart(2, "0")}`;
		displayDate = `${weekStart.toLocaleDateString("de-CH")} - ${weekEnd.toLocaleDateString("de-CH")}`;
	} else if (groupingKey === 2) {
		// Monthly grouping
		const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);

		groupKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;
		displayDate = `${monthStart.toLocaleDateString("de-CH", { month: "long" })} ${date.getFullYear()}`;
	} else if (groupingKey === 3) {
		// Yearly grouping
		groupKey = `${date.getFullYear()}`;
		displayDate = `${date.getFullYear()}`;
	}

	return { groupKey, displayDate };
};

const addRowToGroup = (group: JobItemStatsRow, row: JobItemStatsRow) => {
	group.total_jobs += row.total_jobs;
	group.total_job_items =
		(group.total_job_items ?? 0) + (row.total_job_items ?? 0);
	group.amount = (group.amount ?? 0) + (row.amount ?? 0);
	group.amount_tariff += row.amount_tariff;
	group.amount_article_material += row.amount_article_material;
	group.amount_article_postage += row.amount_article_postage;
	group.amount_article_external_work += row.amount_article_external_work;
	group.amount_article_discounts += row.amount_article_discounts;
	group.amount_article_fixed_rate += row.amount_article_fixed_rate;
	group.amount_warranty += row.amount_warranty;
};

const groupByClient = (data: JobItemStatsRow[], key: number) => {
	if (!data || data.length === 0) return {};
	const groups = new Map<string, JobItemStatsRow>();

	data.forEach((row) => {
		const date = new Date(row.date);
		const { groupKey, displayDate } = formatDateByGrouping(date, key);
		const dateClientKey = `${groupKey}_${row.client_id}`;

		if (!groups.has(dateClientKey)) {
			groups.set(dateClientKey, {
				...row,
				displayDate: displayDate,
				date: row.date,
				total_jobs: 0,
				total_job_items: 0,
				amount_tariff: 0,
				amount_article_material: 0,
				amount_article_postage: 0,
				amount_article_external_work: 0,
				amount_article_discounts: 0,
				amount_article_fixed_rate: 0,
				amount_warranty: 0,
			});
		}

		const group = groups.get(dateClientKey)!;
		addRowToGroup(group, row);
	});

	// Convert the Map to the expected output format
	const result: Record<string, JobItemStatsRow[]> = {};

	Array.from(groups.values()).forEach((row) => {
		if (!row.client_id) {
			Logger.error("No client id");
			return;
		}
		if (!result[row.client_id]) {
			result[row.client_id] = [];
		}

		const existingEntry = result[row.client_id].find(
			(entry) => entry.date === row.date
		);

		if (!existingEntry) {
			result[row.client_id].push(row);
			Logger.log("no existingEntry", row);
		} else {
			// This should never happen with our current implementation
			// since we're already grouping by date and client in the Map
			showNotification({
				message: "Fehler beim Gruppieren der Daten",
				type: "error",
			});
		}
	});

	return result;
};

const groupByTechnician = (data: JobItemStatsRow[], key: number) => {
	if (!data || data.length === 0) return {};
	const groups = new Map<string, JobItemStatsRow>();

	data.forEach((row) => {
		const date = new Date(row.date);
		const { groupKey, displayDate } = formatDateByGrouping(date, key);
		const dateTechnicianKey = `${groupKey}_${row.technician_id}`;

		if (!groups.has(dateTechnicianKey)) {
			groups.set(dateTechnicianKey, {
				...row,
				displayDate: displayDate,
				date: row.date,
				total_jobs: 0,
				total_job_items: 0,
				amount_tariff: 0,
				amount_article_material: 0,
				amount_article_postage: 0,
				amount_article_external_work: 0,
				amount_article_discounts: 0,
				amount_article_fixed_rate: 0,
				amount_warranty: 0,
			});
		}

		const group = groups.get(dateTechnicianKey)!;
		addRowToGroup(group, row);
	});

	const result: Record<string, JobItemStatsRow[]> = {};

	Array.from(groups.values()).forEach((row) => {
		if (!row.technician_id) {
			if (!result["undefined technician"]) {
				result["undefined technician"] = [];
			}
			result["undefined technician"].push(row);
			return;
		}
		if (!result[row.technician_id]) {
			result[row.technician_id] = [];
		}

		const existingEntry = result[row.technician_id].find(
			(entry) => entry.date === row.date
		);

		if (!existingEntry) {
			result[row.technician_id].push(row);
			Logger.log("no existingEntry", row);
		} else {
			// This can happen since there are jobs that are not assigned to a technician
			addRowToGroup(existingEntry, row);
		}
	});

	return result;
};

const groupByDate = (data: JobItemStatsRow[], key: number) => {
	if (!data || data.length === 0) return {};
	const groups = new Map<string, JobItemStatsRow>();

	data.forEach((row) => {
		const date = new Date(row.date);
		const { groupKey, displayDate } = formatDateByGrouping(date, key);

		if (!groups.has(groupKey)) {
			groups.set(groupKey, {
				...row,
				displayDate: displayDate,
				date: row.date,
				total_jobs: 0,
				total_job_items: 0,
				amount_tariff: 0,
				amount_article_material: 0,
				amount_article_postage: 0,
				amount_article_external_work: 0,
				amount_article_discounts: 0,
				amount_article_fixed_rate: 0,
				amount_warranty: 0,
			});
		}

		const group = groups.get(groupKey)!;
		addRowToGroup(group, row);
	});

	// Convert the Map to the expected output format
	const result: Record<string, JobItemStatsRow[]> = {
		all: Array.from(groups.values()).sort((a, b) =>
			a.date.localeCompare(b.date)
		),
	};

	return result;
};

const groupByCode = (data: JobItemStatsRow[], key: number) => {
	if (!data || data.length === 0) return {};

	// Group by code first, then by time period
	const result: Record<string, JobItemStatsRow[]> = {};

	data.forEach((row) => {
		const date = new Date(row.date);
		const { groupKey, displayDate } = formatDateByGrouping(date, key);
		const codeKey = row.code_e || "undefined code";

		if (!result[codeKey]) {
			result[codeKey] = [];
		}

		// Check if we already have an entry for this code and time period
		const existingEntryIndex = result[codeKey].findIndex(
			(entry) => entry.displayDate === displayDate
		);

		if (existingEntryIndex >= 0) {
			// Add values to existing entry
			addRowToGroup(result[codeKey][existingEntryIndex], row);
		} else {
			// Create new entry for this code and time period
			const newEntry = {
				...row,
				displayDate,
				date: row.date,
				amount: row.amount,
				type: row.type,
				code_e: codeKey,
				total_jobs: row.total_jobs,
				amount_tariff: row.amount_tariff,
				amount_article_material: row.amount_article_material,
				amount_article_postage: row.amount_article_postage,
				amount_article_external_work: row.amount_article_external_work,
				amount_article_discounts: row.amount_article_discounts,
				amount_article_fixed_rate: row.amount_article_fixed_rate,
				amount_warranty: row.amount_warranty,
			};
			result[codeKey].push(newEntry);
		}
	});

	Object.keys(result).forEach((code) => {
		result[code].sort((a, b) => a.date.localeCompare(b.date));
	});

	return result;
};

const groupByTimeWithEntities = (
	data: JobItemStatsRow[],
	timeGrouping: number,
	selectedStatistics: string
): Record<string, JobItemStatsRow[]> => {
	if (!data || data.length === 0) return {};

	const groups = new Map<string, Map<string, JobItemStatsRow>>();

	data.forEach((row) => {
		const date = new Date(row.date);
		const { groupKey, displayDate } = formatDateByGrouping(
			date,
			timeGrouping
		);

		if (!groups.has(groupKey)) {
			groups.set(groupKey, new Map());
		}

		const timeGroup = groups.get(groupKey)!;
		const entityId =
			selectedStatistics === "1"
				? row.client_id
				: selectedStatistics === "2"
					? row.technician_id
					: row.code_e;

		if (!timeGroup.has(entityId || "undefined")) {
			timeGroup.set(entityId || "undefined", {
				...row,
				displayDate,
				date: row.date,
				total_jobs: 0,
				total_job_items: 0,
				amount_tariff: 0,
				amount_article_material: 0,
				amount_article_postage: 0,
				amount_article_external_work: 0,
				amount_article_discounts: 0,
				amount_article_fixed_rate: 0,
				amount_warranty: 0,
			});
		}

		const entityGroup = timeGroup.get(entityId || "undefined")!;
		addRowToGroup(entityGroup, row);
	});

	// Convert the Map to the expected output format
	const result = Object.fromEntries(
		Array.from(groups.entries()).map(([timeKey, entityMap]) => [
			timeKey,
			Array.from(entityMap.values()),
		])
	);

	return result;
};

const TableWithJobItemsStats = ({
	selectedStatistics,
	startDate,
	endDate,
	processedData,
	loading,
	clientsLookup,
	membersLookup,
	grouping,
}: {
	selectedStatistics: string;
	startDate: Date | null;
	endDate: Date | null;
	processedData: Record<string, JobItemStatsRow[]> | null;
	loading: boolean;
	clientsLookup: Record<string, ClientEntityType>;
	membersLookup: Record<string, Member>;
	grouping: number;
}) => {
	const [sortConfig, setSortConfig] = useState<SortConfig>({
		column: null,
		direction: null,
	});

	const handleSort = (column: keyof JobItemStatsRow | "total") => {
		let direction: SortDirection = "asc";
		if (sortConfig.column === column && sortConfig.direction === "asc") {
			direction = "desc";
		}
		setSortConfig({ column, direction });
	};

	const sortedRows = (rows: JobItemStatsRow[]) => {
		return [...rows].sort((a, b) => {
			if (sortConfig.column === null) return 0;

			if (sortConfig.column === "type") {
				// First compare by type
				if (a.type !== b.type) {
					return sortConfig.direction === "asc"
						? (a.type || 0) - (b.type || 0)
						: (b.type || 0) - (a.type || 0);
				}
				// If types are equal, use code_e as secondary sort
				const codeA = a.code_e || "";
				const codeB = b.code_e || "";
				return sortConfig.direction === "asc"
					? codeA.localeCompare(codeB)
					: codeB.localeCompare(codeA);
			}

			if (sortConfig.column === "total") {
				const totalA =
					a.amount_tariff +
					a.amount_article_material +
					a.amount_article_external_work +
					a.amount_article_postage -
					a.amount_article_discounts +
					a.amount_article_fixed_rate;
				const totalB =
					b.amount_tariff +
					b.amount_article_material +
					b.amount_article_external_work +
					b.amount_article_postage -
					b.amount_article_discounts +
					b.amount_article_fixed_rate;

				if (sortConfig.direction === "asc") {
					return totalA - totalB;
				} else {
					return totalB - totalA;
				}
			}

			const valueA = a[sortConfig.column];
			const valueB = b[sortConfig.column];

			if (typeof valueA === "number" && typeof valueB === "number") {
				if (sortConfig.direction === "asc") {
					return valueA - valueB;
				} else {
					return valueB - valueA;
				}
			}

			if (typeof valueA === "string" && typeof valueB === "string") {
				if (sortConfig.direction === "asc") {
					return valueA.localeCompare(valueB);
				} else {
					return valueB.localeCompare(valueA);
				}
			}

			return 0;
		});
	};

	const arrow = (column: keyof JobItemStatsRow | "total") => {
		if (sortConfig.column === column) {
			return sortConfig.direction === "asc" ? " ↓" : " ↑";
		}
		return "";
	};

	// Helper function to calculate total for a row
	const calculateRowTotal = (row: JobItemStatsRow): number => {
		return (
			row.amount_tariff +
			row.amount_article_material +
			row.amount_article_external_work +
			row.amount_article_postage -
			row.amount_article_discounts +
			row.amount_article_fixed_rate
		);
	};

	const firstHeaderColumn = () => {
		if (grouping !== 0) {
			switch (selectedStatistics) {
				case "0":
					return (
						<TableCell
							onClick={() => handleSort("date")}
							style={{ cursor: "pointer" }}
						>
							<>
								Datum
								{arrow("date")}
							</>
						</TableCell>
					);
				case "1":
					return (
						<TableCell
							onClick={() => handleSort("client_id")}
							style={{ cursor: "pointer" }}
						>
							<>
								Auftraggeber
								{arrow("client_id")}
							</>
						</TableCell>
					);
				case "2":
					return (
						<TableCell
							onClick={() => handleSort("technician_id")}
							style={{ cursor: "pointer" }}
						>
							<>
								Techniker
								{arrow("technician_id")}
							</>
						</TableCell>
					);
				case "3":
					return (
						<TableCell
							onClick={() => handleSort("code_e")}
							style={{ cursor: "pointer" }}
						>
							<>
								Eingabecode
								{arrow("code_e")}
							</>
						</TableCell>
					);
			}
		} else {
			return (
				<TableCell
					onClick={() => handleSort("date")}
					style={{ cursor: "pointer" }}
				>
					<>
						Datum
						{arrow("date")}
					</>
				</TableCell>
			);
		}
	};

	// Helper function to render table header
	const renderTableHeader = () => (
		<TableHead>
			{" "}
			{/* TODO: Make it sticky */}
			<TableRow>
				{firstHeaderColumn()}
				{selectedStatistics === "3" && (
					<TableCell
						onClick={() => handleSort("type")}
						style={{ cursor: "pointer" }}
					>
						Typ
						{arrow("type")}
					</TableCell>
				)}
				<TableCell
					onClick={() => handleSort("total_jobs")}
					style={{ cursor: "pointer" }}
				>
					Anzahl Aufträge
					{arrow("total_jobs")}
				</TableCell>
				<TableCell
					onClick={() =>
						handleSort(
							selectedStatistics !== "3"
								? "total_job_items"
								: "amount"
						)
					}
					style={{ cursor: "pointer" }}
				>
					{selectedStatistics !== "3" ? (
						<>
							Anzahl Positionen
							{arrow("total_job_items")}
						</>
					) : (
						<>
							Anzahl Artikel
							{arrow("amount")}
						</>
					)}
				</TableCell>
				<TableCell
					onClick={() => handleSort("amount_tariff")}
					style={{ cursor: "pointer" }}
				>
					Arbeit
					{arrow("amount_tariff")}
				</TableCell>
				<TableCell
					onClick={() => handleSort("amount_article_material")}
					style={{ cursor: "pointer" }}
				>
					Material
					{arrow("amount_article_material")}
				</TableCell>
				<TableCell
					onClick={() => handleSort("amount_article_external_work")}
					style={{ cursor: "pointer" }}
				>
					Fremdarbeit
					{arrow("amount_article_external_work")}
				</TableCell>
				<TableCell
					onClick={() => handleSort("amount_article_postage")}
					style={{ cursor: "pointer" }}
				>
					Porto
					{arrow("amount_article_postage")}
				</TableCell>
				<TableCell
					onClick={() => handleSort("amount_article_discounts")}
					style={{ cursor: "pointer" }}
				>
					Abzüge
					{arrow("amount_article_discounts")}
				</TableCell>
				<TableCell
					onClick={() => handleSort("amount_article_fixed_rate")}
					style={{ cursor: "pointer" }}
				>
					Arbeitspauschale
					{arrow("amount_article_fixed_rate")}
				</TableCell>
				<TableCell
					onClick={() => handleSort("amount_warranty")}
					style={{ cursor: "pointer" }}
				>
					Garantie
					{arrow("amount_warranty")}
				</TableCell>
				<TableCell
					onClick={() => handleSort("total")}
					style={{ cursor: "pointer" }}
				>
					Summe
					<br />
					(ohne Garantie, ohne MwSt.)
					{arrow("total")}
				</TableCell>
			</TableRow>
		</TableHead>
	);

	const firstContentColumn = (row: JobItemStatsRow) => {
		if (grouping === 1 && selectedStatistics === "1") {
			const client = clientsLookup[row.client_id ?? ""];
			if (client) {
				return (
					`${client.title || ""} ${client.first_name || ""} ${client.last_name || ""}`.trim() ||
					"Kein Name"
				);
			} else {
				return "Unbekannter Auftraggeber";
			}
		} else if (grouping === 1 && selectedStatistics === "2") {
			const technician = membersLookup[row.technician_id ?? ""];
			if (technician) {
				return (
					`${technician.first_name || ""} ${technician.last_name || ""}`.trim() ||
					"Kein Name"
				);
			} else {
				return "Keinem Techniker zugewiesen";
			}
		} else if (grouping === 1 && selectedStatistics === "3") {
			// Codes
			return row.code_e || "";
		} else {
			return row.displayDate;
		}
	};

	// Helper function to render a normal table row
	const renderTableRow = (
		row: JobItemStatsRow,
		key: string,
		rowIndex: number
	) => (
		<TableRow key={`${key}-${row.date}-${rowIndex}`}>
			<TableCell>{firstContentColumn(row)}</TableCell>
			{selectedStatistics === "3" && (
				<TableCell>
					{row.type === JobItemTypeEnum.TARIFF
						? "Tarif"
						: row.type === JobItemTypeEnum.ARTICLE_MATERIAL
							? "Material"
							: row.type === JobItemTypeEnum.ARTICLE_POSTAGE
								? "Porto"
								: row.type ===
									  JobItemTypeEnum.ARTICLE_EXTERNAL_WORK
									? "Fremdarbeit"
									: row.type ===
										  JobItemTypeEnum.ARTICLE_DISCOUNTS
										? "Abzug/Rabatt"
										: row.type ===
											  JobItemTypeEnum.ARTICLE_FIXED_RATE
											? "Arbeitspauschale"
											: "Garantie"}
				</TableCell>
			)}
			<TableCell>{formatNumber(row.total_jobs, 0)}</TableCell>
			{selectedStatistics !== "3" ? (
				row.total_job_items ? (
					<TableCell>
						{formatNumber(row.total_job_items, 0)}
					</TableCell>
				) : (
					<TableCell>0</TableCell>
				)
			) : row.amount ? (
				<TableCell>{formatNumber(row.amount, 0)}</TableCell>
			) : (
				<TableCell>0</TableCell>
			)}

			<TableCell>{formatNumber(row.amount_tariff)} CHF</TableCell>
			<TableCell>
				{formatNumber(row.amount_article_material)} CHF
			</TableCell>
			<TableCell>
				{formatNumber(row.amount_article_external_work)} CHF
			</TableCell>
			<TableCell>
				{formatNumber(row.amount_article_postage)} CHF
			</TableCell>
			<TableCell>
				{formatNumber(row.amount_article_discounts)} CHF
			</TableCell>
			<TableCell>
				{formatNumber(row.amount_article_fixed_rate)} CHF
			</TableCell>
			<TableCell>{formatNumber(row.amount_warranty)} CHF</TableCell>
			<TableCell>{formatNumber(calculateRowTotal(row))} CHF</TableCell>
		</TableRow>
	);

	// Helper function to render a summary row
	const renderSummaryRow = (rows: JobItemStatsRow[]) => {
		const totalJobs = rows.reduce((sum, row) => sum + row.total_jobs, 0);
		const totalJobItems = rows.reduce(
			(sum, row) => sum + (row.total_job_items ?? 0),
			0
		);
		const totalAmount = rows.reduce(
			(sum, row) => sum + (row.amount ?? 0),
			0
		);
		const totalTariff = rows.reduce(
			(sum, row) => sum + row.amount_tariff,
			0
		);
		const totalMaterial = rows.reduce(
			(sum, row) => sum + row.amount_article_material,
			0
		);
		const totalExternalWork = rows.reduce(
			(sum, row) => sum + row.amount_article_external_work,
			0
		);
		const totalPostage = rows.reduce(
			(sum, row) => sum + row.amount_article_postage,
			0
		);
		const totalDiscounts = rows.reduce(
			(sum, row) => sum + row.amount_article_discounts,
			0
		);
		const totalFixedRate = rows.reduce(
			(sum, row) => sum + row.amount_article_fixed_rate,
			0
		);
		const totalWarranty = rows.reduce(
			(sum, row) => sum + row.amount_warranty,
			0
		);
		const grandTotal = rows.reduce(
			(sum, row) => sum + calculateRowTotal(row),
			0
		);

		return (
			<TableRow className="bg-blue-100">
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						Gesamt
					</Typography>
				</TableCell>
				{selectedStatistics === "3" && <TableCell></TableCell>}
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(totalJobs, 0)}
					</Typography>
				</TableCell>
				<TableCell>
					{selectedStatistics !== "3" ? (
						<Typography fontWeight="bold" fontSize="15px">
							{formatNumber(totalJobItems, 0)}
						</Typography>
					) : (
						<Typography fontWeight="bold" fontSize="15px">
							{formatNumber(totalAmount, 0)}
						</Typography>
					)}
				</TableCell>
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(totalTariff)} CHF
					</Typography>
				</TableCell>
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(totalMaterial)} CHF
					</Typography>
				</TableCell>
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(totalExternalWork)} CHF
					</Typography>
				</TableCell>
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(totalPostage)} CHF
					</Typography>
				</TableCell>
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(totalDiscounts)} CHF
					</Typography>
				</TableCell>
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(totalFixedRate)} CHF
					</Typography>
				</TableCell>
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(totalWarranty)} CHF
					</Typography>
				</TableCell>
				<TableCell>
					<Typography fontWeight="bold" fontSize="15px">
						{formatNumber(grandTotal)} CHF
					</Typography>
				</TableCell>
			</TableRow>
		);
	};

	if (loading) return <Loading />;
	if (startDate === null || endDate === null)
		return <div>Keinen Zeitraum ausgewählt</div>;

	// Render for client or technician statistics view
	return (
		<div className="flex flex-col gap-4 h-full">
			{processedData &&
				Object.entries(processedData).map(([timeKey, rows]) => {
					const sortedRowData = sortedRows(rows);
					return (
						<div
							key={timeKey}
							style={{
								width: "100%",
								border: "var(--border-sm)",
							}}
						>
							{grouping === 1 && (
								<div className="flex flex-row justify-between items-center px-4 py-3">
									<Typography variant="h6">
										{rows[0]?.displayDate}
									</Typography>
								</div>
							)}
							{grouping === 0 && selectedStatistics === "1" && (
								<div className="flex flex-row justify-between items-center px-4 py-3">
									<Typography variant="h6">
										{clientsLookup[timeKey]?.company_name ??
											`${clientsLookup[timeKey]?.title} ${clientsLookup[timeKey]?.first_name} ${clientsLookup[timeKey]?.last_name}`}
									</Typography>
								</div>
							)}
							{grouping === 0 && selectedStatistics === "2" && (
								<div className="flex flex-row justify-between items-center px-4 py-3">
									<Typography variant="h6">
										{membersLookup[timeKey]
											? `${membersLookup[timeKey]?.first_name ?? ""} ${membersLookup[timeKey]?.last_name ?? ""}`
											: "Keinem Techniker zugewiesen"}
									</Typography>
								</div>
							)}
							{grouping === 0 && selectedStatistics === "3" && (
								<div className="flex flex-row justify-between items-center px-4 py-3">
									<Typography variant="h6">
										{timeKey === "undefined code"
											? "Kein Code"
											: `Code: ${timeKey}`}
									</Typography>
								</div>
							)}
							<Table stickyHeader>
								{renderTableHeader()}
								<TableBody>
									{sortedRowData.map((row, rowIndex) =>
										renderTableRow(row, timeKey, rowIndex)
									)}
									{sortedRowData.length > 1 &&
										renderSummaryRow(sortedRowData)}
								</TableBody>
							</Table>
						</div>
					);
				})}
		</div>
	);
};
