import { StateCreator } from "zustand";
import { supabase } from "../../supabase/supabaseClient";
import {
	ActivityEntityType,
	ActivityTypeEnum,
	ReplyEntityType,
	SharesWithFileInfo,
	SupabaseShareEntityViewEnum,
	SupabaseTableEnum,
} from "../../supabase/supabaseTypes";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { Logger } from "../../../lib/logger/Logger";

export interface ActivitySlice {
	activityLoading: boolean;
	activityCount: number | null;
	activitiesLookup: Record<
		string,
		ActivityEntityType & { replies: ReplyEntityType[] }
	>;
	latestSharedFiles: SharesWithFileInfo[];
	createJobSharedActivity: (
		connectRelationshipId: string,
		userId: string,
		jobRequestId: number,
		organizationId: string
	) => Promise<void>;
	updateActivities: (
		payload: RealtimePostgresChangesPayload<{ [key: string]: any }>
	) => void;
	fetchActivities: (
		connectRelationshipId: string,
		cursor?: number
	) => Promise<void>;
	fetchActivity: (activityId: number) => Promise<ActivityEntityType | null>;
	fetchLatestSharedFiles: (
		organizationId: string,
		numberofFiles: number
	) => Promise<void>;
}
export const createActivityStore: StateCreator<ActivitySlice> = (set) => ({
	activityLoading: true,
	activitiesLookup: {},
	latestSharedFiles: [],
	createJobSharedActivity: async (
		connectRelationshipId,
		userId,
		jobRequestId,
		organizationId
	) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.SHARED)
			.insert({
				connect_relationship_id: connectRelationshipId,
				created_by: userId,
				job_request_id: jobRequestId,
				activity_type: ActivityTypeEnum.SHARED_JOB_REQUEST,
				created_by_org: organizationId,
			})
			.select();

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			Logger.info("Activity created", data);
			set((state) => ({
				activitiesLookup: {
					[data[0].id]: data[0],
					...state.activitiesLookup,
				},
			}));
		}
	},
	activityCount: null,
	updateActivities: (payload) => {
		set((state) => ({
			activitiesLookup: {
				...state.activitiesLookup,
				[(payload.new as ActivityEntityType).id]: payload.new,
			},
		}));
	},

	fetchActivities: async (connectRelationshipId, cursor = 9) => {
		// Only set loading on first fetch
		if (cursor <= 9) set({ activityLoading: true });
		if (!connectRelationshipId) {
			set({
				activityLoading: false,
			});
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseTableEnum.SHARED)
			.select(`* ,${SupabaseTableEnum.REPLIES}(*)`)
			.range(0, cursor)
			.eq("connect_relationship_id", connectRelationshipId)
			.order("created_at", { ascending: false });

		if (error) {
			Logger.error(error);
			set({
				activityLoading: false,
			});
			return;
		}
		const countData = await supabase
			.from(SupabaseTableEnum.SHARED)
			.select("", {
				count: "exact",
				head: true,
			})
			.eq("connect_relationship_id", connectRelationshipId);

		set({
			activityCount: countData.count,
		});

		const activitiesLookup = data.reduce(
			(acc, row) => ({
				...acc,
				[row.id]: {
					...row,
					replies: Array.isArray(row.replies) ? row.replies : [],
				},
			}),
			{} as Record<
				string,
				ActivityEntityType & { replies: ReplyEntityType[] }
			>
		);

		if (data) {
			set({ activitiesLookup });
		}

		set({
			activityLoading: false,
		});
	},
	fetchActivity: async (activityId: number) => {
		if (!activityId) return null;
		const { data, error } = await supabase
			.from(SupabaseTableEnum.SHARED)
			.select(`* ,${SupabaseTableEnum.REPLIES}(*)`)
			.eq("id", activityId);

		if (error) {
			Logger.error(error);
			return null;
		}

		return data?.[0];
	},

	fetchLatestSharedFiles: async (
		organizationId: string,
		numberOfFiles: number
	) => {
		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.SHARES_WITH_FILE_INFO)
			.select("*")
			.eq("organization_id", organizationId)
			.not("connect_relationship_id", "is", null)
			.order("created_at", {
				ascending: false,
			})
			.limit(numberOfFiles);

		if (error) {
			Logger.error(error);
			return;
		}

		set({
			latestSharedFiles: data,
		});
	},
});
