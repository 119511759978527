import { useSendEmail } from "@/dentlab/src/hooks/useSendEmail";
import {
	FileWithShare,
	isShared,
	JobWithShare,
} from "@/lib/supabase/supabaseTypes";
import { ProfileOptionType } from "../share-dialog/practitioner-emails-autocomplete";
import { ShareDialog } from "../share-dialog/share-dialog.component";
import { usePatientStore } from "@/dentlab/src/store/Patient";
import { useJobStore } from "@/dentlab/src/store/Jobs";

// This component is used to share files (non-pdf files) relating to a patient or a job
//
// If a job or patient is set the corresponding job or patient will be attached to the file
// in dentconnect
export const ShareFileButton = ({
	file,
	open,
	setOpen,
	handleShare,
}: {
	file: FileWithShare;
	open: boolean;
	setOpen: (val: boolean) => void;
	handleShare: () => void;
}) => {
	const { getIsPatientShared } = usePatientStore((state) => ({
		getIsPatientShared: state.getIsPatientShared,
	}));
	const { jobShared } = useJobStore((state) => ({
		jobShared: isShared(state.job as JobWithShare),
	}));
	const { sendSharedFileEmailTemplate } = useSendEmail();

	const handleNotify = async (
		profiles: ProfileOptionType[],
		message: string
	) => {
		const { label: emailAddress } = profiles[0];
		const patientShared = await getIsPatientShared(file.patient_id);

		sendSharedFileEmailTemplate({
			emailAddress,
			fileName: file.file_name ?? "Kein Dateiname",
			link: `https://dentconnect.dentdesk.ch/${
				file.patient_id && patientShared
					? `patient/${file.patient_id}`
					: file.job_id && jobShared
						? `job/${file.job_id}`
						: ""
			}`,
			message,
		});

		setOpen(false);
	};
	const shareDialog = (
		<ShareDialog
			open={open}
			onClose={() => setOpen(false)}
			entityName={file?.file_name ?? "Datei"}
			shared={isShared(file)}
			onShare={handleShare}
			onUnshare={() => {}}
			onNotify={handleNotify}
		/>
	);

	return <>{shareDialog}</>;
};
