import { useCallback, useEffect, useState } from "react";

interface UseAutoSaveOptions<T> {
	data: T;
	onSave: () => Promise<void> | void;
	shouldSave?: boolean;
	delay?: number;
}

export const useAutoSave = <T>({
	data,
	onSave,
	shouldSave = true,
	delay = 500,
}: UseAutoSaveOptions<T>) => {
	const [lastChangeTimestamp, setLastChangeTimestamp] = useState(0);

	const debouncedSave = useCallback(() => {
		if (shouldSave) {
			onSave();
		}
	}, [onSave, shouldSave]);

	useEffect(() => {
		if (!shouldSave || lastChangeTimestamp === 0) return;

		const timer = setTimeout(() => {
			debouncedSave();
		}, delay);

		return () => clearTimeout(timer);
	}, [lastChangeTimestamp, debouncedSave, delay, shouldSave]);

	const triggerSave = useCallback(() => {
		if (!shouldSave) return;
		setLastChangeTimestamp(new Date().getTime());
	}, [shouldSave]);

	useEffect(() => {
		triggerSave();
	}, [data, triggerSave]);

	return { triggerSave };
};
