import { Logger } from "@/lib/logger/Logger";
import { MessageContent } from "../../../../lib/store/Realtime/message.types";
import { MessageType } from "../../../../lib/store/Realtime/message.types";
import {
	JobRequestMessageV1,
	TextMessage,
} from "../../../../lib/store/Realtime/message.types";
import parse from "html-react-parser";

export const getMessageContent = (content: string): MessageContent => {
	return typeof content === "string"
		? (JSON.parse(content) as MessageContent)
		: (content as MessageContent);
};

export const getMessagePreview = (content: string, isSender: boolean) => {
	const newContent = getMessageContent(content);
	Logger.info("newContent", newContent);

	switch (newContent.type) {
		case MessageType.JOB_REQUEST_V1:
			return getJobRequestMessagePreview(
				newContent as JobRequestMessageV1,
				isSender
			);
		case MessageType.TEXT:
			return getTextMessagePreview(newContent as TextMessage, isSender);
		case MessageType.JOB_REQUEST_DENIED:
			return "Auftragsanfrage wurde abgelehnt";
		case MessageType.JOB_REQUEST_ACCEPTED:
			return "Auftragsanfrage wurde angenommen";
		case MessageType.SHARED_FILE:
			return "Datei wurde geteilt";
		default:
			return getTextMessagePreview(newContent as TextMessage, isSender);
	}
};

const getJobRequestMessagePreview = (
	content: JobRequestMessageV1,
	isSender: boolean
) => {
	if (isSender) {
		return `Du hast einen Auftrag mit dem Titel "${content.title}" erstellt.`;
	} else {
		return `Du hast einen Auftrag mit dem Titel "${content.title}" erhalten.`;
	}
};

const getTextMessagePreview = (content: TextMessage, isSender: boolean) => {
	const text = parse(content.text);
	if (!content.text) {
		return <span></span>;
	} else if (isSender) {
		return (
			<div className="flex flex-row items-center gap-1">Du: {text}</div>
		);
	} else {
		return (
			<div className="flex flex-row items-center gap-1">
				Nachricht: {text}
			</div>
		);
	}
};
