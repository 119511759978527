import { TextField } from "@mui/material";
import { useForm } from "../../hooks/useForm/useForm";
import { Database } from "../../../../lib/supabase";
import { FormProps } from "../form-types";
import { FormSectionWithSaveButton } from "../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { CustomTextField } from "../form-input-components/custom-input-components/custom-text-field.component";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { useProfileContext } from "../../../../lib/contexts/ProfileContext";
import { Logger } from "../../../../lib/logger/Logger";
import { useCustomDialogContext } from "../../components/dialog-components/custom-dialog/custom-dialog.component";
import { CitySelect } from "../form-input-components/selects/city-select/city-select";
import { useToast } from "../../context/ToastContext";
import { useCentralStore } from "../../store/Central";

export const CompanyInformationForm: React.FC<FormProps> = ({
	submitRef = null,
}) => {
	const { organization, getOrganization } = useCentralStore((state) => ({
		organization: state.organization,
		getOrganization: state.getOrganization,
	}));
	const { setIsPendingChanges } = useCustomDialogContext();
	const { showToast } = useToast();

	const {
		handleInputChange,
		handleValueChange,
		handleUpdate,
		formData,
		formErrors,
		resetFormData,
	} = useForm<
		Database["public"]["Tables"][SupabaseTableEnum.ORGANIZATIONS]["Update"]
	>(
		{},
		SupabaseTableEnum.ORGANIZATIONS,
		true,
		organization?.id,
		"Firmenangaben",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (!organization?.id) {
			showToast("Es ist ein Fehler aufgetreten.", "error");
			Logger.error("No organizationId found in CompanyInformationForm");
			return;
		}
		const { success, error } = await handleUpdate(event, organization.id);
		if (success) {
			showToast("Ihre Änderungen wurden gespeichert.", "success");
		} else {
			showToast("Es ist ein Fehler aufgetreten.", "error");
			Logger.error(error, {}, "Error in CompanyInformationForm");
		}

		await getOrganization();
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			disabled={false}
			margin="none"
			onResetForm={resetFormData}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "500px",
					paddingTop: "1rem",
				}}
			>
				<TextField
					label="Anrede/Firma"
					name="title_or_company_name"
					helperText="Ihre Anrede (Frau, Herr...) oder Firmenbezeichnung."
					value={formData.title_or_company_name || ""}
					onChange={handleInputChange}
				/>
				<TextField
					label="Name/Zusatz"
					name="name_or_additional_information"
					helperText="Ihr Vorname und Name."
					value={formData.name_or_additional_information || ""}
					onChange={handleInputChange}
				/>
				<TextField
					label="Strasse"
					name="street"
					helperText="Die Strasse und Hausnummer Ihrer Anschrift."
					value={formData.street || ""}
					onChange={handleInputChange}
				/>
				<div
					style={{
						paddingBottom: "8px",
					}}
				>
					<CitySelect
						onChange={(value) => {
							handleValueChange("city", value.city);
							handleValueChange("postal_code", value.postalCode);
						}}
						value={formData.postal_code + " " + formData.city}
					/>
				</div>
				<CustomTextField
					label="Telefon/Fax"
					name="phone_and_fax"
					helperText="Ihre Telefon- und Faxnummer"
					value={formData.phone_and_fax || ""}
					onChange={handleInputChange}
					validationError={formErrors.phone_and_fax}
				/>
				<CustomTextField
					key="gln"
					label="GLN"
					name="gln"
					helperText="GLN, Global Location Number, wird verwendet, um Ihre Laboradresse eindeutig zu kennzeichnen."
					value={formData.gln || ""}
					onChange={handleInputChange}
					validationError={formErrors.gln}
				/>
			</div>
		</FormSectionWithSaveButton>
	);
};
