import {
	JobDocumentEntityType,
	OrganizationEntityType,
} from "@/lib/supabase/supabaseTypes";
import { CalculateTotalsResult } from "../../lib/utils/calculate";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";

const discountsRowLabel = (
	discountWork: number,
	discountMaterial: number,
	discountFixedAmounts: string
) => {
	const discountWorkLabel = discountWork
		? "Arbeit -" + discountWork + "%"
		: "";
	const discountMaterialLabel = discountMaterial
		? "Mat. -" + discountMaterial + "%"
		: "";
	const discountFixedAmountsLabel = discountFixedAmounts
		? "Fixe Abzüge " + discountFixedAmounts
		: "";
	return `Abzüge (${Array.from([discountWorkLabel, discountMaterialLabel, discountFixedAmountsLabel].filter((e) => e)).join(", ")})`;
};

export const PdfTableTotalsSection: React.FC<{
	totals: CalculateTotalsResult;
	organization: OrganizationEntityType;
	numberOfColumns: number;
	numberOfAdditionalColumns: number; // additional columns to the right of the total values
	jobDocument?: JobDocumentEntityType; // optional, if totals are for one document (which might have discounts)
	monthlyDiscountOnWorkAndFixedRate?: string; // optional, if totals are for a monthly invoice (which might have a monthly discount)
	monthlyDiscountOnSubtotal?: string; // optional, if totals are for a monthly invoice (which might have a monthly discount)
	pdfTemplateConfiguration: PdfTemplateConfiguration;
	isMonthlyInvoice?: boolean;
}> = ({
	totals,
	organization,
	numberOfColumns,
	numberOfAdditionalColumns,
	jobDocument,
	monthlyDiscountOnWorkAndFixedRate,
	monthlyDiscountOnSubtotal,
	pdfTemplateConfiguration,
	isMonthlyInvoice,
}) => {
	const {
		amountMaterials,
		amountPostage,
		amountExternalWork,
		amountDiscounts,
		amountWorkAndFixedRate,
		deductionsMaterials,
		deductionsWorkAndFixedRate,
		deductions,
		subTotal,
		mwst,
		total,
		taxpunkte,
		mwstPercentage,
	} = totals;

	const generalConfig = pdfTemplateConfiguration?.general;

	const summaryRows: { label: string; value: string }[] = [];

	if (amountWorkAndFixedRate.value > 0) {
		summaryRows.push({
			label: `Arbeit (${taxpunkte.text}Tp)`,
			value: amountWorkAndFixedRate.text,
		});
	}

	if (amountMaterials.value > 0) {
		summaryRows.push({
			label: "Material",
			value: amountMaterials.text,
		});
	}

	if (
		!isMonthlyInvoice &&
		(jobDocument?.discount_work ||
			jobDocument?.discount_material ||
			amountDiscounts.value > 0)
	) {
		summaryRows.push({
			label: discountsRowLabel(
				jobDocument?.discount_work ?? 0,
				jobDocument?.discount_material ?? 0,
				amountDiscounts.text
			),
			value: "-" + deductions.text,
		});
	}

	if (amountPostage.value > 0) {
		summaryRows.push({
			label: "Porto",
			value: amountPostage.text,
		});
	}

	if (amountExternalWork.value > 0) {
		summaryRows.push({
			label: "Fremdarbeiten",
			value: amountExternalWork.text,
		});
	}

	// Add empty row
	summaryRows.push({
		label: "",
		value: "",
	});

	if (
		isMonthlyInvoice &&
		deductionsWorkAndFixedRate &&
		deductionsWorkAndFixedRate.value > 0 &&
		deductionsWorkAndFixedRate.text !== "0.00"
	) {
		summaryRows.push({
			label: `Abzüge auf Arbeit`,
			value: `-${deductionsWorkAndFixedRate.text}`,
		});
	}
	if (
		isMonthlyInvoice &&
		deductionsMaterials &&
		deductionsMaterials.value > 0 &&
		deductionsMaterials.text !== "0.00"
	) {
		summaryRows.push({
			label: `Abzüge auf Material`,
			value: `-${deductionsMaterials.text}`,
		});
	}
	if (
		isMonthlyInvoice &&
		monthlyDiscountOnWorkAndFixedRate &&
		monthlyDiscountOnWorkAndFixedRate !== "0.00"
	) {
		summaryRows.push({
			label: "Monatsrabatt auf Arbeit",
			value: "-" + monthlyDiscountOnWorkAndFixedRate,
		});
	}

	if (
		isMonthlyInvoice &&
		monthlyDiscountOnSubtotal &&
		monthlyDiscountOnSubtotal !== "0.00"
	) {
		summaryRows.push({
			label: "Monatsrabatt",
			value: "-" + monthlyDiscountOnSubtotal,
		});
	}

	summaryRows.push({
		label: "Subtotal",
		value: subTotal.text,
	});

	// The Mwst. (/EUSt.) could be zero if public.organizations.is_mwst_included is true (meaning the organization has selected that the tariffs and articles already include MwSt./EUSt.)
	if (mwst.value > 0) {
		summaryRows.push({
			label: `${organization.is_mwst_eust ? "EUSt." : "MwSt."} (${
				mwstPercentage
			}%)`,
			value: mwst.text,
		});
	}

	// Add empty row
	summaryRows.push({
		label: "",
		value: "",
	});
	summaryRows.push({
		label: "Total",
		value: total.text,
	});

	return (
		<>
			{summaryRows.map((row, i) => {
				// Add empty rows
				if (row.label === "" && row.value === "") {
					return (
						<tr
							key={i}
							style={{
								height: "5mm",
								backgroundColor: "lightgrey",
							}}
						>
							<td
								colSpan={
									numberOfColumns + numberOfAdditionalColumns
								}
								style={{
									height: "5mm",
								}}
							></td>
						</tr>
					);
				}
				return (
					<SummaryRow
						key={row.label}
						label={row.label}
						value={row.value}
						numberOfColumns={numberOfColumns}
						numberOfAdditionalColumns={numberOfAdditionalColumns}
						// Add borders to the first and last summary rows
						style={
							i === 0
								? { borderTop: "1px solid black" }
								: i === summaryRows.length - 1
									? { borderBottom: "1px solid black" }
									: {}
						}
					/>
				);
			})}
		</>
	);
};

const SummaryRow: React.FC<{
	label: string;
	value: string;
	numberOfColumns: number;
	numberOfAdditionalColumns: number;
	style?: React.CSSProperties;
}> = ({ label, value, style, numberOfColumns, numberOfAdditionalColumns }) => (
	<tr
		style={{
			...style,
			height: "5mm",
			backgroundColor: "lightgrey",
		}}
	>
		<td colSpan={2} />
		<td align="left" colSpan={numberOfColumns - 3}>
			{label}
		</td>
		<td align="right" colSpan={1}>
			{value}
		</td>
		{numberOfAdditionalColumns > 0 && (
			<td colSpan={numberOfAdditionalColumns} />
		)}
	</tr>
);
