import { create } from "zustand";
import {
	RealtimeSubscriptionSlice,
	createRealtimeSubscriptionStore,
} from "./realtime-subscriptions.store";
import { ActivitySlice, createActivityStore } from "./activity.store";
import { ReplySlice, createReplyStore } from "./reply.store";
import { MessageSlice, createMessageStore } from "./message.store";

interface RealtimeStore
	extends RealtimeSubscriptionSlice,
		ActivitySlice,
		ReplySlice,
		MessageSlice {}

export const useRealtimeStore = create<RealtimeStore>((...args) => ({
	...createRealtimeSubscriptionStore(...args),
	...createActivityStore(...args),
	...createReplyStore(...args),
	...createMessageStore(...args),
}));
