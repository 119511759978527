import { GridCustomCellProps } from "@progress/kendo-react-grid";
import "./custom-cells.css";
import { useEffect, useState } from "react";
import { FormControl, OutlinedInput } from "@mui/material";

export const NumberCustomCell = (props: GridCustomCellProps) => {
	const [input, setInput] = useState<string>("");

	useEffect(() => {
		if (props.field) {
			setInput(props.dataItem[props.field]?.toString() ?? "");
		}
	}, [props.dataItem, props.field]);

	const applyChange = (event: React.SyntheticEvent<EventTarget, Event>) => {
		if (props.onChange) {
			const numericValue = Number(input);
			if (!isNaN(numericValue)) {
				props.onChange({
					dataItem: props.dataItem,
					field: props.field,
					syntheticEvent: event,
					value: numericValue,
					dataIndex: props.dataIndex,
				});
			}
		}
	};

	const handleBlur = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		applyChange(event);
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		if (event.key === "Enter") {
			applyChange(event);
			(event.target as HTMLInputElement).blur();
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(",", ".");
		if (/^-?\d*[,.]?\d*$/.test(value)) {
			setInput(value);
		}
	};

	return (
		<td className="custom-td">
			<FormControl
				variant="outlined"
				sx={{
					padding: 0,
					margin: 0,
				}}
				fullWidth
			>
				<OutlinedInput
					type="text"
					inputMode="decimal"
					data-testid={`number-custom-cell-outlined-input-${props.field}`}
					value={input}
					onChange={handleChange}
					onBlur={handleBlur}
					onKeyDown={handleKeyDown}
					size="small"
					sx={{
						height: "22px",
						fontSize: "12px",
						"& .MuiOutlinedInput-input": {
							textOverflow: "ellipsis",
						},
						...props.style,
					}}
				/>
			</FormControl>
		</td>
	);
};
