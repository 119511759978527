import { PageTitle } from "@/components/src/page-title/page-title.component";
import {
	Card,
	CardContent,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
} from "@mui/material";
import { useTemplatesStore } from "../../store/Templates/templates.store";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableBody } from "@mui/material";
import { Check, SquarePlus, X } from "lucide-react";
import { EditableTitle } from "@/components/src/editable-title/editable-title.component";
import { displayTemplateLabel } from "../../store/Templates/utils";
import { useCentralStore } from "../../store/Central";
import { useRefContext } from "../../context/RefContext";
import { Toolbar } from "../job-page/job-document/job-document-components/job-items-table/job-items-table.component";
import { useAutoSave } from "../../hooks/useAutosave/useAutosave";
import { CreateTemplateDialog } from "../job-page/job-document/job-document-components/job-document-options-menu.component";
import { showNotification } from "../../store/Central/selectors";

export const JobDocumentTemplatesPage = () => {
	const { container } = useRefContext();
	const [searchParams, setSearchParams] = useSearchParams();
	const templateId = searchParams.get("templateId");
	const [searchQuery, setSearchQuery] = useState("");
	const [createTemplateDialogOpen, setCreateTemplateDialogOpen] =
		useState(false);
	const [confirmDeleteTemplateOpen, setConfirmDeleteTemplateOpen] =
		useState(false);

	const { templatesLookup, deleteTemplate, createTemplate } =
		useTemplatesStore((state) => ({
			templatesLookup: state.templatesLookup,
			deleteTemplate: state.deleteTemplate,
			createTemplate: state.createTemplate,
		}));
	const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
		null
	);

	const [hoveredTemplateId, setHoveredTemplateId] = useState<number | null>(
		null
	);

	useEffect(() => {
		if (templateId && templatesLookup[Number(templateId)]) {
			setSelectedTemplateId(Number(templateId));
		}
	}, [templateId]);

	const filteredTemplates = Object.values(templatesLookup).filter(
		(template) =>
			template.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
			template.code?.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const handleDeleteTemplate = async (templateId: number) => {
		setSelectedTemplateId(null);
		await deleteTemplate(templateId);
		setSearchParams((prev) => {
			prev.delete("templateId");
			return prev;
		});
	};

	const handleCreateTemplate = async (code: string, name: string) => {
		const result = await createTemplate(code, name, []);
		if (result) {
			showNotification({
				message: "Jumbo/Vorlage erstellt",
				type: "success",
			});
			setSelectedTemplateId(result.id);
		}
	};

	return (
		<div className="flex flex-row w-full h-full overflow-hidden">
			<div className="p-6 flex flex-col w-full gap-4 h-[calc(100vh-100px)]">
				<PageTitle title={[{ name: "Dokumentenvorlagen" }]} />
				<div className="flex flex-row w-full gap-4 h-full">
					<Card
						sx={{
							minWidth: 200,
							height: "100%",
							overflow: "hidden",
						}}
					>
						<div className="flex flex-row gap-2 m-1">
							<TextField
								size="small"
								placeholder="Suchen..."
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								sx={{
									marginLeft: "12px",
								}}
								fullWidth
								variant="standard"
							/>
							<IconButton
								size="medium"
								onClick={() =>
									setCreateTemplateDialogOpen(true)
								}
							>
								<SquarePlus size={24} />
							</IconButton>
						</div>
						<List
							sx={{
								overflow: "auto",
								maxHeight: "calc(100vh - 230px)",
							}}
						>
							{filteredTemplates.map((template) => (
								<ListItem
									key={template.id}
									disablePadding
									onMouseEnter={() =>
										setHoveredTemplateId(template.id)
									}
									onMouseLeave={() =>
										setHoveredTemplateId(null)
									}
								>
									<ListItemButton
										selected={
											selectedTemplateId === template.id
										}
										onClick={() => {
											setSelectedTemplateId(template.id);
											setSearchParams((prev) => {
												prev.set(
													"templateId",
													template.id.toString()
												);
												return prev;
											});
										}}
									>
										<ListItemText
											primary={displayTemplateLabel(
												template.code,
												template?.name ?? undefined
											)}
										/>
										{(selectedTemplateId === template.id ||
											hoveredTemplateId ===
												template.id) &&
											(confirmDeleteTemplateOpen ? (
												<div className="flex flex-row gap-2">
													<IconButton
														onClick={(e) => {
															e.stopPropagation(); // Add this line
															handleDeleteTemplate(
																hoveredTemplateId ??
																	0
															);
															setConfirmDeleteTemplateOpen(
																false
															);
														}}
													>
														<Check size={18} />
													</IconButton>
													<IconButton
														onClick={() =>
															setConfirmDeleteTemplateOpen(
																false
															)
														}
													>
														<X size={18} />
													</IconButton>
												</div>
											) : (
												<IconButton
													key={template.id}
													onClick={() =>
														setConfirmDeleteTemplateOpen(
															true
														)
													}
												>
													<DeleteIcon
														sx={{ fontSize: 18 }}
													/>
												</IconButton>
											))}
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Card>
					<div className="flex flex-col gap-4 h-full w-full">
						{selectedTemplateId && (
							<JobDocumentTemplateCard
								templateId={selectedTemplateId}
							/>
						)}
					</div>
					<div className="w-full h-full" ref={container}></div>
				</div>
			</div>
			<CreateTemplateDialog
				onCreateTemplate={handleCreateTemplate}
				open={createTemplateDialogOpen}
				setOpen={setCreateTemplateDialogOpen}
			/>
		</div>
	);
};

const JobDocumentTemplateCard: React.FC<{
	templateId: number;
}> = ({ templateId }) => {
	const {
		updateTemplateItemQuantity,
		deleteTemplateItem,
		updateTemplateName,
		updateTemplateCode,
		templatesLookup,
		addTemplateItem,
	} = useTemplatesStore((state) => ({
		deleteTemplate: state.deleteTemplate,
		updateTemplateItemQuantity: state.updateTemplateItemQuantity,
		deleteTemplateItem: state.deleteTemplateItem,
		updateTemplateName: state.updateTemplateName,
		updateTemplateCode: state.updateTemplateCode,
		templatesLookup: state.templatesLookup,
		addTemplateItem: state.addTemplateItem,
	}));
	const { tariffs, articles } = useCentralStore((state) => ({
		tariffs: state.tariffs,
		articles: state.articles,
	}));

	const template = templatesLookup[templateId];
	const {
		name: templateName,
		code: templateCode,
		template_items: templateItems,
	} = template;

	const [title, setTitle] = useState(templateName);
	const [code, setCode] = useState(templateCode);

	useEffect(() => {
		setTitle(templateName);
		setCode(templateCode);
	}, [templateId]);

	const handleTitleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const handleCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCode(e.target.value);
	};

	const handleTitleSubmit = async () => {
		if (title) await updateTemplateName(templateId, title);
	};

	const handleCodeSubmit = async () => {
		if (code) await updateTemplateCode(templateId, code);
	};

	const handleTitleReset = () => {
		setTitle(templateName);
	};

	const handleCodeReset = () => {
		setCode(templateCode);
	};

	const handleAddTariff = (code_e: string) => {
		addTemplateItem(templateId, { code_e, quantity: 1 });
	};

	const handleAddArticle = (code_e: string) => {
		addTemplateItem(templateId, { code_e, quantity: 1 });
	};

	const handleAddTemplate = (code_e: string) => {
		const template = Object.values(templatesLookup).find(
			(t) => t.code === code_e
		);
		if (template) {
			template.template_items.forEach((item) => {
				addTemplateItem(templateId, item);
			});
		}
	};

	const handleQuantityChange = async (itemId: number, quantity: number) => {
		await updateTemplateItemQuantity(templateId, itemId, quantity);
	};

	return (
		<Card elevation={2}>
			<CardContent>
				<div className="flex flex-row gap-2">
					<EditableTitle
						name="title"
						key={`title-${templateId}`}
						value={title ?? ""}
						onChange={handleTitleInputChange}
						onSubmit={handleTitleSubmit}
						onReset={handleTitleReset}
					/>
					<EditableTitle
						name="code"
						key={`code-${templateId}`}
						value={code ?? ""}
						onChange={handleCodeInputChange}
						onSubmit={handleCodeSubmit}
						onReset={handleCodeReset}
						type="code"
					/>
				</div>
				<div className="flex flex-row items-center gap-4 mt-4">
					<Toolbar
						onAddTariff={handleAddTariff}
						onAddArticle={handleAddArticle}
						onAddTemplate={handleAddTemplate}
						jobItemCodes={templateItems}
						jobDocumentId={0}
						jobDocumentType={"template"}
					/>
				</div>
				<Stack
					direction="row"
					spacing={1}
					sx={{ mt: 2 }}
					flexWrap="wrap"
					gap={1}
				>
					<TableContainer
						sx={{
							overflow: "auto",
							width: "100%",
							height: "calc(100vh - 300px)",
						}}
					>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Code</TableCell>
									<TableCell>Bezeichnung</TableCell>
									<TableCell>Menge</TableCell>
									<TableCell align="right">
										Aktionen
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{[...templateItems]
									.sort((a, b) =>
										a.code_e.localeCompare(b.code_e)
									)
									.map((item) => (
										<TableRow key={item.id}>
											<TableCell>
												<Tooltip
													title={`${!tariffs[item.code_e] && !articles[item.code_e] ? "Tarif oder Artikel nicht gefunden" : ""}`}
												>
													<span
														className={`${!tariffs[item.code_e] && !articles[item.code_e] ? "text-red-500" : ""}`}
													>
														{item.code_e}
													</span>
												</Tooltip>
											</TableCell>
											<TableCell>
												{tariffs[item.code_e]
													?.description_de ??
													articles[item.code_e]
														?.description_de}
											</TableCell>
											<TableCell>
												<TemplateItemQuantityInput
													quantity={item.quantity}
													onChange={(quantity) =>
														handleQuantityChange(
															item.id,
															quantity
														)
													}
												/>
											</TableCell>
											<TableCell align="right">
												<IconButton
													size="small"
													onClick={() =>
														deleteTemplateItem(
															templateId,
															item.id
														)
													}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</CardContent>
		</Card>
	);
};

const TemplateItemQuantityInput: React.FC<{
	quantity: number;
	onChange: (quantity: number) => void;
}> = ({ quantity, onChange }) => {
	const [value, setValue] = useState(quantity);
	useAutoSave({
		data: value,
		onSave: () => {
			if (value !== quantity && !isNaN(value)) {
				onChange(value);
			}
		},
	});

	useEffect(() => {
		setValue(quantity);
	}, [quantity]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseInt(e.target.value);
		const regex = /^[0-9]*$/;
		if (!regex.test(e.target.value)) {
			return;
		}
		setValue(newValue);
	};

	return (
		<TextField
			type="number"
			size="small"
			value={value}
			className="w-14"
			onChange={handleChange}
		/>
	);
};
