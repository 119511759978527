import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useToast } from "@/dentlab/src/context/ToastContext";
import { compressImage } from "@/lib/utils/compress-image";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "@/dentlab/src/types/enums";
import { Logger } from "@/lib/logger/Logger";

interface ImageInputProps {
	bucket: StorageBucketsEnum;
	onFileChange: (file: File | null) => void;
	avatarPath?: string;
	organizationId: string;
}

/**
 * USES ORG_V1 AS BUCKET, i.e. DO NOT USE for images that should be share-able.
 * Only use in components such as client.form.tsx where usage of the file is only internal org-wide.
 */
export const ImageInput: React.FC<ImageInputProps> = ({
	bucket,
	avatarPath,
	onFileChange,
	organizationId,
}) => {
	const [profilePic, setProfilePic] = useState<string>("");
	useEffect(() => {
		const fetchProfilePic = async () => {
			if (!avatarPath || avatarPath?.length <= 0) {
				Logger.log("[fetchProfilePic] No avatar path provided");
				return;
			}
			if (!organizationId) {
				Logger.log("[fetchProfilePic] No organization ID provided");
				return;
			}
			const { data, error } = await supabase.storage
				.from(bucket)
				.createSignedUrl(`${organizationId}/${avatarPath}`, 3600, {
					transform: {
						quality: 35,
					},
				});
			if (error) {
				Logger.log(
					"[fetchProfilePic] Error fetching signed URL",
					error
				);
				return;
			}
			if (!data) {
				Logger.log(
					"[fetchProfilePic] No data returned from signed URL"
				);
				return;
			}
			setProfilePic(data.signedUrl);
		};

		fetchProfilePic();
	}, [avatarPath, organizationId]);

	const { showToast } = useToast();
	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length <= 0) return;
		const file = e.target.files[0];
		if (file.size > 5 * 1024 * 1024) {
			showToast("Bitte wählen Sie eine Bilddatei unter 5MB.", "warning");
			return;
		}
		if (!file) {
			setProfilePic("");
			showToast("Fehler beim Hochladen des Avatars", "error");
			return;
		}

		const compressedFile = await compressImage(file, 0.6);

		onFileChange(compressedFile);
		const reader = new FileReader();
		reader.onloadend = () => {
			setProfilePic(reader.result as string);
		};
		reader.readAsDataURL(file);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: 8,
				maxWidth: "500px",
			}}
		>
			<div
				style={{
					width: 160,
					height: 160,
					border: "1px solid rgba(0,0,0,0.2)",
					borderRadius: 5,
					overflow: "hidden",
				}}
			>
				{!profilePic ? (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							height: "100%",
						}}
					>
						{avatarPath ? "Lädt..." : "Kein Bild gewählt"}
					</div>
				) : (
					<img
						src={profilePic}
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
				)}
			</div>
			<input
				id="profile_picture"
				name="profile_picture"
				accept="image/*"
				type="file"
				style={{
					display: "none",
				}}
				onChange={handleFileChange}
			/>
			<Button
				style={{
					padding: 0,
				}}
			>
				<label
					style={{
						padding: 4,
						cursor: "pointer",
					}}
					htmlFor="profile_picture"
				>
					Hochladen
				</label>
			</Button>
		</div>
	);
};
