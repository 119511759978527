import { StateCreator } from "zustand";
import { State } from "./types";
import { fetchClientAvatar, initialState, TEMPLATE_CODE } from "./utils";
import {
	ClientEntityType,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";
import { supabase } from "../../../../lib/supabase";
import { Logger } from "../../../../lib/logger/Logger";
import { usePatientStore } from "../Patient";
import { showNotification } from "./selectors";
import { produce } from "immer";
import axios from "axios";

interface ConnectGetOrCreateUserResponse {
	data: {
		data: {
			profileId: string | null;
			organizationId: string | null;
		};
		message: string;
	};
	error: string | null;
}

export interface ClientSlice extends State {
	switchClient: (clientId: string) => Promise<void>;
	getClients: () => Promise<void>;
	upsertClient: (client: ClientEntityType) => Promise<void>;
	favoriteClient: (clientId: string, favorite: boolean) => Promise<void>;
	connectClientFromScratch: (adminEmail: string) => Promise<void>;
	connectClientToExistingConnectRelationship: (
		connectRelationshipId: string
	) => Promise<void>;
	deleteConnectRelationshipEntirely: () => Promise<void>;
	removeConnectRelationshipFromClient: () => Promise<void>;
}

export const createClientStore: StateCreator<ClientSlice> = (set, get) => ({
	...initialState,
	switchClient: async (clientId: string) => {
		set({ clientLoading: true });
		const clients = get().clientsLookup;
		set({
			connectRelationshipId: null,
		});
		if (!clients || !clients[clientId]) {
			set({ client: null, clientLoading: false });
			Logger.warn("No clients found or clientId does not exist");
			return;
		}
		const client = clients[clientId];
		set({
			clientId,
			client,
			clientLoading: false,
			connectRelationshipId: client.connect_relationship_id,
		});

		// TODO: this would be simpler if there wasn't an extra connect_relationship_table
		// If the client has a connect relationship, get the organization_id from the connect relationship so
		// that it can be used for sending messages
		if (client.connect_relationship_id) {
			const { data, error } = await supabase
				.from(SupabaseTableEnum.CONNECT_RELATIONSHIPS)
				.select("med_id")
				.eq("id", client.connect_relationship_id)
				.single();
			if (error) {
				Logger.warn("Error fetching connect relationship", error);
			}
			if (data) {
				set({
					clientOrganizationId: data.med_id,
				});
			}
		}

		// Fetch the patients for the client
		await usePatientStore.getState().fetchPatients(clientId);
	},
	getClients: async () => {
		set({ clientsLoading: true });

		const organizationId = get().organization?.id;

		if (!organizationId) {
			Logger.warn("Organization id is not set");
			set({ clientsLoading: false });
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseTableEnum.CLIENTS)
			.select()
			.eq("organization_id", organizationId as string)
			.neq("code", TEMPLATE_CODE) // TEMPLATE_CODE is the code of the dummy client for templates
			.order("created_at", { ascending: false });

		if (error) {
			set({ clientsLoading: false });
			Logger.warn(error);
			return;
		}

		if (!data) {
			set({ clientsLoading: false });
			return;
		}

		const newClientsLookup = data?.reduce(
			(
				acc: Record<string, ClientEntityType>,
				client: ClientEntityType
			) => {
				acc[client.id] = client;
				return acc;
			},
			{}
		);

		// Set the clientsLookup instead of waiting for the avatars to be fetched
		set({
			clientsLookup: newClientsLookup,
			clientsLoading: false,
		});

		const createClientsLookupWithAvatars = async () => {
			const newClientsLookup: Record<
				string,
				ClientEntityType & { image_src?: string }
			> = {};

			for (const client of data) {
				if (client.avatar_path) {
					const image_src = await fetchClientAvatar(
						client.avatar_path
					);
					// @ts-expect-error this is a new field to store the
					// image url
					if (image_src) client.image_src = image_src;
				}

				newClientsLookup[client.id] = client;
			}
		};

		// This will update the clients avatar path without having the user wait for the avatars to be fetched
		// Beauty of asynchrony
		createClientsLookupWithAvatars();
	},
	upsertClient: async (client: ClientEntityType & { image_src?: string }) => {
		if (client.avatar_path) {
			const image_src = await fetchClientAvatar(client.avatar_path);

			if (image_src) client.image_src = image_src;
		}
		set((state) => ({
			clientsLookup: {
				...state.clientsLookup,
				[client.id]: client,
			},
			client,
			clientId: client.id,
			connectRelationshipId: client.connect_relationship_id,
		}));

		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { image_src, search_vector, ...rest } = client as any;

		await supabase.from(SupabaseTableEnum.CLIENTS).upsert(rest, {
			onConflict: "id",
		});
	},
	favoriteClient: async (clientId: string, favorite: boolean) => {
		const newValue = favorite ? new Date().toISOString() : null;

		const { error } = await supabase
			.from(SupabaseTableEnum.CLIENTS)
			.update({
				favorite: newValue,
			})
			.eq("id", clientId);

		if (error) {
			showNotification({
				message: "Fehler beim Favorisieren des Kunden",
				type: "error",
			});
			Logger.warn(error);
			return;
		}

		set(
			produce((state) => {
				state.clientsLookup[clientId].favorite = newValue;
				// Only update the client if it is the currently selected client
				if (state.client && state.client.id === clientId) {
					state.client.favorite = newValue;
				}
			})
		);
	},
	connectClientToExistingConnectRelationship: async (
		connectRelationshipId: string
	) => {
		const clientId = get().clientId;

		if (!clientId) {
			showNotification({
				message: "Kunde nicht gefunden.",
				type: "error",
			});
			return;
		}

		const { error } = await supabase
			.from(SupabaseTableEnum.CLIENTS)
			.update({ connect_relationship_id: connectRelationshipId })
			.eq("id", clientId);

		if (error) {
			showNotification({
				message:
					"Fehler beim Verbinden des Kunden mit einem anderen Auftraggeber",
				type: "error",
			});
			Logger.error(error);
			return;
		}

		window.location.reload();
	},
	connectClientFromScratch: async (adminEmail: string) => {
		const organizationId = get().organization?.id;
		const clientId = get().clientId;
		if (!organizationId) {
			showNotification({
				message: "Organisation nicht gefunden.",
				type: "error",
			});
			return;
		}
		if (!clientId) {
			showNotification({
				message: "Kunde nicht gefunden.",
				type: "error",
			});
			return;
		}
		if (!adminEmail) {
			showNotification({
				message: "Kein Admin-Email angegeben.",
				type: "error",
			});
			return;
		}

		let medOrgId: string | null = null;

		// The backend checks if a user/profile with the given email exists
		// If not, it signs up a new user (with a new organization) and returns profile_id and organization_id
		// If yes, it retrieves profile_id and organization_id
		try {
			const {
				data,
				error: connectError,
			}: ConnectGetOrCreateUserResponse = await axios({
				url: `${
					import.meta.env.VITE_PDF_MICROSERVICE_URL
				}/connect/get-or-create-user`,
				method: "POST",
				data: {
					email: adminEmail,
				},
			});

			if (connectError) {
				showNotification({
					message: "Fehler beim Verbinden des Behandlers (A2).",
					type: "error",
				});
				return;
			}

			medOrgId = data.data.organizationId;
		} catch (error) {
			showNotification({
				message: "Fehler beim Verbinden des Behandlers (A1).",
				description: `${adminEmail} ${error}`,
				type: "error",
			});
			return;
		}
		if (!medOrgId) {
			showNotification({
				message: "Fehler beim Verbinden des Behandlers (A4).",
				type: "error",
			});
			return;
		}

		// The rpc
		// - creates a connect relationship between the lab and the med organization
		// - adds the connect_relationship_id to the client
		// - adds the profile_id to the practitioner
		const { data, error } = await supabase.rpc("connect_client", {
			client_id: clientId,
			lab_org_id: organizationId,
			med_org_id: medOrgId,
		});

		if (error) {
			Logger.error(error, {}, "connectPractitioner");
			showNotification({
				message: "Fehler beim Verbinden des Behandlers (A5).",
				type: "error",
			});
			return;
		}

		if (data === "exists") {
			showNotification({
				message:
					"Zu dieser E-Mail besteht bereits eine Verbindung. Nutzen Sie 'Von bestehendem Auftraggeber'. ",
				type: "warning",
			});
			return;
		}

		window.location.reload();
	},

	/**
	 * This function removes the connect relationship and removes the connect_relationship_id from ALL connected clients
	 * To only remove the connect relationship from the current client, use the removeConnectRelationship function
	 */
	deleteConnectRelationshipEntirely: async () => {
		const clientId = get().clientId;
		if (!clientId) {
			showNotification({
				message: "Auftraggeber nicht gefunden.",
				type: "error",
			});
			return;
		}

		const { error } = await supabase.rpc("delete_connect_relationship", {
			client_id: clientId,
		});

		if (error) {
			showNotification({
				message: "Fehler beim Aufheben des Zugangs.",
				type: "error",
			});
			return;
		}

		window.location.reload();
	},
	/**
	 * This function removes the connect relationship only from the current client
	 * The relationship entry remains
	 */
	removeConnectRelationshipFromClient: async () => {
		const clientId = get().clientId;
		if (!clientId) {
			showNotification({
				message: "Auftraggeber nicht gefunden.",
				type: "error",
			});
			return;
		}

		const { error } = await supabase
			.from(SupabaseTableEnum.CLIENTS)
			.update({
				connect_relationship_id: null,
			})
			.eq("id", clientId);

		if (error) {
			showNotification({
				message: "Fehler beim Trennen der Connect-Verbindung.",
				type: "error",
			});
			return;
		}

		window.location.reload();
	},
});
