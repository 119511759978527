import {
	Autocomplete,
	TextField,
	FormControl,
	FormHelperText,
} from "@mui/material";
import { SelectProps } from "../forms/form-types";

interface CustomAutocompleteProps extends SelectProps {
	options: {
		value: string | number;
		label: string;
	}[];
	inputLabel: string;
	isMultiple?: boolean;
	helperText?: string;
}

export const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
	options,
	inputLabel,
	value,
	onChange,
	isMultiple = false,
	size = "small",
	disabled = false,
	width = "100%",
	required = false,
	helperText = "",
}) => {
	// Finde das aktuelle Label basierend auf dem Value
	const getCurrentOption = () => {
		const option = options.find((o) => o.value === value);
		return option ? option.label : value?.toString() || "";
	};

	return (
		<div
			style={{ width }}
			data-testid={`custom-autocomplete-${inputLabel.split(" ").join("-")}`}
		>
			<FormControl fullWidth size={size} required={required}>
				<Autocomplete
					value={getCurrentOption()}
					onChange={(_, newValue) => {
						// Wenn ein vorhandener Wert ausgewählt wurde
						const existingOption = options.find(
							(o) => o.label === newValue
						);
						if (existingOption) {
							onChange(existingOption.value.toString());
						} else {
							// Wenn ein neuer Wert eingegeben wurde
							onChange(newValue || "");
						}
					}}
					freeSolo
					disabled={disabled}
					options={options.map((o) => o.label)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={inputLabel}
							size={size}
							required={required}
						/>
					)}
					sx={{
						marginTop: "-8px", //TODO: replace that weird work around
					}}
				/>
				{helperText && <FormHelperText>{helperText}</FormHelperText>}
			</FormControl>
		</div>
	);
};
