import { toast } from "sonner";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Logger } from "@/lib/logger/Logger";

const backgroundColors = {
	error: "#ef4444",
	success: "#22c55e",
	warning: "#fb923c",
	info: "#bfdbfe",
};

const icons = {
	error: <ErrorIcon />,
	success: <CheckCircleIcon />,
	warning: <WarningIcon />,
	info: <InfoIcon />,
};

export const showNotification = ({
	message,
	type,
	duration,
	action,
	description,
	log,
}: {
	message: string;
	type: "error" | "success" | "info" | "warning";
	duration?: number;
	action?: {
		label: string;
		onClick: () => void;
	};
	description?: string;
	log?: {
		identifier: string;
	};
}) => {
	if (log) {
		Logger.error(message, {
			identifier: log.identifier,
		});
	}
	toast(message, {
		icon: icons[type],
		style: {
			backgroundColor: backgroundColors[type],
			color: type !== "success" ? "white" : "black",
		},
		// Using className because data-testid isn't available on the component
		className: `toast-${type}`,
		...(duration && {
			duration,
		}),

		...(action && {
			action,
		}),
		...(description && {
			description,
		}),
	});
};
