import { StateCreator } from "zustand";
import { supabase } from "../../supabase/supabaseClient";
import { SupabaseTableEnum } from "../../supabase/supabaseTypes";
import { RealtimeChannel } from "@supabase/supabase-js";
import { Logger } from "../../logger/Logger";
import { useRealtimeStore } from ".";

export interface RealtimeSubscriptionSlice {
	channels: RealtimeChannel[];
	subscribe: () => void;
	unsubscribe: () => Promise<void>;
}

export const createRealtimeSubscriptionStore: StateCreator<
	RealtimeSubscriptionSlice
> = (set, get) => ({
	channels: [],
	subscribe: () => {
		//const activityId = useRealtimeStore.getState().activityId;
		//Logger.info("Subscribing..", activityId);

		// TODO: this is not used currently but would be needed again once we re-establish threads/replies
		/* 		const channel = supabase
			.channel("replies_channel")
			.on(
				"postgres_changes",
				{
					event: "INSERT",
					schema: "public",
					table: SupabaseTableEnum.REPLIES,
					filter: `shared_id=eq.${activityId}`,
				},
				(payload) => {
					useRealtimeStore.getState().updateReplies(payload);
				}
			)
			.subscribe(); */

		const channel3 = supabase
			.channel("message_channel")
			.on(
				"postgres_changes",
				{
					event: "INSERT",
					schema: "public",
					table: SupabaseTableEnum.MESSAGES,
				},
				(payload) => {
					Logger.info("Message changes", payload);
					useRealtimeStore.getState().updateMessages(payload);
				}
			)
			.subscribe((status, err) => {
				if (status === "SUBSCRIBED") {
					Logger.info(
						"[realtime] Connected to realtime messages_channel!"
					);
				} else {
					Logger.error(
						`[realtime] Error subscribing to messages_channel: ${err}`,
						{
							identifier: "messages_channel",
						}
					);
				}
			});

		set({
			channels: [channel3],
		});
	},

	unsubscribe: async () => {
		Logger.info("Unsubscribing..");
		const channels = get().channels;

		await Promise.all(
			channels.map((c) => {
				c.unsubscribe();
			})
		);
	},
});
