import { ClientEntityType } from "../../../lib/supabase/supabaseTypes";
import { TpTier, TpValue } from "../types/enums";

export const clientExample: ClientEntityType = {
	id: "123e4567-e89b-12d3-a456-426614174000",
	created_at: "2021-06-29T15:00:00Z",
	modified_at: "2021-06-29T15:00:00Z",
	avatar_path: "https://example.com/avatar.jpg",
	code: "12345",
	note: "Wichtige Notiz",
	title: "Herr",
	first_name: "Max",
	last_name: "Mustermann",
	organization_id: "123e4567-e89b-12d3-a456-426614174000",
	additional_information: "Zusatzinfo 1, Zusatzinfo 2",
	street: "Musterstrasse 10",
	country: "Schweiz",
	city: "Bern",
	postal_code: "3000",
	phone_personal: "0123456789",
	phone_business: "0987654321",
	fax: "0123456789",
	favorite: null,
	phone_mobile: "0771234567",
	email: "max.mustermann@example.com",
	language: "Deutsch",
	c_title: "Frau",
	c_first_name: "Anna",
	c_last_name: "Musterfrau",
	c_additional_information: "Kontakt Zusatzinfo 1, Zusatzinfo 2",
	c_street: "Beispielweg 20",
	c_country: "Schweiz",
	c_city: "Zürich",
	c_postal_code: "8000",
	c_phone_business: "0987654321",
	c_phone_personal: "0123456789",
	c_fax: "0123456789",
	c_phone_mobile: "0791234567",
	c_email: "anna.musterfrau@example.com",
	is_correspondence_address: true,
	is_inv_w_pat_data: false,
	is_inv_w_job_title: true,
	is_inactive: false,
	inactive_reason: "",
	external_reference: "Ext12345",
	is_incl_dr_tax: true,
	is_print_del_note_w_pat_pass: false,
	is_print_del_note_w_col_card: true,
	is_print_pat_code_only: false,
	is_print_skonto: true,
	is_print_kv_w_col_card: false,
	sv_number: "756.1234.5678.97",
	monthly_discount: 5.0,
	is_monthly_discount: true,
	currency: "CHF",
	inv_footnote_type: "Standard",
	inv_footnote: "Rechnungsfußnote",
	guarantor_id: null,
	bank_account_id: 123,
	discount_work: 10.0,
	discount_material: 15.0,
	solvency: "gut",
	inv_print_qty: 2,
	del_note_print_qty: 1,
	kv_print_qty: 1,
	requests_text: "Spezielle Anforderungen",
	requests_checked: ["Anforderung 1", "Anforderung 2"],
	connect_relationship_id: "123e456",
	kun_id: null,
	default_tp_tier: TpTier.SV,
	default_tp_value: TpValue.NEW,
	default_tp_variation: 0,
	is_pt_name_in_doc_name: true,
	is_pt_name_in_xml_name: true,
	patient_name_format: 1,
	company_name: null,
	is_monthly_discount_only_work: true,
};
