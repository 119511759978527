import { usePdfTemplateConfigurationContext } from "../../context/PdfTemplateConfigurationContext";
import { KostenvoranschlagTemplate } from "../../pdf-templates/pdf-templates/kostenvoranschlag.template";
import { LieferscheinTemplate } from "../../pdf-templates/pdf-templates/lieferschein.template";
import { GutschriftTemplate } from "../../pdf-templates/pdf-templates/gutschrift.template";
import { AuftragsblattTemplate } from "../../pdf-templates/pdf-templates/auftragsblatt.template";
import { RechnungTemplate } from "../../pdf-templates/pdf-templates/rechnung.template";
import { SammelrechnungTemplate } from "../../pdf-templates/pdf-templates/sammelrechnung.template";
import { BarzahlungsquittungTemplate } from "../../pdf-templates/pdf-templates/barzahlungsquittung.template";
import { KartenzahlungsquittungTemplate } from "../../pdf-templates/pdf-templates/kartenzahlungsquittung.template";
import { AkontorechnungTemplate } from "../../pdf-templates/pdf-templates/akontorechnung.template";
import { useExportPdf } from "../useExportPdf";
import {
	ClientEntityType,
	GuarantorEntityType,
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	OrganizationEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import {
	CalculateTotalsResult,
	ExtendedJobDocument,
	calculateJobTotalsLegacy,
	calculateSammelrechnungRowsAndTotals,
} from "../../lib/utils/calculate";
import { PaymentSlipDataInput } from "../../pdf-templates/pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { XmlEndpointsEnum, useExportXml } from "../useExportXml/useExportXml";
import { useCentralStore } from "../../store/Central";
import { showNotification } from "../../store/Central/selectors";
import { Currency } from "../../types/enums";
import { useStorage } from "../useStorage";
import { getBankAccount } from "./get-bank-account/get-bank-account";

const DEFAULT_CURRENCY = Currency.CHF;
export interface OperationResult {
	success: boolean;
	error: string | null;
}

export interface CompleteJobDocumentInformation {
	client: ClientEntityType;
	job: JobEntityType;
	jobDocument: JobDocumentEntityType;
	jobItems: JobItemEntityType[];
}

interface DocumentPropsForReturnType {
	organization: OrganizationEntityType;
	job: JobEntityType;
	jobDocument: JobDocumentEntityType;
	jobItems: JobItemEntityType[];
	client: ClientEntityType;
	jobTotals: CalculateTotalsResult;
	guarantor: GuarantorEntityType | undefined;
}

export const documentPropsFor = (
	organization: OrganizationEntityType,
	props: CompleteJobDocumentInformation
): DocumentPropsForReturnType | null => {
	const { client, job, jobDocument, jobItems } = props;
	return {
		organization,
		job,
		jobDocument,
		jobItems,
		client,
		jobTotals: calculateJobTotalsLegacy({
			job,
			jobDocument,
			jobItems,
			organization,
			client,
		}),
		guarantor: job.guarantor_id
			? useCentralStore.getState().guarantorLookup[job.guarantor_id]
			: undefined,
	};
};

export const useExportActions = () => {
	const { pdfTemplateConfiguration } = usePdfTemplateConfigurationContext();
	const { bankAccountsLookup, clientsLookup } = useCentralStore((state) => ({
		bankAccountsLookup: state.bankAccountsLookup,
		clientsLookup: state.clientsLookup,
	}));
	const { exportPdf } = useExportPdf();
	const { exportXml } = useExportXml();
	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
	}));
	const { fileUrl } = useStorage();

	const paymentSlipDataFor = (
		props: CompleteJobDocumentInformation,
		invoiceNumber: string
	): PaymentSlipDataInput | null => {
		if (!organization) {
			showNotification({
				message: "Organisation nicht gefunden",
				type: "error",
			});
			return null;
		}
		const { client, job, jobDocument, jobItems } = props;

		const { bankAccount, error: bankAccountError } = getBankAccount({
			bankAccountsLookup,
			lab: organization,
			job,
			recipient: client,
		});
		if (!bankAccount) {
			showNotification({
				message: bankAccountError,
				type: "error",
			});
			return null;
		}

		const amount = calculateJobTotalsLegacy({
			job,
			jobDocument,
			jobItems,
			organization,
			client,
		}).total.text;
		const paymentSlipData: PaymentSlipDataInput = {
			bankAccount,
			recipient: client,
			amount,
			invoiceNumber,
			currency: DEFAULT_CURRENCY,
		};
		return paymentSlipData;
	};

	const getElementForKostenvoranschlag = (
		documentProps: Omit<
			React.ComponentProps<typeof KostenvoranschlagTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<KostenvoranschlagTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const getElementForLieferschein = (
		documentProps: Omit<
			React.ComponentProps<typeof LieferscheinTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<LieferscheinTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const getElementForAuftragsblatt = (
		documentProps: Omit<
			React.ComponentProps<typeof AuftragsblattTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<AuftragsblattTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const getElementForRechnung = (
		documentProps: Omit<
			React.ComponentProps<typeof RechnungTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<RechnungTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const getElementForGutschrift = (
		documentProps: Omit<
			React.ComponentProps<typeof GutschriftTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<GutschriftTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const getElementForBarzahlungsquittung = (
		documentProps: Omit<
			React.ComponentProps<typeof BarzahlungsquittungTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<BarzahlungsquittungTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const getElementForKartenzahlungsquittung = (
		documentProps: Omit<
			React.ComponentProps<typeof KartenzahlungsquittungTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<KartenzahlungsquittungTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const getElementForAkontorechnung = (
		documentProps: Omit<
			React.ComponentProps<typeof AkontorechnungTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<AkontorechnungTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const getElementForSammelrechnung = (
		documentProps: Omit<
			React.ComponentProps<typeof SammelrechnungTemplate>,
			"pdfTemplateConfiguration"
		>
	) => {
		return (
			<SammelrechnungTemplate
				{...documentProps}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		);
	};

	const exportRechnung = async (
		documentProps: DocumentPropsForReturnType,
		paymentSlipData: PaymentSlipDataInput,
		filePath: string,
		patient: PatientEntityType | undefined
	): Promise<OperationResult> => {
		const documentComponent = getElementForRechnung({
			...documentProps,
			paymentSlipData,
			patient,
		});

		return await exportPdf({
			documentComponent,
			filePath,
		});
	};

	const exportBarzahlungsquittung = async (
		documentProps: DocumentPropsForReturnType,
		paymentSlipData: PaymentSlipDataInput,
		filePath: string
	): Promise<OperationResult> => {
		const documentComponent = getElementForBarzahlungsquittung({
			...documentProps,
			paymentSlipData,
		});
		return await exportPdf({
			documentComponent,
			filePath,
		});
	};

	const exportKartenzahlungsquittung = async (
		documentProps: DocumentPropsForReturnType,
		paymentSlipData: PaymentSlipDataInput,
		filePath: string
	): Promise<OperationResult> => {
		const documentComponent = getElementForKartenzahlungsquittung({
			...documentProps,
			paymentSlipData,
		});
		return await exportPdf({
			documentComponent,
			filePath,
		});
	};

	interface ExportSammelrechnungProps {
		recipient: ClientEntityType | GuarantorEntityType;
		extendedJobDocuments: ExtendedJobDocument[];
		invoiceNumber: string;
		filePath: string;
		invoiceDate: Date;
	}

	const exportSammelrechnung = async ({
		recipient,
		extendedJobDocuments,
		invoiceNumber,
		filePath,
		invoiceDate,
	}: ExportSammelrechnungProps): Promise<OperationResult> => {
		if (!organization) {
			showNotification({
				message: "Organisation nicht gefunden",
				type: "error",
			});
			return {
				success: false,
				error: "Organisation nicht gefunden",
			};
		}

		const { bankAccount, error: bankAccountError } = getBankAccount({
			bankAccountsLookup,
			lab: organization,
			job: null,
			recipient,
		});
		if (!bankAccount) {
			showNotification({
				message: bankAccountError,
				type: "error",
			});
			return {
				success: false,
				error: bankAccountError,
			};
		}
		const sammelrechnungCalculationResult =
			calculateSammelrechnungRowsAndTotals(
				extendedJobDocuments,
				organization,
				recipient,
				clientsLookup
			);
		const paymentSlipData: PaymentSlipDataInput = {
			bankAccount: bankAccount,
			recipient,
			amount: sammelrechnungCalculationResult.total.text,
			invoiceNumber,
			currency: DEFAULT_CURRENCY,
		};
		const documentProps = {
			recipient,
			sammelrechnungCalculationResult,
			organization,
			paymentSlipData,
			logoUrl: pdfTemplateConfiguration?.general.logoImgurl,
			currentDate: invoiceDate.toISOString(),
		};
		const element = getElementForSammelrechnung(documentProps);
		return await exportPdf({
			documentComponent: element,
			filePath,
		});
	};

	const exportKostenvoranschlag = async (
		documentProps: DocumentPropsForReturnType,
		filePath: string,
		patient?: PatientEntityType
	): Promise<OperationResult> => {
		const element = getElementForKostenvoranschlag({
			...documentProps,
			patient,
		});
		return await exportPdf({
			documentComponent: element,
			filePath,
		});
	};

	const exportLieferschein = async (
		documentProps: DocumentPropsForReturnType,
		filePath: string,
		patient?: PatientEntityType
	): Promise<OperationResult> => {
		const element = getElementForLieferschein({
			...documentProps,
			patient,
		});
		return await exportPdf({
			documentComponent: element,
			filePath,
		});
	};

	const exportAuftragsblatt = async (
		documentProps: DocumentPropsForReturnType,
		filePath: string
	): Promise<OperationResult> => {
		let clientAvatarPath = documentProps.client?.avatar_path;
		let clientAvatarUrl = "";

		// TODO(Eyob): Why is the client.avatar_path already signed?
		// https://dentdesk.atlassian.net/browse/DW-884
		if (clientAvatarPath && clientAvatarPath?.startsWith("http")) {
			clientAvatarPath = clientAvatarPath
				.split("supabase.co/storage/v1/render/image/sign/v1/")[1]
				.split("?")[0];
		}
		if (clientAvatarPath) {
			const imageSrc = await fileUrl(clientAvatarPath);
			if (imageSrc.data) {
				clientAvatarUrl = imageSrc.data.signedUrl;
			}
		}
		const element = getElementForAuftragsblatt({
			...documentProps,
			qrCodeUrl: window.location.origin + location.pathname,
			clientAvatarUrl: clientAvatarUrl ?? undefined,
		});
		return await exportPdf({
			documentComponent: element,
			filePath,
		});
	};

	const exportGutschrift = async (
		documentProps: DocumentPropsForReturnType,
		filePath: string,
		patient?: PatientEntityType
	): Promise<OperationResult> => {
		const element = getElementForGutschrift({
			...documentProps,
			patient,
		});
		return await exportPdf({
			documentComponent: element,
			filePath,
		});
	};

	const exportXmlLieferschein = async (
		documentProps: CompleteJobDocumentInformation,
		patient: PatientEntityType,
		fileName: string
	) => {
		return exportXml({
			documentProps,
			patient,
			endpoint: XmlEndpointsEnum.DELIVERY_NOTE,
			fileName: fileName,
		});
	};
	const exportXmlKostenvoranschlag = async (
		props: CompleteJobDocumentInformation,
		patient: PatientEntityType,
		fileName: string
	) => {
		if (!organization) {
			showNotification({
				message: "Organisation nicht gefunden",
				type: "error",
			});
			return {
				success: false,
				error: "Organisation nicht gefunden",
			};
		}
		const documentProps = documentPropsFor(organization, props);
		if (!documentProps) {
			return {
				success: false,
				error: "Dokumentinformationen konnten nicht erstellt werden",
			};
		}
		return exportXml({
			documentProps,
			patient,
			endpoint: XmlEndpointsEnum.QUOTATION,
			fileName: fileName,
		});
	};

	return {
		paymentSlipDataFor,
		exportRechnung,
		exportBarzahlungsquittung,
		exportKartenzahlungsquittung,
		exportSammelrechnung,
		exportKostenvoranschlag,
		exportLieferschein,
		exportAuftragsblatt,
		exportGutschrift,
		exportXmlLieferschein,
		exportXmlKostenvoranschlag,
	};
};
