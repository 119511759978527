import { Button } from "@mui/material";
import { SidebarType } from "../../../../types/types";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { JobCodeForm } from "../../../../forms/form-components/job/update/job-code.form";
import { PatientProfileButton } from "../patient-profile-button/patient-profile-button.component";
import { JobTitleForm } from "../../../../forms/form-components/job/update/job-title.form";
import { useEffect, useState } from "react";
import { useDimensions } from "../../../../../../lib/hooks/useDimensions";
import EditIcon from "@mui/icons-material/Edit";
import { PatientWithShare } from "@/lib/supabase/supabaseTypes";
import { Plus } from "lucide-react";
import clsx from "clsx";
import { usePatientStore } from "@/dentlab/src/store/Patient";
import { CustomButtonWithAnchoredMenu } from "@/dentlab/src/components/custom-button-with-anchored-menu/custom-button-with-anchored-menu.component";
import { CustomMenuItem } from "../../job-document/job-document-components";
import { FileDropDown } from "@/dentlab/src/components/file-display-components/file-dropdown.component";
import { PatientCommandDialog } from "../patient-info-dialog/components/patient-command-dialog.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { PatientFormDialog } from "@/dentlab/src/pages-via-dialog/patient/patient.dialog";
import { PinJob } from "../../pin-job.component";
import { JobStatusEnum } from "@/lib/types/job";

export const JobPageHeader: React.FC<{
	isSidebarOpen: boolean;
	onSidebarOpen: (type: SidebarType) => void;
}> = ({ isSidebarOpen, onSidebarOpen }) => {
	const {
		patientName,
		getPatient,
		upsertPatient,
		patientsLookup,
		createPatientFromName,
	} = usePatientStore((state) => ({
		patientName: state.patientName,
		getPatient: state.getPatient,
		upsertPatient: state.upsertPatient,
		patientsLookup: state.patientsLookup,
		createPatientFromName: state.createPatientFromName,
	}));
	const { job, updateJob, uploadFile, jobFiles, shareFile } = useJobStore(
		(state) => ({
			job: state.job,
			updateJob: state.updateJob,
			uploadFile: state.uploadFile,
			jobFiles: state.jobFilesLookup,
			shareFile: state.shareFile,
		})
	);

	// This is only used for the patient form dialog, not to determine whether the job has a patient!
	const [patient, setPatient] = useState<PatientWithShare | undefined>(
		undefined
	);

	const [existingPatientFormOpen, setExistingPatientFormOpen] =
		useState(false);
	const [newPatientFormOpen, setNewPatientFormOpen] = useState(false);
	const [patientCommandDialogOpen, setPatientCommandDialogOpen] =
		useState(false);

	const handleOpenExistingPatient = async (patientId: string) => {
		setExistingPatientFormOpen(true);
		if (!patientId) {
			showNotification({
				message: "Patient nicht gefunden",
				type: "error",
			});
			return;
		}
		const patient = await getPatient(patientId);
		if (patient) {
			setPatient(patient);
		} else {
			showNotification({
				message: "Patient nicht gefunden",
				type: "error",
			});
		}
	};

	const handleOpenPatientCommandDialog = () => {
		setPatientCommandDialogOpen(true);
	};

	const handlePatientSelect = (patientId: string) => {
		updateJob("patient_id", patientId);
		setPatientCommandDialogOpen(false);
	};

	const handleNewBlankPatient = () => {
		setNewPatientFormOpen(true);
	};

	const handleNewPatientWithPrefill = async (nameForPrefill: string) => {
		const patientId = await createPatientFromName(nameForPrefill);
		if (patientId) {
			updateJob("patient_id", patientId);
		}
	};

	const { width, updateDimensions, measuredDivRef } = useDimensions();

	const handleOpenPatientViaShortcut = () => {
		const currentJob = useJobStore.getState().job;
		if (currentJob?.patient_id) {
			handleOpenExistingPatient(currentJob.patient_id);
		} else {
			handleOpenPatientCommandDialog();
		}
	};

	useEffect(() => {
		updateDimensions();
	}, [isSidebarOpen]);

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "p" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				handleOpenPatientViaShortcut();
			}
		};
		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, []);

	const TitleCodePatientGroup: React.ReactNode = (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyItems: "center",
				gap: "10px",
				marginLeft: "10px",
				height: "100%",
			}}
		>
			<PatientCommandDialog
				onPatientSelect={handlePatientSelect}
				onNewBlankPatient={handleNewBlankPatient}
				onNewPatientWithPrefill={handleNewPatientWithPrefill}
				open={patientCommandDialogOpen}
				setOpen={() => {
					setPatientCommandDialogOpen(false);
				}}
			/>
			<PatientFormDialog
				open={existingPatientFormOpen}
				onClose={() => {
					setExistingPatientFormOpen(false);
				}}
				patient={patient}
				onSave={(patient) => {
					upsertPatient(patient as PatientWithShare);
				}}
			/>
			<PatientFormDialog
				open={newPatientFormOpen}
				onClose={() => {
					setNewPatientFormOpen(false);
				}}
				onSave={(patient) => {
					upsertPatient(patient as PatientWithShare);
					updateJob("patient_id", patient.id as string);
					setNewPatientFormOpen(false);
				}}
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "10px",
				}}
			>
				<JobTitleForm />
				<JobCodeForm
					jobId={job?.id ?? 0}
					jobCode={job?.code ?? ""}
					jobStatus={job?.status as JobStatusEnum}
				/>
			</div>
			{job?.patient_id ? (
				<PatientProfileButton
					name={patientName(patientsLookup[job.patient_id])}
					onClick={() =>
						handleOpenExistingPatient(job.patient_id as string)
					}
				/>
			) : (
				<Button
					variant="outlined"
					startIcon={<PersonAddIcon />}
					onClick={handleOpenPatientCommandDialog}
					data-testid="add-patient-button"
				>
					Patient
				</Button>
			)}
			<Button
				variant="outlined"
				startIcon={<EditIcon />}
				onClick={() => onSidebarOpen("jobInfo")}
			>
				Auftrag
			</Button>
		</div>
	);

	const ButtonGroup: React.ReactNode = (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				gap: "10px",
				height: "100%",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginRight: "10px",
					gap: "10px",
				}}
			>
				<CustomButtonWithAnchoredMenu>
					<CustomMenuItem
						title={""}
						menuFunction={() => {}}
						disabled={false}
						text="Auftragsetikette mit QR-Code drucken"
						placement="left"
					/>
					<CustomMenuItem
						title={""}
						menuFunction={() => {}}
						disabled={false}
						text="Auftragsetikette ohne QR-Code drucken"
						placement="left"
					/>
					<CustomMenuItem
						title={""}
						menuFunction={() => {}}
						disabled={false}
						text="Versandetikette drucken"
						placement="left"
					/>
					<CustomMenuItem
						title={""}
						menuFunction={() => {}}
						disabled={false}
						text="Modelletikette drucken"
						placement="left"
					/>
				</CustomButtonWithAnchoredMenu>
				{job && <PinJob job={job} />}
			</div>
		</div>
	);

	const [loading, setLoading] = useState(false);

	return (
		<>
			<div
				ref={measuredDivRef}
				style={{
					height: "100%",
					backgroundColor: "white",
				}}
			>
				{width && width > 820 ? (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							height: "var(--height-header-md)",
							borderBottom: "var(--border-sm)",
						}}
					>
						<div>{TitleCodePatientGroup}</div>
						<div className="flex items-center gap-4">
							{ButtonGroup}
						</div>
					</div>
				) : (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							style={{
								borderBottom: "var(--border-sm)",
								minHeight: "var(--height-header-md)",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
							}}
						>
							{TitleCodePatientGroup}
						</div>
						<div
							style={{
								height: "var(--height-header-md)",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								paddingLeft: "15px",
								borderBottom: "var(--border-sm)",
								gap: "20px",
							}}
						>
							{ButtonGroup}
						</div>
					</div>
				)}
				<div className="flex border-b gap-0 justify-between">
					<div className="flex flex-row items-center overflow-x-auto gap-1 p-[3px]">
						{Object.values(jobFiles).map((file) => {
							// If the file is attached to a job_document it is already shown in the job_document interface
							if (file.job_document_id) {
								return null;
							}
							return (
								<FileDropDown
									key={file.id}
									file={file}
									onShareFile={() => {
										if (file.id) {
											shareFile(file.id);
										}
									}}
								/>
							);
						})}
					</div>
					<div className="m-1 cursor-pointer flex items-center justify-center">
						<label
							htmlFor="job-file"
							className={clsx(
								"cursor-pointer w-full hover:bg-neutral-100 rounded-sm text-md",
								{
									"cursor-wait": loading,
									"text-gray-300": loading,
								}
							)}
						>
							<div className="flex flex-row gap-1 px-2 w-full items-center">
								<span className="whitespace-nowrap text-ellipsis overflow-hidden text-gray-700">
									Datei hinzufügen
								</span>
								<Plus
									className={clsx("text-gray-700", {
										"text-gray-500": loading,
									})}
									size="15px"
								/>
							</div>
						</label>
					</div>
					<input
						disabled={loading}
						type="file"
						className="hidden"
						id="job-file"
						onChange={async (e) => {
							if (
								!e.target.files ||
								e.target.files?.length <= 0 ||
								!job?.id
							)
								return;
							setLoading(true);
							const file = e.target.files?.[0];
							await uploadFile(file, job.id);
							setLoading(false);
						}}
					/>
				</div>
			</div>
		</>
	);
};
