import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SelectProps } from "../forms/form-types";
import { FormHelperText } from "@mui/material";

interface CustomSelectProps extends SelectProps {
	options: {
		value: string | number;
		label: string;
	}[];
	inputLabel: string;
	isMultiple?: boolean;
	helperText?: string;
	props?: any;
}

/**
 * CustomSelect - Custom select component
 * @param options - options to display in select
 * @param inputLabel - label to display above select
 * @param value - value of select
 * @param onChange - function to call when select value changes
 * @param isMultiple - whether select should allow multiple values
 * @param size - size of select
 * @param disabled - whether select should be disabled
 * @returns
 */
export const CustomSelect: React.FC<CustomSelectProps> = ({
	options,
	inputLabel,
	value,
	onChange,
	isMultiple = false,
	size = "small",
	disabled = false,
	width = "100%",
	required = false,
	helperText = "",
	props,
}) => {
	return (
		<div
			style={{
				width: width,
			}}
			data-testid={`custom-select-${inputLabel.split(" ").join("-")}`}
		>
			<FormControl fullWidth size={size} required={required}>
				<InputLabel>{inputLabel}</InputLabel>
				<Select
					value={value ?? ""}
					label={inputLabel}
					onChange={(e: SelectChangeEvent) =>
						onChange(e.target.value)
					}
					multiple={isMultiple}
					disabled={disabled}
					{...props}
				>
					{options.map((o) => (
						<MenuItem
							key={o.value}
							value={o.value}
							data-testid={
								o.label
									? `custom-select-${o.label
											.split(" ")
											.join("-")}`
									: "custom-select-no-label"
							}
						>
							{o.label}
						</MenuItem>
					))}
				</Select>
				{helperText && <FormHelperText>{helperText}</FormHelperText>}
			</FormControl>
		</div>
	);
};
