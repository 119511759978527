import { useState, useMemo } from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { supabase } from "@/lib/supabase";
import { useCentralStore } from "../../store/Central";
import { Logger } from "@/lib/logger/Logger";
import { useNavigate } from "react-router-dom";
import { ArticleForm } from "./article-form.component";
import { PageTitle } from "@/components/src/page-title/page-title.component";
import { showNotification } from "../../store/Central/selectors";

export const ArticleDialog = () => {
	const [open, setOpen] = useState(false);

	const defaultValues = useMemo(() => ({}), []);
	const navigate = useNavigate();
	const { organization, getArticles } = useCentralStore((state) => ({
		organization: state.organization,
		getArticles: state.getArticles,
	}));

	const methods = useForm({
		defaultValues,
	});

	const handleCreateArticle = async (data: any) => {
		if (!organization) {
			Logger.error("Organization not found in TariffDialog");
			return;
		}
		const { data: newArticle, error } = await supabase
			.from(SupabaseTableEnum.ARTICLES)
			.insert({
				...data,
				organization_id: organization.id,
			})
			.select()
			.single();
		if (error) {
			Logger.error(
				JSON.stringify({ error, data }),
				{
					identifier: "create_article",
				},
				"Error creating article"
			);
			showNotification({
				message: "Fehler beim Erstellen des Artikels",
				type: "error",
			});
			return;
		}
		await getArticles();
		setOpen(false);
		showNotification({
			message: "Artikel erfolgreich erstellt",
			type: "success",
		});
		navigate(`/article/${newArticle.code_e}`);
	};

	// Reset the form each time the dialog opens
	const handleOpen = () => {
		methods.reset(defaultValues);
		setOpen(true);
	};

	return (
		<>
			<Button variant="contained" onClick={handleOpen}>
				Neuer Artikel
			</Button>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				fullWidth
				maxWidth="md"
			>
				<DialogContent>
					{open && (
						<FormProvider {...methods}>
							<div>
								<PageTitle
									title={[
										{
											name: "Artikel",
											link: "/article",
										},
										{
											name: "Neue Artikel",
										},
									]}
								/>
							</div>
							<ArticleForm onSubmit={handleCreateArticle} />
						</FormProvider>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)}>Abbrechen</Button>
					<Button
						onClick={methods.handleSubmit(handleCreateArticle)}
						variant="contained"
						color="primary"
					>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
