import React from "react";
import {
	usePractitionerName,
	useUserName,
} from "../../../../dentlab/src/hooks/useName/useNameHooks";
import { ActivityEntityType } from "../../../../lib/supabase/supabaseTypes";
import { getDayAndTime } from "../../../../dentlab/src/lib/utils/utils-functions";

type CreatorType = "lab" | "dentist" | "system";

/**
 * ActivityTitleWithUserAndDate
 * @param {any} activity - activity object
 */
export const ActivityTitleWithUserAndDate: React.FC<{
	activity: ActivityEntityType;
	hideDate?: boolean;
}> = ({ activity, hideDate }) => {
	const title = getActivityTitle(activity.activity_type);
	const date = getDayAndTime(activity.created_at);

	// TODO: Re-implement user name
	// let userName: React.ReactNode = "";
	/* 	if (activity.creator_type === "lab") {
        userName = <LabUserName profileId={activity.profile_id} />;
        // TODO: implement in extra component using context
    } else if (activity.creator_type === "dentist") {
        userName = <PractitionerUserName profileId={activity.profile_id} />;
    } else if (activity.creator_type === "system") {
        userName = "System";
    } */
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<div
				style={{
					fontWeight: "bold",
					fontSize: "18px",
					lineHeight: "20px",
				}}
			>
				{title}
			</div>
			<div
				style={{
					fontSize: "14px",
				}}
			>
				{!hideDate && <span> {date} von </span>}

				<span
					style={{
						color: "blue",
					}}
				>
					{/* {userName} */}
				</span>
			</div>
		</div>
	);
};

export const getActivityTitle = (activityType: string | null) => {
	switch (activityType) {
		case "job_request_status":
			return "Auftragsanfrage aktualisiert";
		case "shared_file":
			return "Datei geteilt";
		case "shared_job":
			return "Auftrag geteilt";
		case "shared_document":
			return "Dokument geteilt";
		case "shared_patient":
			return "Patient geteilt";
		case "message_general":
			return "Neue Nachricht";
		case "message_job":
			return "Neue Nachricht zu einem Auftrag";
		case "shared_job_request":
			return "Auftragsanfrage";
		case "srg":
			return "Sammelrechnung geteilt";
		default:
			return "";
	}
};

const PractitionerUserName: React.FC<{ profileId: string }> = ({
	profileId,
}) => {
	const { name } = usePractitionerName(profileId);
	return name;
};

const LabUserName: React.FC<{ profileId: string }> = ({ profileId }) => {
	const { name } = useUserName(profileId);
	return name;
};
