import { StateCreator } from "zustand";
import { adjustJobStatus } from "./utils";
import { Logger } from "@/lib/logger/Logger";
import { goBackOrForwardDays } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import {
	JobEntityType,
	JobWithShare,
	FileWithShare,
	SupabaseShareEntityViewEnum,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { JobStatusEnum } from "@/lib/types/job";
import { useCentralStore } from "../Central";
import { showNotification } from "../Central/selectors";
import { produce } from "immer";
import { StorageBucketsEnum, TpTier, DefaultValues } from "../../types/enums";
import { usePatientStore } from "../Patient";
import { JobStoreUnion } from ".";
import {
	initialJobDocumentsState,
	JobDocumentWithFiles,
} from "./job-documents.store";
import { initialJobListState } from "./job-list.store";
import { initialJobItemState } from "./job-items.store";
import { v4 } from "uuid";
import { uploadFileWithProvidedPath } from "../../hooks/useStorage";
import { MessageType } from "@/lib/store/Realtime/message.types";

interface JobState {
	job: JobWithShare | null;
	jobLoading: boolean;
	pendingChanges: boolean;
	jobFilesLookup: Record<string, FileWithShare>;
}

export const initialJobState: JobState = {
	job: null,
	jobLoading: false,
	pendingChanges: false,
	jobFilesLookup: {},
};

export interface JobSlice extends JobState {
	handleJobRouteChange: (jobIdParam: string) => Promise<void>;
	// TODO: Currently fetchJob is only used in job-general.form.tsx and it might make sense to remove it
	fetchJob: (jobId: number) => Promise<void>;
	createJob: () => Promise<JobEntityType | null>;
	updateGuarantor: (guarantorId: string | null) => Promise<void>;
	deleteJob: (jobId: number, jobStatus: JobStatusEnum) => Promise<void>; // A jobId is needed because the job can also be deleted from the job list (e.g. archiving a job)
	changeJobStatus: (
		jobId: number, // A jobId is needed because the job can also be changed from the job list (e.g. archiving a job)
		currentStatus: JobStatusEnum,
		targetStatus: JobStatusEnum
	) => Promise<boolean>;
	updateJob: (
		field: "title" | "code" | "patient_id",
		value: string | number,
		overrideJobId?: number
	) => Promise<boolean>;
	reset: () => void;
	setPendingChanges: (pendingChanges: boolean) => void;
	pendingChangesNotification: () => void;
	shareFile: (fileId: string, jobDocumentId?: number) => Promise<void>;
	uploadFile: (file: File, jobId: number) => Promise<void>;
	fetchJobFiles: (jobId: number) => Promise<void>;
	getDefaultValues: () => Promise<{
		tpValueForJob: number;
		tpTierForJob: TpTier;
		tpVariationForJob: number;
		taxRateForJob: number;
	}>;
	acceptJobRequest: (jobId: number) => Promise<void>;
	denyJobRequest: (jobId: number) => Promise<void>;
}

export const createJobStore: StateCreator<JobStoreUnion, [], [], JobSlice> = (
	set,
	get
) => ({
	...initialJobState,
	handleJobRouteChange: async (jobIdParam: string) => {
		const jobId = Number(jobIdParam);
		if (!jobIdParam || jobId === get().job?.id) return;

		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.JOBS_WITH_SHARES)
			.select(
				`*, ${SupabaseTableEnum.JOB_DOCUMENTS} (*,${SupabaseShareEntityViewEnum.FILES_WITH_SHARES}(*))`
			)
			.eq("id", jobId)
			.single();

		if (error) {
			Logger.error(error, {}, "Error fetching job");
			return;
		}

		const { job_documents, ...job } = data;

		set({
			job: job,
			jobDocuments: Array.isArray(job_documents)
				? job_documents.reduce(
						(acc: Record<number, JobDocumentWithFiles>, curr) => {
							acc[curr.id] = curr;
							return acc;
						},
						{}
					)
				: {},
		});

		await get().fetchJobFiles(jobId);
	},
	fetchJobFiles: async (jobId: number) => {
		const { data: jobFiles, error: jobFilesError } = await supabase
			.from(SupabaseShareEntityViewEnum.FILES_WITH_SHARES)
			.select()
			.eq("job_id", jobId);

		if (jobFilesError) {
			showNotification({
				message: "Fehler beim Abrufen der Dateien für den Auftrag",
				type: "error",
			});
			Logger.error(jobFilesError, {}, "Error fetching files for job");
			set({
				jobFilesLookup: {},
			});
			return;
		}

		set(
			produce((state) => {
				state.jobFilesLookup = jobFiles.reduce(
					(
						acc: Record<string, FileWithShare>,
						row: FileWithShare
					) => {
						acc[row.id as string] = row;
						return acc;
					},
					{}
				);
			})
		);
	},

	setPendingChanges: (pendingChanges: boolean) => {
		set({
			pendingChanges,
		});
	},
	createJob: async () => {
		set({
			jobLoading: true,
		});
		const { client, organization } = useCentralStore.getState();

		if (!client || !organization || !organization?.id) {
			showNotification({
				message:
					"Fehler beim Erstellen des Auftrags durch fehlende Daten.",
				type: "error",
			});
			Logger.error(
				"Unexpected error creating job. Missing client or organization data."
			);
			return null;
		}
		const {
			tpValueForJob,
			tpTierForJob,
			tpVariationForJob,
			taxRateForJob,
		} = await get().getDefaultValues();

		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.insert({
				code: "", // If code = '' it will be set on insert on the db
				client_id: client.id,
				title: "Neuer Auftrag",
				status: JobStatusEnum.IN_PROGRESS,
				organization_id: organization?.id,
				incoming_order_date: new Date().toISOString(),
				outgoing_order_date: goBackOrForwardDays(7),
				tax_rate: taxRateForJob,
				guarantor_id: client.guarantor_id,
				tp_tier: tpTierForJob,
				tp_value: tpValueForJob,
				tp_variation: tpVariationForJob,
			})
			.select()
			.single();

		if (error) {
			showNotification({
				message: "Fehler beim Erstellen des Auftrags",
				type: "error",
			});
			Logger.error(error);
			return null;
		}

		if (data) {
			Logger.info({
				message: "Auftrag erstellt",
				type: "success",
			});
			set(
				produce((state) => {
					state.job = {
						...data,
						job_documents: [],
					};
					state.jobDocuments = [];
					state.jobList[JobStatusEnum.IN_PROGRESS].jobs.unshift({
						...data,
						job_documents: [],
					});
					state.jobList[JobStatusEnum.IN_PROGRESS].jobCount += 1;
					state.jobFilesLookup = {};
				})
			);
			set({
				jobLoading: false,
			});
			return {
				...data,
				job_documents: [],
				shared_ids: [],
				connect_relationship_id: null,
			};
		} else {
			showNotification({
				message: "Fehler beim Erstellen des Auftrags",
				type: "error",
			});
			return null;
		}
	},
	fetchJob: async (jobId: number) => {
		if (!jobId) return;

		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.JOBS_WITH_SHARES)
			.select(
				`*, ${SupabaseTableEnum.JOB_DOCUMENTS} (*,${SupabaseTableEnum.FILES}(*))`
			)
			.eq("id", jobId)
			.single();

		if (error) {
			set({
				job: null,
			});
			return;
		}

		const { job_documents, ...job } = data;

		set(
			produce((state) => {
				state.job = {
					...job,
					shared_ids: job.shared_ids?.filter((j) => j) ?? [],
				};
			})
		);

		// This is for a case where for some reason the patient hasn't fetched yet; could be done in a more clever way
		if (job.patient_id) {
			await usePatientStore.getState().getPatient(job.patient_id);
		}
	},
	deleteJob: async (jobId: number, jobStatus: JobStatusEnum) => {
		let job: JobWithShare | null = null;

		// Optimistic update
		set(
			produce((state) => {
				job = state.job;

				// If the currently selected job is deleted, set it to null
				if (state.job?.id === jobId) {
					state.job = null;
				}

				// Remove the job from the list of its current status
				const jobIndex = state.jobList[jobStatus].jobs.findIndex(
					(j: JobWithShare) => j.id === jobId
				);
				if (jobIndex !== -1) {
					state.jobList[jobStatus].jobCount -= 1;
					state.jobList[jobStatus].jobs.splice(jobIndex, 1);
				}

				// Remove the job from pinned jobs if it is pinned
				delete state.pinnedJobs[jobId];
			})
		);
		const { error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.delete()
			.eq("id", jobId);

		if (error) {
			Logger.error(error, {}, "Error deleting job");
			set(
				produce((state) => {
					state.job = job;
					state.jobList[jobStatus].jobCount += 1;
					state.jobList[jobStatus].jobs.shift(job);
					state.pinnedJobs[jobId] = job;
				})
			);

			showNotification({
				message: "Fehler beim Löschen des Auftrags",
				type: "success",
			});
			return;
		}
		showNotification({
			message: "Auftrag gelöscht",
			type: "success",
		});
	},

	/**
	 * updateGuarantor
	 * Allowed if: No job document has been created that has job items
	 */
	updateGuarantor: async (guarantorId: string | null) => {
		const { job } = get();
		if (!job) {
			showNotification({
				message: "Auftrag ist nicht gesetzt",
				type: "error",
			});
			return;
		}

		const someJobDocumentWithJobItems = Object.values(
			get().jobDocuments
		).some(({ id }) => get().jobItemsForDocuments[id]?.length > 0);
		if (someJobDocumentWithJobItems) {
			showNotification({
				message:
					"Der Garant kann nicht mehr geändert werden, da bereits Dokumente mit Positionen erstellt wurden",
				type: "error",
			});
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.update({ guarantor_id: guarantorId })
			.eq("id", job.id as number);

		if (error) {
			showNotification({
				message: "Fehler beim Aktualisieren des Garanten",
				type: "error",
			});
			Logger.log(error);
			return;
		}

		if (data) {
			set({
				job: {
					...job,
					guarantor_id: guarantorId,
				},
			});
			showNotification({
				message: "Garant aktualisiert",
				type: "success",
			});
		}
	},

	changeJobStatus: async (
		jobId: number,
		currentStatus: JobStatusEnum,
		targetStatus: JobStatusEnum
	) => {
		set(
			produce((state) => {
				adjustJobStatus(state, jobId, targetStatus, currentStatus);
				state.job = {
					...state.job,
				};
			})
		);

		const { error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.update({
				status: targetStatus,
			})
			.eq("id", jobId);

		if (error) {
			set(
				produce((state) => {
					// Revert the changes
					adjustJobStatus(state, jobId, currentStatus, targetStatus);
				})
			);
			get().fetchJob(jobId);
			return false;
		}

		return true;
	},

	/*
	 * If overrideJobId is not provided, it is set to the current job id
	 */
	updateJob: async (
		field: "title" | "code" | "patient_id",
		value: string | number,
		overrideJobId?: number
	) => {
		let previousJob: JobWithShare | null = null;
		const jobId: number | null = overrideJobId || get().job?.id || null;
		let jobStatus: JobStatusEnum | null = null;
		let jobIndex: number = -1;

		if (!jobId) {
			showNotification({
				message: "Auftrag ist nicht gesetzt",
				type: "error",
			});
			return false;
		}

		set(
			produce((state) => {
				previousJob = state.job;
				jobStatus = state.job?.status as JobStatusEnum;
				jobIndex = state.jobList[jobStatus].jobs.findIndex(
					(j: JobWithShare) => j.id === jobId
				);

				state.job[field] = value;
				if (jobIndex !== -1) {
					state.jobList[jobStatus].jobs[jobIndex][field] = value;
				}
			})
		);

		const { error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.update({ [field]: value })
			.eq("id", jobId);

		if (error) {
			set(
				produce((state) => {
					state.job = previousJob;
					if (jobIndex !== -1 && jobStatus) {
						state.jobList[jobStatus].jobs[jobIndex] = previousJob;
					}
				})
			);
			showNotification({
				message:
					"Fehler beim Aktualisieren des Auftrags in der Datenbank",
				type: "error",
			});
			return false;
		} else {
			showNotification({
				message: "Auftrag aktualisiert",
				type: "success",
			});
		}

		return true;
	},
	reset: () => {
		set({
			...initialJobState,
			...initialJobDocumentsState,
			...initialJobListState,
			...initialJobItemState,
		});
	},
	pendingChangesNotification: () => {
		if (get().pendingChanges) {
			showNotification({
				message:
					"Ausführung trotz ungespeicherter Auftragsinformationen.",
				type: "warning",
			});
		}
	},
	shareFile: async (fileId: string, jobDocumentId?: number) => {
		const { data, error } = await supabase.rpc("share_file", {
			share_file_id: fileId,
			message_content: {
				type: "shared_file",
				file_id: fileId,
				// TODO: Option to add text?
				text: "",
			},
		});
		if (error) {
			showNotification({
				message: "Fehler beim Teilen der Datei",
				type: "error",
			});
		} else if (!(data as any)?.success) {
			showNotification({
				message: (data as any)?.message,
				type: "error",
			});
		} else {
			showNotification({
				message: "Datei geteilt",
				type: "success",
			});

			// Update file to display it as shared
			if (jobDocumentId) {
				// TODO: properly update only the file in question
				// refetch the job document
				get().fetchJobDocument(jobDocumentId);
			} else {
				// TODO: properly update only the file in question
				// for now just refetch the job
				await get().fetchJobFiles(get().job?.id ?? 0);
			}

			// If job wasn't shared yet, update state
			if (!get().job?.shared_with) {
				set(
					produce((state) => {
						state.job["shared_with"] = v4();
					})
				);
			}
		}
	},
	uploadFile: async (file: File, jobId: number) => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) {
			showNotification({
				message:
					"Fehler beim Hochladen der Datei für den Auftrag (Keine Organisation ausgewählt)",
				type: "error",
			});
			return;
		}

		const fileName = file.name;
		const filePath = `${v4()}/${fileName}`;

		const { success, pathName } = await uploadFileWithProvidedPath({
			fileBody: file,
			filePath: filePath,
		});

		if (!success || !pathName) {
			showNotification({
				message:
					"Fehler beim Hochladen der Datei für den Auftrag (Fehler beim Hochladen der Datei auf den Server)",
				type: "error",
			});
			Logger.error("Error uploading file for job");
			return;
		}
		const { error, data } = await supabase
			.from(SupabaseTableEnum.FILES)
			.insert([
				{
					file_name: fileName,
					bucket_name: StorageBucketsEnum.V1,
					path_name: pathName,
					job_id: jobId,
					organization_id: organizationId,
				},
			])
			.select()
			.single();

		if (error) {
			showNotification({
				message:
					"Fehler beim Hochladen der Datei für den Auftrag (Fehler beim Speichern der Datei in der Datenbank)",
				type: "error",
			});
			return;
		}

		set(
			produce((state) => {
				state.jobFilesLookup[data.id] = data;
			})
		);
	},
	getDefaultValues: async () => {
		const { organization, client } = useCentralStore.getState();

		const taxRateForJob =
			organization?.mwst_percentage ?? DefaultValues.TAX_RATE;
		const tpValueForJob =
			client?.default_tp_value ??
			organization?.default_tp_value ??
			DefaultValues.TP_VALUE;
		const tpVariationForJob =
			client?.default_tp_variation ?? DefaultValues.TP_VARIATION;
		const tpTierForJob =
			client?.default_tp_tier ??
			organization?.default_tp_tier ??
			DefaultValues.TP_TIER;

		return {
			tpValueForJob,
			tpTierForJob,
			tpVariationForJob,
			taxRateForJob,
		};
	},
	acceptJobRequest: async (jobId: number) => {
		get().changeJobStatus(
			jobId,
			JobStatusEnum.REQUESTED,
			JobStatusEnum.IN_PROGRESS
		);
		// Set default values for the job
		const {
			tpValueForJob,
			tpTierForJob,
			tpVariationForJob,
			taxRateForJob,
		} = await get().getDefaultValues();

		// TODO: This is also not great, because a lab might update
		// the tp_value/... before accepting the job request and then
		// will be confused that the values were reset to the defaults
		// (especially Krankenkasse Fall might be sth like this)
		// so long term we should already set the correct values
		// when either the job is created or when the lab looks
		// at the job for the first time
		const { data: dataUpdatingJob, error: errorUpdatingJob } =
			await supabase
				.from(SupabaseTableEnum.JOBS)
				.update({
					tp_value: tpValueForJob,
					tp_tier: tpTierForJob,
					tp_variation: tpVariationForJob,
					tax_rate: taxRateForJob,
				})
				.eq("id", jobId)
				.select()
				.single();

		if (errorUpdatingJob) {
			showNotification({
				message: "Fehler beim Aktualisieren des Auftrags",
				type: "error",
			});
		}

		if (dataUpdatingJob) {
			// TODO: Don't be lazy and properly update state
			// TODO: If the job info is open, then there's a bug
			// where the state doesn't immediately update (only when closing and opening again)
			set(
				produce((state) => {
					state.job = {
						...state.job,
						tp_value: tpValueForJob,
						tp_tier: tpTierForJob,
						tp_variation: tpVariationForJob,
						tax_rate: taxRateForJob,
					};
				})
			);
		}

		const { profile } = useCentralStore.getState();
		if (!profile?.id) {
			showNotification({
				message:
					"Fehler beim Erstellen der Benachrichtigung (Kein Profil ausgewählt)",
				type: "error",
			});
			return;
		}
		const { error } = await supabase
			.from(SupabaseTableEnum.MESSAGES)
			.insert({
				job_id: jobId,
				content: {
					type: MessageType.JOB_REQUEST_ACCEPTED,
				},
				created_by: profile?.id,
				recipient_org_id: get().job?.shared_with,
			});

		if (error) {
			showNotification({
				message:
					"Fehler beim Erstellen der Benachrichtigung (Fehler beim Erstellen der Benachrichtigung)",
				type: "error",
			});
			return;
		}
	},
	denyJobRequest: async (jobId: number) => {
		get().changeJobStatus(
			jobId,
			JobStatusEnum.REQUESTED,
			JobStatusEnum.ARCHIVED
		);

		const { profile } = useCentralStore.getState();
		if (!profile?.id) {
			showNotification({
				message: "Fehler beim Erstellen der Benachrichtigung",
				type: "error",
			});
			return;
		}
		const { error } = await supabase
			.from(SupabaseTableEnum.MESSAGES)
			.insert({
				job_id: jobId,
				content: {
					type: MessageType.JOB_REQUEST_DENIED,
				},
				created_by: profile?.id,
				recipient_org_id: get().job?.shared_with,
			});

		if (error) {
			showNotification({
				message: "Fehler beim Erstellen der Benachrichtigung",
				type: "error",
			});
			return;
		}
	},
});
