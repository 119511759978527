import { PatientEntityType } from "@/lib/supabase/supabaseTypes";
import { ClientEntityType } from "@/lib/supabase/supabaseTypes";

export const getPatientLine = (
	patient: PatientEntityType,
	client: ClientEntityType
) => {
	const patientCode = patient?.code ?? "";
	if (client.is_print_pat_code_only) {
		return patientCode;
	}
	const patientName =
		(patient?.title ?? "") +
		" " +
		(patient?.first_name ?? "") +
		" " +
		(patient?.last_name ?? "");
	const birthDate = patient?.birth_date
		? new Date(patient.birth_date).toLocaleDateString("de-DE", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
			})
		: "";

	if (patientName.trim() && patientCode.trim() && birthDate.trim()) {
		return patientCode + ": " + patientName + " (" + birthDate + ")";
	} else if (patientName.trim() && patientCode.trim()) {
		return patientCode + ": " + patientName;
	} else if (patientName.trim() && birthDate.trim()) {
		return patientName + " (" + birthDate + ")";
	} else if (patientCode.trim() && birthDate.trim()) {
		return patientCode + " (" + birthDate + ")";
	} else if (patientName.trim()) {
		return patientName;
	} else if (patientCode.trim()) {
		return patientCode;
	} else if (birthDate.trim()) {
		return birthDate;
	}
};
