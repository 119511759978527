import { useEffect } from "react";
import { useJobStore } from "../../store/Jobs";

const WarningOnUnload = () => {
	const timers = useJobStore((state) => state.timers);
	const pendingChanges = useJobStore((state) => state.pendingChanges);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (Object.entries(timers).length > 0 || pendingChanges) {
				event.preventDefault();

				// Included for legacy support, e.g. Chrome/Edge < 119 according to MDN
				event.returnValue = true;
				return "";
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [timers, pendingChanges]);

	return null;
};

export default WarningOnUnload;
