import {
	Button,
	FormControlLabel,
	Paper,
	Switch,
	Typography,
} from "@mui/material";
import { ProfileForm_USER } from "../forms/form-components/user-profile.form";
import { useCentralStore } from "../store/Central";
import { Check } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { RolesEnum, StorageBucketsEnum } from "../types/enums";
import { showNotification } from "../store/Central/selectors";
import { ImageInput } from "../forms/form-components/client/input-components/image-input";
import { compressImage } from "@/lib/utils/compress-image";
import { v4 as uuidv4 } from "uuid";

export const AccountPage: React.FC = () => {
	const handleSignOut = useCentralStore((state) => state.handleSignOut);
	const { setMFADialog, mfaEnrolled, mfaVerified, role, organization } =
		useCentralStore((state) => ({
			setMFADialog: state.setMFADialog,
			mfaEnrolled: state.mfaEnrolled,
			mfaVerified: state.mfaVerified,
			role: state.role,
			organization: state.organization,
		}));
	const profile = useCentralStore((state) => state.profile);
	if (!mfaVerified) return null;

	const toggleAnalytics = async () => {
		if (!organization?.id) return;

		const { error } = await supabase
			.from(SupabaseTableEnum.ORGANIZATIONS)
			.update({
				is_analytics: !organization?.is_analytics,
			})
			.eq("id", organization?.id);
		if (error) {
			showNotification({
				message:
					"Fehler beim Ändern der Analyse-Einstellungen. Sollte das Problem anhalten, melden Sie sich bitte bei unserem Support.",
				type: "error",
			});
		} else {
			// reload the page so the state is reflected globally and the script tag is updated accordingly
			window.location.reload();
		}
	};

	// TODO: make this a store method and share logic between dentlab and dentconnect
	const handleFileChange = async (file: File | null) => {
		if (!file || !organization?.id || !profile?.id) return;

		const avatarPath = `avatars/${uuidv4()}/avatar`;
		const compressedFile = await compressImage(file, 0.6);

		const { error } = await supabase.storage
			.from(StorageBucketsEnum.PUBLIC_IMAGES)
			.upload(`${organization.id}/${avatarPath}`, compressedFile);

		if (error) {
			showNotification({
				message: "Fehler beim Hochladen des Profilbilds",
				type: "error",
			});
			return;
		}

		// Update profile in database
		const { error: updateError } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.update({
				avatar_url: avatarPath,
			})
			.eq("id", profile?.id);

		if (updateError) {
			showNotification({
				message: "Fehler beim Aktualisieren des Profils",
				type: "error",
			});
			return;
		}

		// Update local state
		useCentralStore.setState((state) => ({
			profile: {
				...state.profile!,
				avatar_url: avatarPath,
			},
		}));

		showNotification({
			message: "Profilbild erfolgreich aktualisiert",
			type: "success",
		});
	};

	return (
		<div style={{ marginTop: 6 }}>
			<div className="flex items-center justify-between pr-8">
				<Typography sx={{ m: "30px" }} variant="h4" gutterBottom>
					Kontoeinstellungen
				</Typography>

				{!mfaEnrolled ? (
					<Button onClick={() => setMFADialog(true)} className="">
						Mehrfaktor-Authentifizierung einrichten
					</Button>
				) : (
					<div className=" flex gap-2 items-center bg-green-100 text-green-700 rounded p-2 ">
						<span>
							<Check size={22} />
						</span>
						<span>Mehrfaktor-Authentifizierung eingerichtet</span>
					</div>
				)}
			</div>
			<Paper sx={{ p: "30px" }}>
				<div
					style={{
						marginBottom: "20px",
						marginTop: "20px",
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<div>
						<ImageInput
							bucket={StorageBucketsEnum.PUBLIC_IMAGES}
							organizationId={organization?.id ?? ""}
							avatarPath={profile?.avatar_url ?? ""}
							onFileChange={handleFileChange}
						/>
					</div>
					{role === RolesEnum.OWNER && (
						<FormControlLabel
							control={
								<Switch
									checked={organization?.is_analytics}
									onChange={toggleAnalytics}
								/>
							}
							sx={{
								fontSize: 10,
							}}
							label="Erlauben Sie das Sammeln anonymisierter Daten zur Verbesserung von dentLAB"
						/>
					)}
				</div>
				<ProfileForm_USER />
				<Button
					sx={{ my: 5 }}
					size="small"
					variant="outlined"
					color="primary"
					onClick={handleSignOut}
				>
					Ausloggen
				</Button>
			</Paper>
		</div>
	);
};
