import {
	CalculateTotalsResult,
	getJobItemsForPdfTable,
} from "@/dentlab/src/lib/utils/calculate";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import {
	JobDocumentEntityType,
	JobItemEntityType,
	OrganizationEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfTableTotalsSection } from "../pdf-table-totals-section.component";
import { PdfTemplateConfiguration } from "../../../context/PdfTemplateConfigurationContext";
import { columnsStateInitializer } from "@mui/x-data-grid/internals";
import { PdfTemplateType } from "../../document-template.types";

export type PdfTableColumns = {
	field: string;
	label: string;
	align: "left" | "right";
	width: number;
};

/**
 *
 * @param showDrTax - If the document is a Lieferschein or Gutschrift job items with ZAZ-Steuer (Dr. Tax) should be marked
 * @returns
 */
export const PdfJobItemsTable: React.FC<{
	jobDocument: JobDocumentEntityType;
	jobItems: JobItemEntityType[];
	organization: OrganizationEntityType;
	showDrTax: boolean;
	jobTotals?: CalculateTotalsResult;
	excludePrices?: boolean;
	pdfTemplateConfiguration: PdfTemplateConfiguration;
	options?: {
		lotNumberColumn?: boolean;
		noteColumn?: boolean;
	};
}> = ({
	jobTotals,
	jobDocument,
	jobItems,
	organization,
	showDrTax,
	excludePrices = false,
	pdfTemplateConfiguration,
	options = {
		lotNumberColumn: false,
		noteColumn: false,
	},
}) => {
	const jobItemsForTable = getJobItemsForPdfTable(jobItems);

	const totalWidth = 100;
	let columns = {
		code: 7,
		quantity: 7,
		description: 25,
		tpValue: 5,
		price: 7,
		discount: 5,
		total: 8,
		lot: 0,
		note: 0,
		tax: 0,
		warranty: 0,
	};
	if (options.lotNumberColumn) {
		columns.lot = 7;
	}
	if (options.noteColumn && pdfTemplateConfiguration[PdfTemplateType.LIEFERSCHEIN].showNotesInTable) {
		columns.note = 25;
	}
	if (showDrTax && jobItems.some((item) => item.is_req_dr_tax)) {
		columns.tax = 2;
	}
	if (jobItems.some((item) => item.is_warranty)) {
		columns.warranty = 5;
	}

	const usedWidth = Object.values(columns).reduce(
		(sum, width) => sum + width,
		0
	);
	const remainingWidth =
		(totalWidth - usedWidth) / (options.noteColumn ? 2 : 1);

	columns.description = columns.description + remainingWidth;
	options.noteColumn ? (columns.note = columns.note + remainingWidth) : null;

	// columns from left to column of total values
	// columns from left to column of total values
	let numberOfColumns = 0;

	// additional columns to the right of the total values
	let numberOfAdditionalColumns = 0;

	const jobItemTableColumns: PdfTableColumns[] = [];
	jobItemTableColumns.push({
		field: "code",
		label: "Code",
		align: "left",
		width: columns.code,
	});
	numberOfColumns++;
	jobItemTableColumns.push({
		field: "quantity",
		label: "Menge",
		align: "left",
		width: columns.quantity,
	});
	numberOfColumns++;
	jobItemTableColumns.push({
		field: "description",
		label: "Text",
		align: "left",
		width: columns.description,
	});
	numberOfColumns++;

	if (options.lotNumberColumn && jobItems.some((item) => item.lot)) {
		jobItemTableColumns.push({
			field: "lot",
			label: "Lot Nr.",
			align: "left",
			width: columns.lot,
		});
		numberOfColumns++;
	}

	if (options.noteColumn && pdfTemplateConfiguration[PdfTemplateType.LIEFERSCHEIN].showNotesInTable && jobItems.some((item) => item.note)) {
		jobItemTableColumns.push({
			field: "note",
			label: "Notiz",
			align: "left",
			width: columns.note,
		});
		numberOfColumns++;
	}

	const firstTpValue = jobItems[0].tp_value;
	const allItemsHaveSameTp = jobItems.every(
		(item) => item.tp_value === firstTpValue
	);
	if (allItemsHaveSameTp === false) {
		jobItemTableColumns.push({
			field: "tp_value",
			label: "TP-Wert",
			align: "left",
			width: columns.tpValue,
		});
		numberOfColumns++;
	}

	if (!excludePrices) {
		jobItemTableColumns.push({
			field: "price",
			label: "Preis/E",
			align: "right",
			width: columns.price,
		});
		numberOfColumns++;
	}

	// If any job item has a discount, add a column for the discounted price
	if (jobItems.some((item) => item.discount) && !excludePrices) {
		jobItemTableColumns.push({
			field: "discount",
			label: "Rabatt",
			align: "right",
			width: columns.discount,
		});
		numberOfColumns++;
	}

	if (!excludePrices) {
		jobItemTableColumns.push({
			field: "total",
			label: "Betrag",
			align: "right",
			width: columns.total,
		});
		numberOfColumns++;
	}

	if (showDrTax && jobItems.some((item) => item.is_req_dr_tax)) {
		jobItemTableColumns.push({
			field: "is_req_dr_tax",
			label: "",
			align: "right",
			width: columns.tax,
		});
		numberOfAdditionalColumns++;
	}

	if (jobItems.some((item) => item.is_warranty)) {
		jobItemTableColumns.push({
			field: "is_warranty",
			label: "",
			align: "right",
			width: columns.warranty,
		});
		numberOfAdditionalColumns++;
	}

	const fontSize = {
		sm: "6pt",
		md: "7pt",
		lg: "9pt",
	};

	return (
		<table
			style={{
				width: "100%",
				borderCollapse: "collapse",
				fontSize:
					fontSize[pdfTemplateConfiguration.general.size ?? "lg"],
			}}
		>
			<tr
				style={{
					backgroundColor: "lightgrey",
					borderBottom: "1px solid black",
				}}
			>
				{jobItemTableColumns?.map((column: PdfTableColumns) => (
					<th
						key={column.field}
						style={{
							textAlign: column.align,
							width: `${column.width}%`,
							verticalAlign: "top",
							padding: "2pt",
						}}
					>
						{column.label}
					</th>
				))}
			</tr>
			{jobItemsForTable.map((row) => (
				<tr key={row.code_e + row.id}>
					{jobItemTableColumns.map((column: PdfTableColumns) => {
						if (column.field === "is_req_dr_tax") {
							return (
								<td
									key={column.field}
									style={{
										textAlign: "center",
										width: `${column.width}%`,
										verticalAlign: "top",
										padding: "2pt",
									}}
								>
									{row[column.field] ? "*" : ""}
								</td>
							);
						}
						if (column.field === "is_warranty") {
							return (
								<td
									key={column.field}
									style={{
										textAlign: column.align,
										width: `${column.width}%`,
										verticalAlign: "top",
										padding: "2pt",
									}}
								>
									{row.is_warranty ? "gratis" : ""}
								</td>
							);
						}
						return (
							<td
								key={column.field}
								style={{
									textAlign: column.align,
									width: `${column.width}%`,
									verticalAlign: "top",
									padding: "2pt",
								}}
							>
								{row.type ===
									JobItemTypeEnum.ARTICLE_DISCOUNTS &&
									(column.field === "price" ||
										column.field === "total") &&
									"-"}
								{(row as any)[column.field]}
							</td>
						);
					})}
				</tr>
			))}
			{jobTotals && (
				<PdfTableTotalsSection
					totals={jobTotals}
					jobDocument={jobDocument}
					organization={organization}
					numberOfColumns={numberOfColumns}
					numberOfAdditionalColumns={numberOfAdditionalColumns}
					pdfTemplateConfiguration={pdfTemplateConfiguration}
				/>
			)}
		</table>
	);
};
