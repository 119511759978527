import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  FormControl,
  FormHelperText,
  Chip,
  Checkbox,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Option {
  value: string | number;
  label: string;
}

interface CustomMultiSelectProps {
  // Data props
  data?: any[];
  options?: Option[];
  textField?: string;
  valueField?: string;
  
  // Core props
  value?: any;
  onChange?: any;
  inputLabel?: string;
  disabled?: boolean;
  required?: boolean;
  
  // Style props
  size?: "small" | "medium";
  width?: string;
  height?: string;
  maxHeight?: string;
  style?: React.CSSProperties;
}

export const CustomMultiSelect: React.FC<CustomMultiSelectProps> = ({
  // Data props
  data = [],
  options = [],
  textField = "name",   // Default to "name" for statistics page compatibility
  valueField = "id",    // Default to "id" for statistics page compatibility
  
  // Core props
  value = [],
  onChange,
  inputLabel = "",
  disabled = false,
  required = false,
  
  // Style props
  size = "small",
  width = "100%",
  height,
  maxHeight,
  style,
}) => {
  // State
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  
  // Get all available options
  const allOptions = options.length > 0 
    ? options 
    : data.map(item => ({
        label: item[textField] || item.label || "",
        value: item[valueField] || item.value || item.id || ""
      }));
  
  const normalizeValue = (val: any): any[] => {
    if (!val) return [];
    if (Array.isArray(val)) return val;
    return val.toString().split(',').filter(Boolean);
  };
  
  useEffect(() => {
    setSelectedValues(normalizeValue(value));
  }, [value]);
  
  const selectedOptions = allOptions.filter(option => 
    selectedValues.some(val => String(val) === String(option.value))
  );
  
  const sortedOptions = [...allOptions].sort((a, b) => {
    const aSelected = selectedValues.some(val => String(val) === String(a.value));
    const bSelected = selectedValues.some(val => String(val) === String(b.value));
    
    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return 0;
  });
  
  const componentStyle = {
    width,
    ...(maxHeight ? { maxHeight } : {}),
    ...(height ? { height } : {}),
    marginTop: 0,
    ...style
  };
  
  const handleChange = (_: React.SyntheticEvent, newValues: Array<string | Option>) => {
    const newValueArray = newValues.map((item: string | Option) => {
      if (typeof item === 'string') {
        const matchingOption = allOptions.find(opt => 
          opt.label.toLowerCase() === item.toLowerCase()
        );
        return matchingOption ? matchingOption.value : item;
      }
      return item.value;
    });
    
    setSelectedValues(newValueArray);
    
    if (onChange) {
      onChange(newValueArray);
    }
  };

  return (
    <div style={componentStyle}>
      <FormControl fullWidth size={size} required={required} style={{ margin: 0 }}>
        <Autocomplete
          multiple
          freeSolo
          options={sortedOptions}
          disableCloseOnSelect
          getOptionLabel={option => {
            if (typeof option === 'string') return option;
            return option.label || '';
          }}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'string' || typeof value === 'string') return false;
            return String(option.value) === String(value.value);
          }}
          value={selectedOptions}
          onChange={handleChange}
          disabled={disabled}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
          filterOptions={(options, { inputValue }) => {
            if (!inputValue) return options;
            const lowerInput = inputValue.toLowerCase();
            return options.filter(option => 
              option.label.toLowerCase().includes(lowerInput)
            );
          }}
          renderOption={(props, option, { selected }) => {
            if (typeof option === 'string') return null;
            return (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <Typography 
                  variant="body2" 
                  style={{ fontWeight: selected ? 'bold' : 'normal' }}
                >
                  {option.label}
                </Typography>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={inputLabel || null}
              placeholder={"Suchen..."}
              required={required}
              size={size}
              margin="none"
              style={{ height: '38px', margin: 0 }}
            />
          )}
          renderTags={() => {
            const count = selectedOptions.length;
            return count > 0 ? [
              <Chip
                key="count"
                size={size}
                label={`${count} ausgewählt`}
              />
            ] : [];
          }}
          openOnFocus
          sx={{
            height: '38px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.37)',
            },
            '& .MuiOutlinedInput-root': {
              height: '38px',
              padding: '6px 8px',
              borderRadius: '4px',
              borderColor: 'rgba(0, 0, 0, 0.37)',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.6)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.6)',
                borderWidth: '1px',
              },
            },
            '& .MuiAutocomplete-inputRoot': {
              height: '38px',
              margin: 0,
              padding: '0 8px',
            },
            '& .MuiFormControl-root': {
              margin: 0,
            },
            '& .MuiInputBase-root': {
              margin: 0,
            },
            margin: 0,
          }}
        />
      </FormControl>
    </div>
  );
};
