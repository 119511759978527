export enum MessageType {
	JOB_REQUEST_V1 = "job_request_v1",
	TEXT = "text",
	SHARED_FILE = "shared_file",
	JOB_REQUEST_DENIED = "job_request_denied",
	JOB_REQUEST_ACCEPTED = "job_request_accepted",
}

export type MessageContent =
	| JobRequestMessageV1
	| TextMessage
	| SharedFileMessage
	| JobRequestDenied
	| JobRequestAccepted;

export type JobRequestMessageV1 = {
	type: MessageType.JOB_REQUEST_V1;
	incoming_order_date: string;
	title: string;
	description: string;
	patient_first_name: string;
	patient_last_name: string;
	patient_code: string;
};

export type TextMessage = {
	type: MessageType.TEXT;
	text: string;
};

export type SharedFileMessage = {
	type: MessageType.SHARED_FILE;
	file_id: string;
	text: string;
};

export type JobRequestDenied = {
	type: MessageType.JOB_REQUEST_DENIED;
};

export type JobRequestAccepted = {
	type: MessageType.JOB_REQUEST_ACCEPTED;
};
