import React from "react";
import { CircularProgress, Paper } from "@mui/material";
import { ActivityElementWrapperWithLabel } from "./activity-element-wrapper-with-label.component";

/**
 * ActivityLinkWrapper - A paper component with styles specifically for link components within the activity component
 */
export const ActivityLinkWrapper: React.FC<{
	children: React.ReactNode;
	onClick: () => void;
	isLoading: boolean;
	label: string;
}> = ({ children, onClick, isLoading, label }) => {
	return (
		<ActivityElementWrapperWithLabel label={label}>
			<Paper
				sx={{
					width: "100%",
					padding: "10px",
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					// and hover
					"&:hover": {
						backgroundColor: "#f5f5f5",
					},
				}}
				className="activity-job-link"
				variant="outlined"
				onClick={(e) => {
					e.stopPropagation();
					onClick();
				}}
			>
				{isLoading ? <CircularProgress size="20px" /> : children}
			</Paper>
		</ActivityElementWrapperWithLabel>
	);
};
