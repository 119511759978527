import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Typography from "@mui/material/Typography";

// Template from https://mui.com/material-ui/react-autocomplete/

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: (style.top as number) + LISTBOX_PADDING,
	};

	if (Object.prototype.hasOwnProperty.call(dataSet, "group")) {
		return (
			<ListSubheader
				key={dataSet.key}
				component="div"
				style={inlineStyle}
			>
				{dataSet.group}
			</ListSubheader>
		);
	}

	return (
		<Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
			{`${dataSet[1]}`}
		</Typography>
	);
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
	const ref = React.useRef<VariableSizeList>(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData: React.ReactNode[] = [];
	(children as React.ReactChild[]).forEach(
		(item: React.ReactChild & { children?: React.ReactChild[] }) => {
			itemData.push(item);
			itemData.push(...(item.children || []));
		}
	);

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
		noSsr: true,
	});
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child: React.ReactNode) => {
		if (Object.prototype.hasOwnProperty.call(child, "group")) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				{/* @ts-expect-error react-window typing issue */}
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					// @ts-expect-error react-window typing issue
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: "border-box",
		"& ul": {
			padding: 0,
			margin: 0,
		},
	},
});

interface VirtualizedAutocompleteProps {
	options: string[];
	onChange: (value: string) => void;
	label: string;
	value: string;
	required?: boolean;
}

export const VirtualizedAutocomplete: React.FC<
	VirtualizedAutocompleteProps
> = ({ options, onChange, label, value, required }) => {
	const sortedOptions = options.sort((a, b) => {
		const groupA = a.split(" ")[1].toUpperCase();
		const groupB = b.split(" ")[1].toUpperCase();
		if (groupA < groupB) {
			return -1;
		}
		if (groupA > groupB) {
			return 1;
		}
		return 0;
	});

	return (
		<Autocomplete
			sx={{ width: "100%" }}
			disableListWrap
			PopperComponent={StyledPopper}
			ListboxComponent={ListboxComponent}
			options={sortedOptions}
			groupBy={(option: string) => option.split(" ")[1].toUpperCase()}
			renderInput={(params) => (
				<TextField {...params} label={label} required={required} />
			)}
			renderOption={(props, option, state) =>
				[props, option, state.index] as React.ReactNode
			}
			// TODO: Post React 18 update - validate this conversion, look like a hidden bug
			renderGroup={(params) => params as unknown as React.ReactNode}
			onChange={(_event, newValue: string | null) =>
				onChange(newValue as string)
			}
			value={value || ""}
			size="small"
			data-testid="virtualized-autocomplete"
		/>
	);
};
