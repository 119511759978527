import { Box, Button, TextField, Typography } from "@mui/material";
import { useForm } from "../../hooks/useForm/useForm";
import { FormProps } from "../form-types";
import { Database } from "../../../../lib/supabase";
import { TitleAndNameGroup } from "../form-input-components/groups/title-and-name.group";
import { useSessionContext } from "../../../../lib/contexts/SessionContext";
import { useProfileContext } from "../../../../lib/contexts/ProfileContext";
import { CustomTextField } from "../form-input-components/custom-input-components/custom-text-field.component";
import {
	ProfileEntityType,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";

/**
 * ProfileForm_USER - A form to update a user's profile from the user profile page.
 * @param param0
 * @returns
 */
export const ProfileForm_USER: React.FC<FormProps> = ({
	onSave = (profile: ProfileEntityType) => {},
	submitRef = null,
}) => {
	const supabaseTable = SupabaseTableEnum.PROFILES;

	const {
		session: { userId, userEmail },
	}: any = useSessionContext();
	const { setProfile }: any = useProfileContext;

	// TODO: initial form data could also be retrieed from AppContext (but then the AppContext would have to be extended by all necessary fields)
	const {
		handleInputChange,
		handleUpdate,
		formData,
		formErrors,
		handleValueChange,
	} = useForm<Database["public"]["Tables"]["profiles"]["Update"]>(
		{ modified_by_user_at: new Date() },
		supabaseTable,
		true,
		userId,
		"Profil"
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const { success, error, data } = await handleUpdate(event, userId);
		if (success && data) {
			setProfile(formData);
			const profile = data[0];
			onSave(profile);
		}
	};

	const sxTextField = { mb: "20px" };

	return (
		<>
			<form onSubmit={onSubmit} className="pt-4">
				<button
					ref={submitRef}
					type="submit"
					style={{ display: "none" }}
				/>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Typography className="pb-8">Email: {userEmail}</Typography>
					{/* TODO: profile photo */}
					<TitleAndNameGroup
						formData={formData}
						formErrors={formErrors}
						onChange={handleInputChange}
						onValueChange={(args: any) =>
							handleValueChange(args.name, args.value)
						}
					/>
					<TextField
						onChange={handleInputChange}
						value={formData.display_name || ""}
						label="Benutzername"
						name="display_name"
						sx={sxTextField}
						size="small"
					/>
					<TextField
						onChange={handleInputChange}
						value={formData.additional_information || ""}
						name="additional_information"
						label="Zusatz"
						sx={sxTextField}
						size="small"
					/>
					<TextField
						onChange={handleInputChange}
						value={formData.street || ""}
						name="street"
						label="Straße"
						sx={sxTextField}
						size="small"
					/>
					<TextField
						onChange={handleInputChange}
						value={formData.city || ""}
						name="city"
						label="Stadt"
						sx={sxTextField}
						size="small"
					/>
					<CustomTextField
						onChange={handleInputChange}
						value={formData.phone_personal || ""}
						name="phone_personal"
						label="Telefon (privat)"
						sx={sxTextField}
						size="small"
						validationError={formErrors.phone_personal}
					/>
					<CustomTextField
						onChange={handleInputChange}
						value={formData.phone_mobile || ""}
						name="phone_mobile"
						label="Telefon (mobil)"
						sx={sxTextField}
						size="small"
						validationError={formErrors.phone_mobile}
					/>
					{/* TODO: Geburtstag */}
				</div>
				<Button type="submit" variant="contained">
					Speichern
				</Button>
			</form>
		</>
	);
};
