import { useEffect, useRef } from "react";
import { Message } from "../chat/message";
import { RichTextEditor } from "@mantine/rte";
import { Button, IconButton } from "@mui/material";
import { useRealtimeStore } from "@/lib/store/Realtime";
import {
	ChannelType,
	getChannelKeyFromChannelId,
} from "@/lib/store/Realtime/message.store";
import { ChatWindow } from "../chat/chat";
import { ProfilesForChat } from "../../store/Central/types";
import { SquareArrowOutUpRight } from "lucide-react";

export const JobChannel: React.FC<{
	organizationId: string | null;
	jobId: number | null;
	recipientOrgId: string | null;
	profileLookup: Record<string, ProfilesForChat>;
	goTo?: () => void;
}> = ({ organizationId, jobId, recipientOrgId, profileLookup, goTo }) => {
	const {
		messages,
		sendTextMessage,
		getReplyText,
		setReplyText,
		changeChannel,
	} = useRealtimeStore((state) => ({
		messages: state.messages,
		sendTextMessage: state.sendTextMessage,
		getReplyText: state.getReplyText,
		setReplyText: state.setReplyText,
		changeChannel: state.changeChannel,
	}));

	useEffect(() => {
		if (jobId && recipientOrgId) {
			changeChannel({ type: ChannelType.JOB, id: jobId }, recipientOrgId);
		}
	}, [jobId, recipientOrgId]);

	const messagesContainerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (messagesContainerRef.current) {
			messagesContainerRef.current.scrollTop =
				messagesContainerRef.current.scrollHeight;
		}
	}, [messages]);

	const handleSend = async () => {
		sendTextMessage(getReplyText());
	};

	if (!jobId) {
		return null;
	}
	return (
		<ChatWindow>
			{goTo && (
				<div className="flex justify-end py-4 pr-2">
					<IconButton onClick={goTo}>
						<SquareArrowOutUpRight />
					</IconButton>
				</div>
			)}
			<div
				className="flex flex-col gap-4 overflow-y-auto py-4 h-full scroll-smooth"
				ref={messagesContainerRef}
			>
				{messages[
					getChannelKeyFromChannelId({
						type: ChannelType.JOB,
						id: jobId,
					})
				]?.map((message) => {
					let userName = "";
					if (message.created_by) {
						userName =
							profileLookup[message.created_by]?.first_name +
							" " +
							profileLookup[message.created_by]?.last_name;
					}
					return (
						<Message
							key={message.id}
							message={message}
							messageOfCurrentUser={
								message.sender_org_id === organizationId
							}
							userName={userName}
							messageAt={message.created_at ?? ""}
							user={profileLookup[message.created_by ?? ""]}
						/>
					);
				})}
			</div>
			<div className="shadow-md rounded-md relative gap-4 border border-gray-100">
				<div className="w-full mb-6">
					<RichTextEditor
						styles={{
							root: {
								border: "none",
							},
						}}
						controls={[["italic", "underline", "bold"]]}
						value={getReplyText()}
						onChange={(e) => setReplyText(e)}
						onKeyDown={(e) => {
							if (
								e.key.toLocaleLowerCase() === "enter" &&
								(e.metaKey || e.ctrlKey)
							) {
								handleSend();
							}
						}}
					/>
				</div>
				<div className="absolute bottom-0 right-0 mr-3 mb-2">
					<Button onClick={handleSend}>Senden</Button>
				</div>
			</div>
		</ChatWindow>
	);
};
