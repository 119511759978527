import { nameTitleOptions } from "@/dentlab/src/lib/constants/nameTitle";
import { SelectProps } from "../../form-types";
import { CustomAutocomplete } from "../../../components/custom-autocomplete.component";

export const TitleSelect: React.FC<SelectProps> = (props) => {
	return (
		<CustomAutocomplete
			options={nameTitleOptions}
			inputLabel="Anrede"
			{...props}
		/>
	);
};
