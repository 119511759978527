import { supabase } from "@/lib/supabase";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { create } from "zustand";
import { useCentralStore } from "../Central";
import { Logger } from "@/lib/logger/Logger";
import { Tables } from "@/lib/supabase/supabaseTypesImport";
import { showNotification } from "../Central/selectors";
import { produce } from "immer";

export type Template = Omit<Tables<"templates">, "organization_id" | "blo_id">;
export type TemplateItem = Omit<
	Tables<"template_items">,
	"template_id" | "modified_at" | "created_at" | "bpo_id" | "row_number"
>;

export interface TemplateWithItems extends Template {
	template_items: TemplateItem[];
}

const COLUMNS_FOR_TEMPLATE = "id, code, name, created_at, modified_at";
const COLUMNS_FOR_TEMPLATE_ITEM = "id, code_e, quantity";
const COLUMNS_FOR_TEMPLATE_WITH_ITEMS = `${COLUMNS_FOR_TEMPLATE}, template_items(${COLUMNS_FOR_TEMPLATE_ITEM})`;

interface TemplatesState {
	templatesLookup: Record<number, TemplateWithItems>;
	fetchTemplates: () => Promise<void>;
	createTemplate: (
		code: string,
		name: string,
		items: { code_e: string; quantity: number }[]
	) => Promise<TemplateWithItems | undefined>;
	deleteTemplate: (id: number) => Promise<void>;
	updateTemplateName: (id: number, name: string) => Promise<void>;
	updateTemplateCode: (id: number, code: string) => Promise<void>;
	updateTemplateItemQuantity: (
		templateId: number,
		itemId: number,
		quantity: number
	) => Promise<void>;
	addTemplateItem: (
		templateId: number,
		item: { code_e: string; quantity: number }
	) => Promise<void>;
	deleteTemplateItem: (templateId: number, itemId: number) => Promise<void>;
}

export const useTemplatesStore = create<TemplatesState>()((set) => ({
	templatesLookup: {},
	fetchTemplates: async () => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) {
			Logger.error("No organization id found", {});
			return;
		}
		const { data, error } = await supabase
			.from(SupabaseTableEnum.TEMPLATES)
			.select(COLUMNS_FOR_TEMPLATE_WITH_ITEMS)
			.eq("organization_id", organizationId);

		if (error) {
			Logger.error(error, {}, "Error fetching templates");
			return;
		}
		set({
			templatesLookup: data.reduce(
				(acc, template) => {
					acc[template.id] = {
						...template,
						template_items: Array.isArray(template.template_items)
							? template.template_items
							: [],
					};
					return acc;
				},
				{} as Record<number, TemplateWithItems>
			),
		});
	},
	createTemplate: async (
		code: string,
		name: string,
		items: { code_e: string; quantity: number }[]
	) => {
		// We don't need to pass organization_id as it's a default on the templates table
		const { data: newTemplate, error } = await supabase
			.from(SupabaseTableEnum.TEMPLATES)
			.insert({
				code,
				name,
			})
			.select(COLUMNS_FOR_TEMPLATE)
			.single();

		if (error) {
			Logger.error(error, {}, "Error creating template");
			showNotification({
				message: "Fehler beim Erstellen der Vorlage",
				type: "error",
			});
			return;
		}

		const { data: templateItems, error: templateItemsError } =
			await supabase
				.from(SupabaseTableEnum.TEMPLATE_ITEMS)
				.insert(
					items.map((item) => ({
						template_id: newTemplate.id,
						code_e: item.code_e,
						quantity: item.quantity,
					}))
				)
				.select(COLUMNS_FOR_TEMPLATE_ITEM);
		if (templateItemsError) {
			Logger.error(
				templateItemsError,
				{},
				"Error creating template items"
			);
			showNotification({
				message: "Fehler beim Erstellen der Vorlage",
				type: "error",
			});
			return;
		}

		const newTemplateWithItems: TemplateWithItems = {
			...newTemplate,
			template_items: templateItems,
		};
		set(
			produce((state) => {
				state.templatesLookup[newTemplateWithItems.id] =
					newTemplateWithItems;
			})
		);

		return newTemplateWithItems;
	},
	deleteTemplate: async (id: number) => {
		const { error } = await supabase
			.from(SupabaseTableEnum.TEMPLATES)
			.delete()
			.eq("id", id);

		if (error) {
			Logger.error(error, {}, "Error deleting template");
			showNotification({
				message: "Fehler beim Löschen der Vorlage",
				type: "error",
			});
			return;
		}
		set(
			produce((state) => {
				delete state.templatesLookup[id];
			})
		);
		showNotification({
			message: "Vorlage erfolgreich gelöscht",
			type: "success",
		});
	},
	updateTemplateName: async (id: number, name: string) => {
		const { error } = await supabase
			.from(SupabaseTableEnum.TEMPLATES)
			.update({ name })
			.eq("id", id);

		if (error) {
			Logger.error(error, {}, "Error updating template name");
			showNotification({
				message: "Fehler beim Aktualisieren der Vorlage",
				type: "error",
			});
			return;
		}

		set(
			produce((state) => {
				state.templatesLookup[id].name = name;
			})
		);
		showNotification({
			message: "Vorlage erfolgreich aktualisiert",
			type: "success",
		});
	},
	updateTemplateCode: async (id: number, code: string) => {
		const { error } = await supabase
			.from(SupabaseTableEnum.TEMPLATES)
			.update({ code })
			.eq("id", id);

		if (error) {
			Logger.error(error, {}, "Error updating template code");
			showNotification({
				message: "Fehler beim Aktualisieren der Vorlage",
				type: "error",
			});
			return;
		}

		set(
			produce((state) => {
				state.templatesLookup[id].code = code;
			})
		);
		showNotification({
			message: "Vorlage erfolgreich aktualisiert",
			type: "success",
		});
	},
	updateTemplateItemQuantity: async (
		templateId: number,
		itemId: number,
		quantity: number
	) => {
		const { error } = await supabase
			.from(SupabaseTableEnum.TEMPLATE_ITEMS)
			.update({ quantity })
			.eq("id", itemId);

		if (error) {
			Logger.error(error, {}, "Error updating template item");
			showNotification({
				message: "Fehler beim Aktualisieren der Vorlage",
				type: "error",
			});
			return;
		}

		set(
			produce((state) => {
				state.templatesLookup[templateId].template_items.find(
					(item: any) => item.id === itemId
				).quantity = quantity;
			})
		);
		showNotification({
			message: "Vorlage erfolgreich aktualisiert",
			type: "success",
		});
	},
	deleteTemplateItem: async (templateId: number, itemId: number) => {
		const { error } = await supabase
			.from(SupabaseTableEnum.TEMPLATE_ITEMS)
			.delete()
			.eq("id", itemId);

		if (error) {
			Logger.error(error, {}, "Error deleting template item");
			showNotification({
				message: "Fehler beim Löschen des Vorlagenelements",
				type: "error",
			});
			return;
		}

		set(
			produce((state) => {
				state.templatesLookup[templateId].template_items =
					state.templatesLookup[templateId].template_items.filter(
						(item: any) => item.id !== itemId
					);
			})
		);
		showNotification({
			message: "Vorlagenelement erfolgreich gelöscht",
			type: "success",
		});
	},
	addTemplateItem: async (
		templateId: number,
		item: { code_e: string; quantity: number }
	) => {
		// check wether the tariff or article exists in job_items lookup
		const { tariffs, articles } = useCentralStore.getState();
		const exists = tariffs[item.code_e] || articles[item.code_e];
		if (!exists) {
			showNotification({
				message: "Code nicht gefunden",
				type: "error",
			});
			return;
		}

		const existingItem = useTemplatesStore
			.getState()
			.templatesLookup[
				templateId
			].template_items.find((existing: TemplateItem) => existing.code_e === item.code_e);

		if (existingItem) {
			set(
				produce((state) => {
					const itemToUpdate = state.templatesLookup[
						templateId
					].template_items.find(
						(item: TemplateItem) => item.id === existingItem.id
					);
					if (itemToUpdate) {
						itemToUpdate.quantity += item.quantity;
					}
					return;
				})
			);
		}

		if (existingItem) {
			const { error } = await supabase
				.from(SupabaseTableEnum.TEMPLATE_ITEMS)
				.update({ quantity: existingItem.quantity + item.quantity })
				.eq("id", existingItem.id);
			if (error) {
				Logger.error(
					error,
					{},
					"Error updating template item quantity"
				);
				showNotification({
					message: "Fehler beim Aktualisieren des Vorlagenelements",
					type: "error",
				});
				useTemplatesStore.getState().fetchTemplates();
				return;
			}
			showNotification({
				message: "Position erfolgreich aktualisiert",
				type: "success",
			});
			return;
		}

		const { data: newItem, error } = await supabase
			.from(SupabaseTableEnum.TEMPLATE_ITEMS)
			.insert({
				template_id: templateId,
				code_e: item.code_e,
				quantity: item.quantity,
			})
			.select(COLUMNS_FOR_TEMPLATE_ITEM)
			.single();

		if (error) {
			Logger.error(error, {}, "Error adding template item");
			showNotification({
				message: "Fehler beim Hinzufügen des Vorlagenelements",
				type: "error",
			});
			return;
		}

		set(
			produce((state) => {
				state.templatesLookup[templateId].template_items.push(newItem);
			})
		);

		showNotification({
			message: "Vorlagenelement erfolgreich hinzugefügt",
			type: "success",
		});

		useTemplatesStore.getState().fetchTemplates();
	},
}));
