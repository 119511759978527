import { ExtendedArticleEntityType, ExtendedTariffEntityType } from "./types";
import { JobStatusEnum } from "@/lib/types/job";
import { JobItemEntityTypeInsert } from "@/lib/supabase/supabaseTypes";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { JobStoreUnion, useJobStore } from ".";
import { v4 as uuidv4 } from "uuid";
import { showNotification } from "../Central/selectors";
import { TpTier, TpValue } from "../../types/enums";
import { Logger } from "@/lib/logger/Logger";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";

const tpTierName = {
	0: "tp_sv",
	1: "tp_pp1",
	2: "tp_pp2",
	3: "tp_pp3",
};

export const formatTariff = (
	item: ExtendedTariffEntityType,
	jobDocumentId: number,
	clientId: string,
	guarantorId: string | null,
	jobTpTier: TpTier,
	jobTpValue: TpValue,
	jobTpVariation: number
): JobItemEntityTypeInsert | null => {
	Logger.info(`[formatTariff] item: ${JSON.stringify(item)}`);
	const {
		id,
		code,
		code_e,
		description_de,
		description_fr,
		description_it,
		is_new,
		own_tp,
		is_own_tp,
		composition,
		standard_quantity,
		tarifwerk,
		tp_pp1,
		tp_pp2,
		tp_pp3,
		tp_sv,
		custom,
		description_other,
		organization_id,
		tariff_default_id,
		tar_id,
		...filteredItem
	} = item;

	// @ts-expect-error - This is a workaround to avoid having to change the type of the item object
	let tp = item[tpTierName[jobTpTier]] ?? item[tpTierName[TpTier.PP2]];
	const taxpunktwert = is_own_tp ? own_tp : jobTpValue;
	if (taxpunktwert === null) {
		showNotification({
			message: "Taxpunktwert ist null",
			type: "error",
		});
		return null;
	}

	// Warn the user if is_own_tp is true but own_tp is 0
	if (taxpunktwert === 0) {
		showNotification({
			message: `${is_own_tp ? "Eigener " : ""}Taxpunktwert ist 0`,
			type: "warning",
		});
	}

	// If the job's tp_variation != 0, we need to adjust the taxpunkte with the variation
	// ...and round to the nearest 0.05
	if (jobTpVariation && jobTpVariation !== 0 && jobTpVariation !== null) {
		tp = tp + (tp * jobTpVariation) / 100;
		tp = Math.round(tp * 20) / 20;
	}

	Logger.info(`[formatTariff] tp: ${tp}`);

	const newRow: JobItemEntityTypeInsert = {
		...filteredItem,
		id: uuidv4(),
		created_at: undefined,
		modified_at: new Date().toISOString(),
		price: tp,
		quantity: standard_quantity ?? 1,
		description: description_de, // TODO: localize
		code, // for display on documents
		code_e, // for display in the jobs table
		tp_value: taxpunktwert,
		job_document_id: jobDocumentId,
		tariff_id: item?.custom ? item?.id : null, // If the tariff is custom, we use the id from the custom tariffs table
		type: JobItemTypeEnum.TARIFF,
		// TS was complaining about types needed explicit assertion
		tax_rate: (useJobStore.getState().job?.tax_rate as number) ?? 8.1,
	};

	return newRow;
};

export const formatArticle = (
	item: ExtendedArticleEntityType,
	jobDocumentId: number
): JobItemEntityTypeInsert => {
	Logger.info(`[formatArticle] item: ${JSON.stringify(item)}`);

	// Filter out properties that are not needed in the job item
	const {
		id,
		code_e, // for articles we only have code_e because there's no need for an official code
		description_de,
		description_fr,
		description_it,
		is_new,
		standard_quantity,
		article_default_id,
		cluster,
		custom,
		description_other,
		manufacturer,
		margin,
		modified_stock_quantity_at,
		organization_id,
		purchase_price,
		purchase_quantity,
		stock_quantity,
		unit,
		art_id,
		...filteredItem
	} = item;

	const newRow: JobItemEntityTypeInsert = {
		...filteredItem,
		id: uuidv4(),
		created_at: undefined,
		modified_at: new Date().toISOString(),
		quantity: standard_quantity ?? 1,
		description: description_de, // TODO: localize
		code: code_e,
		code_e: code_e,
		job_document_id: jobDocumentId,
		price: item.price ?? 0,
		article_id: item.custom ? item.id : null, // If the article is custom, we use the id from the custom articles table
		tax_rate: (useJobStore.getState().job?.tax_rate as number) ?? 8.1,
		tp_value: 0, // Articles do not have a TP value
	};

	return newRow;
};

export const adjustJobStatus = (
	state: JobStoreUnion,
	jobId: number,
	targetStatus: JobStatusEnum,
	currentStatus: JobStatusEnum
) => {
	const jobIndex = state.jobList[currentStatus].jobs.findIndex(
		(j) => j.id === jobId
	);

	// If the job is not in the job list, the state is not updated
	if (jobIndex === -1) return;

	const job = state.jobList[currentStatus].jobs[jobIndex];

	// If the job to be updated is the current job, update it as well
	if (state.job?.id === jobId) {
		state.job.status = targetStatus;
	}

	// Remove the job from the list of its current status
	state.jobList[currentStatus].jobCount -= 1;
	state.jobList[currentStatus].jobs.splice(jobIndex, 1);

	// Add the job to the list of its new status
	state.jobList[targetStatus].jobCount += 1;
	state.jobList[targetStatus].jobs.unshift(job);
};

export const jobDocumentTypeNamePresets = (type: JobDocumentTypeEnum) => {
	let name = "Neues Dokument";
	if (type === JobDocumentTypeEnum.DeliveryNote) {
		name = "Lieferschein";
	} else if (type === JobDocumentTypeEnum.Quotation) {
		name = "Kostenvoranschlag";
	} else if (type === JobDocumentTypeEnum.CreditNote) {
		name = "Gutschrift";
	} else if (type === JobDocumentTypeEnum.MATERIALS) {
		name = "Materialien";
	}
	return name;
};

export const isUpdateJobCodeAllowed = (jobStatus: JobStatusEnum) => {
	if (
		jobStatus === JobStatusEnum.COMPLETED ||
		jobStatus === JobStatusEnum.BOOKED_MONTHLY ||
		jobStatus === JobStatusEnum.BOOKED_SINGLE
	) {
		return {
			isAllowed: false,
			explanation: "Der Auftrag ist abgeschlossen oder gebucht",
		};
	}

	return {
		isAllowed: true,
		needsConfirmation: false,
		explanation: "Der Auftrag ist nicht abgeschlossen oder gebucht",
	};
};
