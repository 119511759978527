// Always take the lowest status between LS and GS
// IN_PROGRESS -> COMPLETED -> BOOKED -> PAID

/**
 * NOT_STARTED = 0
 * IN_PROGRESS = 1 // "Pendent"
 * COMPLETED = 2 // "Geliefert"
 * ARCHIVED = 3 // "Archiviert"
 * PAID_SINGLE = 4 // "Verbucht als Einzelrechnung"
 * PAID_MONTHLY = 5 // "Verbucht als Sammelrechnung"
 * REQUESTED = 10 // "Auftragseingang"
 * BOOKED_SINGLE = 21 // "Verbucht als Einzelrechnung"
 * BOOKED_MONTHLY = 22 // "Verbucht als Sammelrechnung"
 */
export enum JobStatusEnum {
	// discontinued: NOT_STARTED = 0,
	IN_PROGRESS = 1,
	COMPLETED = 2,
	ARCHIVED = 3,
	PAID_SINGLE = 4,
	PAID_MONTHLY = 5,
	REQUESTED = 10,
	BOOKED_SINGLE = 21,
	BOOKED_MONTHLY = 22,
}

export const jobStatusToLabel = {
	[JobStatusEnum.REQUESTED]: "Auftragseingang",
	[JobStatusEnum.IN_PROGRESS]: "Pendent",
	[JobStatusEnum.COMPLETED]: "Geliefert",
	[JobStatusEnum.ARCHIVED]: "Archiviert",
	[JobStatusEnum.BOOKED_SINGLE]: "Verbucht als Einzelrechnung",
	[JobStatusEnum.BOOKED_MONTHLY]: "Verbucht in Sammelrechnung",
	[JobStatusEnum.PAID_SINGLE]: "Gezahlt per Einzelrechnung",
	[JobStatusEnum.PAID_MONTHLY]: "Gezahlt per Sammelrechnung",
};
