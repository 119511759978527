import {
	DatePicker,
	DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import "./custom-date-picker.css";
import { Translate } from "../translate/translate.component";
import { useEffect, useState } from "react";

/**
 * Custom date picker component
 * Converts the date to UTC and removes the time part (only keep the date) to prevent off-by-one errors
 * - takes any date value to initialize the date state
 * - passes the date in the format yyyy-mm-dd to the onChange function
 *
 * Note: yyyy-mm-dd is the format used by the PostgreSQL "date" type
 */
export const CustomDatePicker: React.FC<{
	initialValue: Date | string | undefined;
	onChange: (value: string | null) => void;
	size?: "small" | "medium" | "large";
}> = ({ initialValue, onChange, size }) => {
	const [date, setDate] = useState<Date | null>(null);

	// Initialize the date state if a value is provided
	// and the date state is not set yet
	useEffect(() => {
		if (initialValue && date === null) {
			setDate(new Date(initialValue));
		}
	}, [initialValue]);

	const handleChange = (event: DatePickerChangeEvent) => {
		const date = event.value;

		// We need to set the date state separately with the kendo event
		// to prevent weird behavior of the kendo component
		setDate(date);

		if (date) {
			// Create a new UTC date using only year, month, and day
			// This removes any time component and ensures consistent date storage
			const utcDate = new Date(
				Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
			);

			// Convert to ISO string and take only the date part (YYYY-MM-DD)
			// by splitting at 'T' and taking the first part
			onChange(utcDate.toISOString().split("T")[0]);
		} else {
			// If no date is selected, pass null
			onChange(null);
		}
	};

	return (
		<Translate>
			<DatePicker
				value={date}
				onChange={handleChange}
				size={size ?? "small"}
				fillMode="outline"
				// German format
				format="dd.MM.yyyy"
				formatPlaceholder={{ year: "JJJJ", month: "MM", day: "TT" }}
			/>
		</Translate>
	);
};
