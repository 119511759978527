import { supabase } from "@/lib/supabase";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import { State } from "./types";
import { StorageBucketsEnum } from "../../types/enums";
import { useCentralStore } from ".";

export const initialState: State = {
	mfaVerified: false,
	mfaAuthenticated: false,
	mfaEnrolled: false,
	role: null,
	mfaDialogOpen: false,
	connectRelationshipId: null,
	initialized: false,
	clientId: null,
	organization: null,
	userId: null,
	userEmail: null,
	isConnectUser: false,
	clientsLookup: {},
	client: null,
	tariffs: {},
	articles: {},
	profile: null,
	guarantorLookup: {},
	profilesForChat: {},
	searchConfig: null,
	printersConfig: {
		default: null,
		[PdfTemplateType.AKONTORECHNUNG]: null,
		[PdfTemplateType.AUFTRAGSBLATT]: null,
		[PdfTemplateType.BARZAHLUNGSQUITTUNG]: null,
		[PdfTemplateType.ERSTE_MAHNUNG]: null,
		[PdfTemplateType.FOLGEMAHNUNG]: null,
		[PdfTemplateType.GUTSCHRIFT]: null,
		[PdfTemplateType.KARTENZAHLUNGSQUITTUNG]: null,
		[PdfTemplateType.KOSTENVORANSCHLAG]: null,
		[PdfTemplateType.LIEFERSCHEIN]: null,
		[PdfTemplateType.RECHNUNG]: null,
		[PdfTemplateType.RECHNUNGSDOPPEL]: null,
		[PdfTemplateType.SAMMELRECHNUNG]: null,
		[PdfTemplateType.ZAHLUNGSERRINERUNG]: null,
		etik_auftrag: null,
		etik_modell: null,
		etik_versand: null,
	},
	clientLoading: true,
	clientsLoading: true,
	bankAccountsLookup: {},

	debugMode: false,
	connectedProfiles: {},
	profilesPerConnectRelationship: {},

	clientOrganizationId: null,
};

export const fetchClientAvatar = async (avatar_path: string) => {
	const organizationId = useCentralStore.getState().organization?.id;
	const image_src = (
		await supabase.storage
			.from(StorageBucketsEnum.ORG_V1)
			.createSignedUrl(`${organizationId}/${avatar_path}`, 3600, {
				transform: {
					quality: 35,
				},
			})
	).data?.signedUrl as string;

	return image_src;
};

// usage of TEMPLATE_CODE for jumbos is deprecated!
// This code is also used in the db rpcs
export const TEMPLATE_CODE = "template-8ed86c65-28a3-4627-a4b0-a68ad61f5876";
