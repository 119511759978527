import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { ProfilesForChat } from "@/dentlab/src/store/Central/types";
import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import {
	ConnectedProfilesForMed,
	OrganizationEntityType,
	ProfileEntityType,
	SupabaseShareEntityViewEnum,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { getAvatarUrl } from "@/lib/utils/get-avatar-url";
export type Lab = {
	id: string;
	name: string;
	connectRelationshipId: string;
	labOrganizationId: string;
};

export interface CentralSlice {
	labsLookup: Record<string, Lab>;
	userId: string | null;
	userEmail: string | null;
	clientId: string | null;
	profile: ProfileEntityType | null;
	organization: OrganizationEntityType | null;
	initialized: boolean;
	connectedProfiles: Record<string, ConnectedProfilesForMed>;
	profilesForChat: Record<string, ProfilesForChat>;
	setClientId: (clientId: string) => void;
	initialize: () => Promise<void>;
	getSession: () => Promise<string | null>;
	getProfile: () => Promise<void>;
	getOrganization: (organizationId: string) => Promise<void>;
	handleSignOut: () => Promise<void>;
	getLabs: () => Promise<void>;
	getConnectedProfiles: () => Promise<void>;
	getProfilesForChat: (organizationId: string) => Promise<void>;
}

export const createCentralStore: StateCreator<CentralSlice> = (set, get) => ({
	userId: null,
	userEmail: null,
	profile: null,
	organization: null,
	initialized: false,
	clientId: null,
	labsLookup: {},
	connectedProfiles: {},
	profilesForChat: {},
	setClientId: (clientId: string) =>
		set({
			clientId,
		}),
	initialize: async () => {
		const {
			getSession,
			getProfile,
			getOrganization,
			getLabs,
			getConnectedProfiles,
			getProfilesForChat,
		} = get();

		if (get().initialized) {
			Logger.warn("Central store already initialized");
			return;
		}

		const organizationId = await getSession();
		if (!organizationId) return;

		await getOrganization(organizationId);
		await getLabs();
		await getConnectedProfiles();
		await getProfilesForChat(organizationId);
		await getProfile();

		set({
			initialized: true,
		});
	},
	getSession: async () => {
		const { data } = await supabase.auth.getSession();

		set({
			userId: data.session?.user.id,
			userEmail: data.session?.user.email ?? "",
		});

		return data.session?.user.app_metadata.organization_id;
	},
	getProfile: async () => {
		const { userId } = get();
		if (!userId) {
			Logger.error("User ID is not set");
			return;
		}
		const { data, error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.select()
			.eq("id", userId)
			.single();

		if (error) {
			Logger.error(error);
			return;
		}

		const profile = data;
		set({ profile });
	},
	getOrganization: async (organizationId: string) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.ORGANIZATIONS)
			.select()
			.eq("id", organizationId)
			.single();

		if (error) {
			Logger.error(error);
			return;
		}
		if (data) {
			set({
				organization: data,
			});
		}
	},
	handleSignOut: async () => {
		try {
			const { error } = await supabase.auth.signOut();
			if (error) {
				showNotification({
					message:
						"Beim Abmelden ist ein Fehler aufgetreten, bitte versuche es erneut",
					type: "error",
				});
				Logger.error(error);
				return;
			}
			set({
				userId: null,
				userEmail: null,
				organization: null,
				profile: null,
			});

			window.location.href = "/";
		} catch (error) {
			Logger.error(error, {}, "Error signing out");
			showNotification({
				message:
					"Beim Abmelden ist ein Fehler aufgetreten, bitte versuche es erneut.",
				type: "error",
			});
		}
	},

	getLabs: async () => {
		const organizationId = get().organization?.id;
		if (!organizationId) return;
		const { data, error } = await supabase
			.from(SupabaseTableEnum.CONNECT_RELATIONSHIPS)
			.select()
			.eq("med_id", organizationId);

		if (error) {
			Logger.error(error);
			return;
		}

		if (data.length === 0) {
			Logger.warn("No labs found for organization", organizationId);
			return;
		}
		const labsLookup = data.reduce((acc: Record<string, Lab>, row) => {
			acc[row.lab_id] = {
				name: row.lab_name ?? "",
				id: row.lab_id,
				connectRelationshipId: row.id,
				labOrganizationId: row.lab_id,
			};

			return acc;
		}, {});

		set({
			labsLookup,
		});
	},
	getConnectedProfiles: async () => {
		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.CONNECTED_PROFILES_FOR_MED)
			.select();
		if (error) {
			Logger.error(
				error,
				{},
				"Error fetching connected profiles for lab"
			);
			return;
		}

		const connectedProfiles = data.reduce((acc, row) => {
			if (row.profile_id) {
				acc[row.profile_id] = row;
			}
			return acc;
		}, get().connectedProfiles);

		set({
			connectedProfiles,
		});
	},
	getProfilesForChat: async (organizationId: string) => {
		// First get profiles from the organization
		const { data: profiles, error: profilesError } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.select("*")
			.eq("organization_id", organizationId);

		if (profilesError) {
			Logger.error(profilesError, {}, "Error fetching profiles");
			return;
		}

		// Get connected profiles with their emails from cr_with_profiles
		const { data: connectedProfiles, error } = await supabase
			.from(SupabaseShareEntityViewEnum.CONNECTED_PROFILES_FOR_MED)
			.select();
		if (error) {
			Logger.error(error, {}, "Error fetching connected profiles");
			return;
		}

		// Process organization profiles
		const orgProfilePromises = profiles.map(async (row) => ({
			profile_id: row.id ?? "",
			first_name: row.first_name ?? "",
			last_name: row.last_name ?? "",
			email: row.email ?? "",
			avatar_url: row.avatar_url
				? await getAvatarUrl(organizationId, row.avatar_url)
				: "",
		}));

		// Process connected profiles
		const connectedProfilePromises = connectedProfiles.map(async (row) => ({
			profile_id: row.profile_id ?? "",
			first_name: row.first_name ?? "",
			last_name: row.last_name ?? "",
			email: row.email ?? "",
			avatar_url: row.avatar_url
				? await getAvatarUrl(row.lab_id ?? "", row.avatar_url)
				: "",
		}));

		// Wait for all avatar URLs to be generated
		const [orgProfiles, connectedProfilesWithUrls] = await Promise.all([
			Promise.all(orgProfilePromises),
			Promise.all(connectedProfilePromises),
		]);

		const profilesForChat = [...orgProfiles, ...connectedProfilesWithUrls];

		const profilesLookup = profilesForChat.reduce(
			(acc, profile) => {
				acc[profile.profile_id] = profile;
				return acc;
			},
			{} as Record<string, ProfilesForChat>
		);

		set({
			profilesForChat: profilesLookup,
		});
	},
});
