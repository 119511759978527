import { FileWithShare, isShared } from "@/lib/supabase/supabaseTypes";
import { useState } from "react";
import { useStorage } from "../../hooks/useStorage";

import {
	DropdownMenu,
	DropdownMenuItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/dentlab/src/components/ui/dropdown-menu";
import { ShareFileButton } from "@/dentlab/src/components/sharing/share-button/share-file-button.component";
import { Check } from "lucide-react";
import clsx from "clsx";
import { useCentralStore } from "../../store/Central";
import { Tooltip } from "@mui/material";
import { FileIconComponent } from "../file-icon/file-icon.component";
import STLViewerDialog from "../stl-viewer";
import { getFileType } from "../file-icon/file-icon-utils";
import { getFileTooltip } from "./file-utils";

const isStl = (fileName: string) => {
	if (!fileName) {
		return false;
	}
	const extension = fileName.split(".").pop();
	if (!extension) {
		return false;
	}
	return extension.toLowerCase() === "stl";
};

/**
 * Displays a file with a dropdown menu that allows the user to open the file in a new tab or share it.
 * Recommended to use in a list with gap-1
 */
export const FileDropDown = ({
	file,
	onShareFile,
}: {
	file: FileWithShare;
	onShareFile: () => void;
}) => {
	const [openShareDialog, setOpenShareDialog] = useState(false);
	const { openFileInNewTab, downloadFile } = useStorage();
	const [stlUrl, setSTLURL] = useState<string | null>("");
	const { connectRelationshipId } = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
	}));
	return (
		<>
			<div className="flex gap-1 items-center hover:bg-neutral-100 px-2 rounded-sm">
				<FileIconComponent
					fileType={getFileType(file?.meta_data, file?.file_name)}
					color={isShared(file) ? "success" : undefined}
				/>
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<div
							className={clsx(
								"max-w-48 cursor-pointer text-ellipsis overflow-hidden whitespace-nowrap"
							)}
							title={getFileTooltip(file)}
						>
							{file.file_name}
						</div>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						<DropdownMenuItem
							onClick={() => {
								if (isStl(file.file_name ?? "")) {
									setSTLURL(file.path_name);
									return;
								}
								openFileInNewTab(file.path_name as string);
							}}
							className="flex justify-between items-center cursor-pointer"
						>
							{isStl(file.file_name ?? "")
								? "Öffnen"
								: "In neuem Tab öffnen"}
						</DropdownMenuItem>
						<DropdownMenuItem
							onClick={() =>
								downloadFile(file.path_name as string)
							}
							className="flex justify-between items-center cursor-pointer"
						>
							Herunterladen
						</DropdownMenuItem>
						{connectRelationshipId && (
							<DropdownMenuItem
								onClick={() => setOpenShareDialog(true)}
								className="flex justify-between items-center cursor-pointer"
							>
								Teilen
								{isShared(file) && (
									<div className="bg-green-500 rounded-full p-0.5">
										<Tooltip
											placement="right"
											title="Datei bereits geteilt"
										>
											<Check color={"white"} size={16} />
										</Tooltip>
									</div>
								)}
							</DropdownMenuItem>
						)}
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			{openShareDialog && (
				<ShareFileButton
					open={openShareDialog}
					setOpen={setOpenShareDialog}
					file={file}
					handleShare={onShareFile}
				/>
			)}
			{stlUrl && (
				<STLViewerDialog
					file={file}
					fileName={stlUrl}
					onClose={() => setSTLURL(null)}
				/>
			)}
		</>
	);
};
