import { Avatar, Badge } from "@mui/material";
import { stringAvatar } from "../../lib/utils/avatar-functions";
import { ClientEntityType } from "@/lib/supabase/supabaseTypes";

interface ClientAvatarProps {
	client: ClientEntityType & { image_src?: string };
	showConnectBadge?: boolean;
	size?: number;
}

export const ClientAvatar: React.FC<ClientAvatarProps> = ({
	client,
	showConnectBadge = false,
	size = 40,
}) => {
	const { sx, children } = stringAvatar(
		`${client.first_name} ${client.last_name}`
	);

	return (
		<Badge
			color="success"
			invisible={
				!showConnectBadge || client.connect_relationship_id === null
			}
			variant="dot"
			overlap="circular"
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			sx={{
				"& .MuiBadge-dot": {
					width: "12px",
					height: "12px",
					borderRadius: "50%",
					bottom: "3px",
					right: "3px",
				},
			}}
		>
			<Avatar
				style={{
					borderRadius: "0.7rem",
					outline: client.favorite ? "2px solid yellow" : "",
					outlineOffset: 3,
				}}
				sx={{
					width: `${size}px`,
					height: `${size}px`,
					...sx,
				}}
				id={client.id}
			>
				{client.image_src &&
				client?.image_src?.split("/")[0] != "public" ? (
					<img
						src={client.image_src}
						alt="Client Avatar"
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
				) : (
					children
				)}
			</Avatar>
		</Badge>
	);
};
