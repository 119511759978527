import clsx from "clsx";
import { getDayAndTime } from "../../lib/utils/utils-functions";
import { getMessagePreview } from "./message-preview";

export const ChatApp: React.FC<{
	children: React.ReactNode;
	className?: string;
}> = ({ children, className }) => {
	return (
		<div className={clsx("flex flex-row gap-1", className)}>{children}</div>
	);
};

export const ChatWindow: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	return (
		<div className="rounded-md w-full h-full">
			<div className="flex flex-col border border-gray-200 rounded-lg px-4 pb-4 shadow-sm h-full">
				{children}
			</div>
		</div>
	);
};

export const ChatPreviews: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	return (
		<div className="flex flex-col gap-2 bg-slate-100 rounded-md p-2 border-b border-gray-300 min-w-[200px] max-w-[40vw] overflow-y-auto">
			{children}
		</div>
	);
};

export const ChatPreviewCard: React.FC<{
	title: string;
	subtitle: string;
	messageAt: string;
	messageContent: string;
	isSender: boolean;
	active: boolean;
	onClick: () => void;
}> = ({
	title,
	subtitle,
	messageAt,
	messageContent,
	isSender,
	active,
	onClick,
}) => {
	return (
		<div
			className={clsx(
				"flex flex-col p-2 rounded-md cursor-pointer hover:bg-slate-100",
				active && "bg-white shadow-md cursor-default hover:bg-white"
			)}
			onClick={onClick}
		>
			<div className="flex flex-row justify-between">
				<span className="text-sm text-gray-900 font-bold">{title}</span>
				<span className="text-xs text-gray-500">
					{getDayAndTime(messageAt)}
				</span>
			</div>
			<span className="text-sm text-gray-700">{subtitle}</span>
			<div className="text-xs text-gray-500">
				{getMessagePreview(messageContent, isSender)}
			</div>
		</div>
	);
};
