import { AppBarButton } from "../app-bar-button/app-bar-button.component";
import { useNavigate, useParams } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton } from "@mui/material";
import "../../../../styles.css";
import { ClientManagementDialog } from "../../../../../pages-via-dialog/client-management/client-management.dialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ClientEntityType } from "../../../../../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { ClientList } from "@/dentlab/src/components/list-components/client-list/client-list.component";
import { useClientManagement } from "@/dentlab/src/hooks/useClientManagement";
import { ClientAvatar } from "@/dentlab/src/components/avatar/client-avatar.component";
import { useDimensions } from "@/lib/hooks/useDimensions";

export const AppBarClientsList: React.FC = () => {
	const navigate = useNavigate();
	const { clientId } = useParams();
	const { clients } = useCentralStore((state) => ({
		clients: Object.values(state.clientsLookup).sort((a, b) => {
			if (a.favorite && b.favorite) {
				return a.favorite > b.favorite ? -1 : 1;
			} else if (a.favorite) {
				return -1;
			} else if (b.favorite) {
				return 1;
			}

			if (!a.created_at || !b.created_at) return 0;
			return a.created_at > b.created_at ? -1 : 1;
		}),
	}));

	// Forces the component to re-render when the window is resized, so numberOfClients is recalculated
	const { measuredDivRef } = useDimensions();
	const numberOfClients = Math.floor((window.innerHeight - 350) / 60);

	return (
		<div
			className="app-bar-list"
			data-testid="app-bar-client-list"
			ref={measuredDivRef}
		>
			{clients
				.slice(0, numberOfClients)
				?.map((client: ClientEntityType & { image_src?: string }) => {
					return (
						<AppBarButton
							key={client.id}
							onClick={() => navigate(`/client/${client.id}`)}
							isSelected={clientId === client.id}
							tooltipTitle={`${client.first_name} ${client.last_name}`}
							dataTestId="app-bar-client-button"
						>
							<ClientAvatar 
								client={client} 
								showConnectBadge={true} 
								size={40}
							/>
						</AppBarButton>
					);
				})}
			{clients.length > 0 ? (
				<ClientTool
					Icon={MoreHorizIcon}
					dataTestId={"show-clients-management-dialog-button"}
				/>
			) : (
				<ClientTool Icon={AddCircleIcon} dataTestId="add-client-tool" />
			)}
		</div>
	);
};

interface ClientToolProps {
	Icon: React.ElementType;
	dataTestId: string;
}

const ClientTool: React.FC<ClientToolProps> = ({ Icon, dataTestId }) => {
	const {
		isClientManagementOpen,
		openDialog,
		handleOpen,
		handleOpenForm,
		handleClose,
		setOpenDialog,
	} = useClientManagement();

	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{ padding: "0" }}
				data-testid={dataTestId}
			>
				<Icon sx={{ color: "white", fontSize: "2rem" }} />
			</IconButton>
			<ClientList
				setOpen={setOpenDialog}
				open={openDialog}
				onClick={handleClose}
				openForm={handleOpenForm}
			/>
			<ClientManagementDialog
				open={isClientManagementOpen}
				onClose={handleClose}
			/>
		</>
	);
};
