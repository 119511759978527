import React from "react";
import {
	ActivityEntityType,
	JobEntityType,
	PatientWithShare,
} from "../../../lib/supabase/supabaseTypes";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Activity } from "./activity.component";
import { getDayAndTime } from "../../../dentlab/src/lib/utils/utils-functions";
import { ActivityIcon } from "./activity-icon.component";
import { ActivityType } from "../../../dentlab/src/types/types";
import { EmptyDataPlaceholder } from "../empty-with-label/empty-data-placeholder.component";

/**
 * ActivityHistory - Takes list of activities and renders them in an activity history
 * Provides some callbacks based on the users actions
 * @param activities
 * @param onJobClick
 * @param onPatientClick
 * // TODO: @param onFileClick
 */
export const ActivityHistory: React.FC<{
	activities: ActivityEntityType[];
	onJobClick: (job: JobEntityType) => void;
	onPatientClick: (patient: PatientWithShare) => void;
}> = ({ activities, onJobClick, onPatientClick }) => {
	if (!activities || activities.length === 0) {
		return <EmptyDataPlaceholder label="Keine Aktivitäten vorhanden" />;
	}
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "row",
			}}
		>
			<div
				style={{
					flex: 1,
					overflow: "auto",
				}}
			>
				<div
					style={{
						// for some reason this seems to fix a UI bug
						// TODO: fix root cause
						width: "500px",
					}}
				>
					<Timeline>
						{activities.map((activity) => {
							const date = getDayAndTime(activity.created_at);
							return (
								<TimelineItem key={activity.id}>
									<TimelineOppositeContent
										sx={{ m: "auto 0" }}
										align="right"
										variant="body2"
										color="text.secondary"
									>
										<p
											style={{
												// set fixed with to prevent UI bugs
												width: "140px",
											}}
										>
											{date}
										</p>
									</TimelineOppositeContent>
									<TimelineSeparator>
										<TimelineConnector />
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												paddingTop: "15px",
												paddingBottom: "15px",
												width: "20px",
											}}
										>
											<ActivityIcon
												activityType={
													activity.activity_type as ActivityType
												}
											/>
										</div>
										<TimelineConnector />
									</TimelineSeparator>

									<TimelineContent sx={{ py: "40px", px: 2 }}>
										<div>
											<Activity
												activity={activity}
												hideDate
												onJobClick={onJobClick}
												onPatientClick={onPatientClick}
											/>
										</div>
									</TimelineContent>
								</TimelineItem>
							);
						})}
					</Timeline>
				</div>
			</div>
		</div>
	);
};
