import { useRefContext } from "@/dentlab/src/context/RefContext";
import { calculateJobTotalsLegacy } from "@/dentlab/src/lib/utils/calculate";
import {
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { Button, Portal } from "@mui/material";
import { RightSidebar } from "../../job-page-components/right-sidebar.component";
import { CustomTextFieldWithPercentage } from "@/dentlab/src/forms/form-input-components/custom-input-components/custom-text-field-with-percentage.component";
import { useForm } from "@/dentlab/src/hooks/useForm/useForm";
import { Database } from "@/lib/supabase";
import { JobStatusEnum } from "@/lib/types/job";
import { useToast } from "@/dentlab/src/context/ToastContext";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const TotalSidebar: React.FC<{
	jobItems: JobItemEntityType[];
	jobDocument: JobDocumentEntityType;
	job: JobEntityType;
	onClose: () => void;
}> = ({ jobItems, job, jobDocument, onClose }) => {
	// Container for setting sidebars on the job page
	const { container } = useRefContext();
	const { organization, client } = useCentralStore((state) => ({
		organization: state.organization,
		client: state.client,
	}));
	const organizationId = organization?.id;
	const { showToast } = useToast();

	const { handleInputChange, handleUpdate, formData } = useForm<
		Database["public"]["Tables"][SupabaseTableEnum.JOB_DOCUMENTS]["Update"]
	>(
		{
			organization_id: organizationId,
		},
		SupabaseTableEnum.JOB_DOCUMENTS,
		true,
		jobDocument.id,
		"Dokument"
	);

	if (!organization) return null;
	if (!client) return null;

	const {
		total,
		mwst,
		amountMaterials,
		amountWorkAndFixedRate,
		deductions,
		taxpunkte,
		amountPostage,
		amountExternalWork,
		deductionsWorkAndFixedRate,
		deductionsMaterials,
		subTotal,
	} = calculateJobTotalsLegacy({
		job,
		jobDocument,
		jobItems,
		organization,
		client,
	});

	const rows = [
		{
			name: "Arbeit",
			value: amountWorkAndFixedRate.text,
			bold: true,
			borderBottom: false,
		},
		{
			name: "ArbeitTP",
			value: taxpunkte.text,
			bold: false,
			borderBottom: false,
		},
		{
			name: "Material",
			value: amountMaterials.text,
			bold: true,
			borderBottom: false,
		},
		{
			name: "Fremdarbeiten",
			value: amountExternalWork.text,
			bold: false,
			borderBottom: false,
		},
		{
			name: "Porto",
			value: amountPostage.text,
			bold: false,
			borderBottom: true,
		},
		{
			name: "Abzüge Arbeit",
			value: deductionsWorkAndFixedRate.text,
			bold: false,
			borderBottom: false,
		},
		{
			name: "Abzüge Material",
			value: deductionsMaterials.text,
			bold: false,
			borderBottom: false,
		},
		{
			name: "Abzüge Gesamt",
			value: deductions.text,
			bold: true,
			borderBottom: true,
		},
		{
			name: "Subtotal",
			value: subTotal.text,
			bold: false,
			borderBottom: false,
		},
		{
			name: "MwSt.",
			value: mwst.text,
			bold: false,
			borderBottom: false,
		},
		{
			name: "Total",
			value: total.text,
			bold: true,
		},
		// TODO: Verrechnet
		// TODO: Nicht verrechnet
	];

	const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (jobDocument.status !== JobStatusEnum.IN_PROGRESS) {
			showToast(
				"Rabatte können nur bei pendendten Aufträgen geändert werden",
				"error"
			);
			return;
		}

		const { success, data, error } = await handleUpdate(
			event,
			jobDocument.id
		);
		if (success && data) {
			// TODO: DW-703 Update job document global state
			window.location.reload();
		}
	};

	return (
		<Portal container={container.current}>
			<RightSidebar
				title="Total"
				onClose={onClose}
				aria-label="Sidebar to display calculated totals of job document"
			>
				<table style={{ margin: "20px" }}>
					<tbody
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "3px",
						}}
					>
						{rows.map((row) => (
							<tr
								key={row.name}
								style={{
									borderBottom: row.borderBottom
										? "1px solid #e0e0e0"
										: "none",
								}}
							>
								<td
									style={{
										width: "140px",
										fontWeight: row.bold
											? "bold"
											: "normal",
									}}
								>
									{row.name}
								</td>
								<td
									style={{
										fontWeight: row.bold
											? "bold"
											: "normal",
									}}
								>
									{row.value}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div
					style={{
						padding: "20px",
					}}
					aria-label="Form to update discounts on job document"
				>
					<CustomTextFieldWithPercentage
						label="Rabatt auf Tarifpositionen"
						name="discount_work"
						value={
							formData.discount_work === null ||
							formData.discount_work === undefined
								? ""
								: formData.discount_work
						}
						onChange={handleInputChange}
						fullWidth
						validationError={null}
					/>
					<CustomTextFieldWithPercentage
						label="Rabatt auf Materialien"
						name="discount_material"
						value={
							formData.discount_material === null ||
							formData.discount_material === undefined
								? ""
								: formData.discount_material
						}
						onChange={handleInputChange}
						fullWidth
						validationError={null}
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSave}
						sx={{ mt: 2 }}
					>
						Speichern
					</Button>
				</div>
			</RightSidebar>
		</Portal>
	);
};
