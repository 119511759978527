import { AppBar } from "./layout-components/app-bar/app-bar.component";
import { HeaderBar } from "./layout-components/header-bar/header-bar.component";
import "./styles.css";
import { Toaster } from "../components/ui/sonner";
import { Loading } from "@/components/src/animations/loading";
import { useEffect, useState } from "react";
import { useCentralStore } from "../store/Central";
import { useRealtimeStore } from "@/lib/store/Realtime";
import {
	ActivityEntityType,
	ActivityTypeEnum,
} from "@/lib/supabase/supabaseTypes";
import { showNotification } from "../store/Central/selectors";
import { EnterTotp } from "../pages/auth/enter-totp.page";
import { useNavigate } from "react-router-dom";
import IntercomSnippet from "../components/intercom";
import { MFADialog } from "../components/mfa/mfa-dialog.component";
import { useJobStore } from "../store/Jobs";
import { JobTabs } from "../components/job-tabs/job-tabs.component";
import { Tooltip } from "@mui/material";
import { JobDocumentTypeEnum } from "../pages/job-page/job-document/job-document.types";
import { jobDocumentTypeNamePresets } from "../store/Jobs/utils";

export const Layout: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const {
		loading,
		mfaDialogOpen,
		setMFADialog,
		mfaEnrolled,
		mfaAuthenticated,
		mfaVerified,
		clientId,
		analyticsEnabled,
	} = useCentralStore((state) => ({
		loading: !state.initialized,
		mfaDialogOpen: state.mfaDialogOpen,
		setMFADialog: state.setMFADialog,
		mfaVerified: state.mfaVerified,
		mfaAuthenticated: state.mfaAuthenticated,
		mfaEnrolled: state.mfaEnrolled,
		clientId: state.client?.id,
		analyticsEnabled: state.organization?.is_analytics,
		userId: state.userId,
	}));
	const navigate = useNavigate();
	const [last, setLast] = useState<ActivityEntityType | null>(null);

	const { subscribe, unsubscribe, activityId, activities } = useRealtimeStore(
		(state) => ({
			subscribe: state.subscribe,
			unsubscribe: state.unsubscribe,
			activityId: state.activityId,
			activities: Object.values(state.activitiesLookup).sort((a, b) => {
				if (a.last_reply_at && b.last_reply_at)
					return a.last_reply_at < b.last_reply_at ? 1 : -1;
				else return -1;
			}),
		})
	);

	useEffect(() => {
		if (!mfaAuthenticated) return;
		if (!clientId) return;
		const lastActivity = activities[0];
		if (!lastActivity || last?.id == lastActivity.id) return;

		// TODO: make sure the job was shared by the dentist
		if (lastActivity.activity_type == ActivityTypeEnum.SHARED_JOB_REQUEST) {
			showNotification({
				message: "Neuer Auftrag eingegangen.",
				type: "info",
				action: {
					label: "Anfrage öffnen",
					onClick: () =>
						navigate(
							`/client/${clientId}/request/${lastActivity.job_request_id}`
						),
				},
			});
			setLast(lastActivity);
		}
	}, [activities, mfaAuthenticated, last?.id, clientId, navigate]);

	useEffect(() => {
		if (!mfaAuthenticated) return;
		subscribe();

		return () => {
			unsubscribe();
		};
	}, [mfaVerified, mfaAuthenticated]);

	useEffect(() => {
		if (!analyticsEnabled) return;
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = true;
		script.innerHTML = `
			(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "o9tqxp6neg");
		`;
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, [analyticsEnabled]);

	if (mfaVerified && !mfaAuthenticated) {
		return <EnterTotp />;
	}

	if (loading || !mfaVerified) {
		return <Loading height="100vh" width="100vw" />;
	}
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				backgroundColor: "var(--primary-main)",
			}}
		>
			{(import.meta.env.MODE === "development" ||
				import.meta.env.MODE === "development_testing") && <IdInfo />}
			<IntercomSnippet />
			<HeaderBar
				mfaAlert={mfaEnrolled === false}
				onMfaAlertClick={() => setMFADialog(true)}
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<AppBar />
				<div className="layout-children">
					<JobTabs />
					{children}
					<Toaster />
				</div>
			</div>
			<MFADialog open={mfaDialogOpen} onOpen={setMFADialog} />
		</div>
	);
};

const IdInfo: React.FC = () => {
	const { userId, organizationId, clientId, connectRelationshipId } =
		useCentralStore((state) => ({
			userId: state.userId,
			organizationId: state.organization?.id,
			clientId: state.clientId,
			connectRelationshipId: state.connectRelationshipId,
		}));
	const { jobId, jobDocuments } = useJobStore((state) => ({
		jobId: state.job?.id,
		jobDocuments: Object.values(state.jobDocuments)
			.map((jobDocument) => ({
				id: jobDocument.id,
				type: jobDocument.type,
			}))
			.sort((a, b) => a.id - b.id),
	}));

	return (
		<div className="bg-white text-xs flex flex-row gap-2">
			<b>User:</b>
			{userId}
			<b>Org:</b>
			{organizationId}
			<b>Client:</b> {clientId}
			<b>ConnectRelationship:</b> {connectRelationshipId}
			<b>Job:</b> {jobId}
			<Tooltip
				title={
					<div>
						{jobDocuments.map((jobDocument) => (
							<div key={jobDocument.id}>
								{jobDocumentTypeNamePresets(
									jobDocument.type as JobDocumentTypeEnum
								)}
								: {jobDocument.id}
							</div>
						))}
					</div>
				}
			>
				<b>JobDocuments</b>
			</Tooltip>
		</div>
	);
};
