import {
	MessageType,
	SharedFileMessage,
} from "../../../../lib/store/Realtime/message.types";
import {
	JobRequestMessageV1,
	TextMessage,
} from "../../../../lib/store/Realtime/message.types";
import { Tables } from "@/lib/supabase/supabaseTypesImport";
import clsx from "clsx";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { getMessageContent } from "./message-preview";
import { getDayAndTime } from "../../lib/utils/utils-functions";
import { Avatar } from "@mantine/core";
import { ProfilesForChat } from "../../store/Central/types";
import { stringAvatar } from "../../lib/utils/avatar-functions";
import { useEffect, useState } from "react";
import { FileEntityTypeWithUrl, useStorage } from "../../hooks/useStorage";
import { FileIconComponent } from "../file-icon/file-icon.component";
import { getFileType } from "../file-icon/file-icon-utils";
import { Loader } from "@mantine/core";
import { Logger } from "../../../../lib/logger/Logger";
import { PdfPreview } from "../file-display-components/file-display.components";
import { supabase } from "../../../../lib/supabase";
import { IconButton } from "@mui/material";
import { ArrowDownToLine } from "lucide-react";

/**
 * @param messageAt: string in the format "2024-01-01T00:00:00Z" as in the database!
 */
export const Message: React.FC<{
	message: Tables<"messages">;
	messageOfCurrentUser: boolean;
	userName: string;
	messageAt: string;
	user: ProfilesForChat;
}> = ({ message, messageOfCurrentUser, userName, messageAt, user }) => {
	const messageContent = message?.content as string;
	if (!messageContent) return null;
	const content = getMessageContent(messageContent);
	const { sx, children } = stringAvatar(
		`${user.first_name} ${user.last_name}`
	);

	return (
		<div
			className={clsx(
				"p-2 rounded-lg shadow-sm border border-gray-100",
				messageOfCurrentUser
					? "bg-blue-100 ml-auto"
					: "bg-gray-100 mr-auto",
				"max-w-sm",
				"min-w-[240px]"
			)}
			style={{
				overflowWrap: "break-word",
			}}
		>
			<div className="flex items-center gap-2 mb-2">
				<div className="flex items-center gap-2">
					<Avatar
						style={{
							borderRadius: "0.7rem",
							outlineOffset: 3,
						}}
						sx={{
							width: `40px`,
							height: `40px`,
						}}
						id={user.profile_id}
					>
						{user.avatar_url &&
						user?.avatar_url?.split("/")[0] != "public" ? (
							<img
								src={user.avatar_url}
								alt="Client Avatar"
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
								}}
							/>
						) : (
							<span
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
									height: "100%",
									fontSize: "1.125rem",
									color: sx.bgcolor,
									backgroundColor: "white",
								}}
							>
								{children}
							</span>
						)}
					</Avatar>
				</div>
				<div className="text-gray-700 flex flex-col">
					<span className="font-bold">{userName}</span>
					<span className="text-xs text-gray-500">
						{getDayAndTime(messageAt)}
					</span>
				</div>
			</div>

			{content.type === MessageType.JOB_REQUEST_V1 ? (
				<JobRequestMessageV1Block content={content} />
			) : content.type === MessageType.TEXT ? (
				<TextMessageBlock content={content} />
			) : content.type === MessageType.SHARED_FILE ? (
				<SharedFileMessageBlock content={content} />
			) : content.type === MessageType.JOB_REQUEST_DENIED ? (
				<JobRequestDeniedBlock />
			) : content.type === MessageType.JOB_REQUEST_ACCEPTED ? (
				<JobRequestAcceptedBlock />
			) : (
				<div>Nachricht konnte nicht formatiert werden.</div>
			)}
		</div>
	);
};

const SharedFileMessageBlock: React.FC<{
	content: SharedFileMessage;
}> = ({ content }) => {
	const [file, setFile] = useState<FileEntityTypeWithUrl | null>(null);
	const [loading, setLoading] = useState(true);
	const [filePreviewUrl, setFilePreviewUrl] = useState<string | null>(null);
	const { fetchFile, openFileInNewTab, downloadFile } = useStorage();

	useEffect(() => {
		const loadFile = async () => {
			try {
				setLoading(true);

				const { data: fileData, error: dbError } = await supabase
					.from("files")
					.select("*")
					.eq("id", content.file_id)
					.single();

				if (dbError || !fileData) {
					throw new Error("Failed to fetch file data from database");
				}

				// Fetch file URL
				const { data, error } = await fetchFile(fileData);
				if (error) throw error;

				setFile(data as FileEntityTypeWithUrl);
				if (data?.url) {
					setFilePreviewUrl(data.url);
				}
			} catch (error) {
				Logger.error("Error loading file", {}, error);
			} finally {
				setLoading(false);
			}
		};

		if (content.file_id) {
			loadFile();
		}
	}, [content.file_id]);

	const isImage =
		file?.file_name && /\.(jpg|jpeg|png|gif|webp)$/i.test(file.file_name);
	const isPdf =
		!isImage || (file?.file_name && /\.pdf$/i.test(file.file_name));
	const isXml = file?.file_name && /\.xml$/i.test(file.file_name);
	const fileType = file ? getFileType(file.meta_data, file.file_name) : null;

	const handleOpenFile = () => {
		if (file?.path_name) {
			openFileInNewTab(file.path_name);
		}
	};

	const handleDownloadFile = () => {
		if (file?.path_name) {
			downloadFile(file.path_name);
		}
	};

	return (
		<div className="flex flex-col">
			{/* File message text */}
			{content.text && (
				<div className="text-sm text-gray-700 mb-2">{content.text}</div>
			)}

			{/* File preview card */}
			<div className="border border-gray-200 rounded-md overflow-hidden">
				{/* File preview area */}
				{loading ? (
					<div className="bg-gray-50 h-32 flex items-center justify-center">
						<Loader size="sm" />
					</div>
				) : (
					<div
						className="bg-gray-50 cursor-pointer"
						onClick={handleOpenFile}
					>
						{isImage && filePreviewUrl ? (
							<div className="h-32 overflow-hidden flex items-center justify-center">
								<img
									src={filePreviewUrl}
									alt={file?.file_name || "File preview"}
									className="max-h-full object-contain"
								/>
							</div>
						) : isPdf && filePreviewUrl ? (
							<div className="h-32 overflow-hidden flex items-center justify-center bg-gray-100">
								{/* PDF preview */}
								{file && !isXml && (
									<PdfPreview
										file={{ ...file, url: filePreviewUrl }}
									/>
								)}
							</div>
						) : (
							<div className="h-32 flex items-center justify-center">
								<div className="flex flex-col items-center justify-center">
									<FileIconComponent
										fileType={fileType}
										size={32}
									/>
									<span className="text-xs text-gray-500 mt-1">
										{file?.file_name ||
											"Unbekannter Dateiname"}
									</span>
								</div>
							</div>
						)}
					</div>
				)}
				{/* File info and actions */}
				<div className="p-3 bg-white">
					<div className="flex justify-between items-start">
						<div className="overflow-hidden">
							<div className="font-medium text-sm text-gray-900 truncate">
								{loading
									? ""
									: file?.file_name ||
										"Unbekannter Dateiname"}
							</div>
						</div>

						{/* Action buttons */}
						<div className="flex space-x-2">
							<IconButton
								onClick={handleDownloadFile}
								size="small"
							>
								<ArrowDownToLine size={18} />
							</IconButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const JobRequestMessageV1Block: React.FC<{
	content: JobRequestMessageV1;
}> = ({ content }) => {
	return (
		<div className="flex flex-col gap-3">
			<div className="text-lg font-semibold text-gray-700">
				Neue Auftragsanfrage
			</div>
			<div className="flex flex-col gap-2">
				<div className="flex items-start gap-2">
					<span className="text-sm font-medium text-gray-600">
						Datum:
					</span>
					<span className="text-sm text-gray-700">
						{content.incoming_order_date
							? new Date(
									content.incoming_order_date
								).toLocaleDateString("de-DE", {
									day: "2-digit",
									month: "2-digit",
									year: "numeric",
									hour: "2-digit",
									minute: "2-digit",
								})
							: "Kein Datum"}
					</span>
				</div>
				<div className="flex items-start gap-2">
					<span className="text-sm font-medium text-gray-600">
						Betreff:
					</span>
					<span className="text-sm text-gray-700">
						{content.title}
					</span>
				</div>

				<div className="flex items-start gap-2">
					<span className="text-sm font-medium text-gray-600">
						Beschreibung:
					</span>
					<span className="text-sm text-gray-700">
						{content.description}
					</span>
				</div>
				<div className="flex items-start gap-2">
					<span className="text-sm font-medium text-gray-600">
						Patient:
					</span>
					<span className="text-sm text-gray-700">
						{content?.patient_first_name}{" "}
						{content?.patient_last_name} (
						{content?.patient_code ?? "Kein Code"})
					</span>
				</div>
			</div>
		</div>
	);
};

const JobRequestDeniedBlock: React.FC = () => {
	return (
		<div className="text-sm font-semibold text-gray-700 gap-3">
			<p>...hat den Auftrag abgelehnt.</p>
		</div>
	);
};

const JobRequestAcceptedBlock: React.FC = () => {
	return (
		<div className="text-sm font-semibold text-gray-700 gap-3">
			<p>...hat den Auftrag akzeptiert.</p>
		</div>
	);
};

const TextMessageBlock: React.FC<{ content: TextMessage }> = ({ content }) => {
	// Sanitize HTML content before parsing
	// TODO: Sanitize everywhere
	const sanitizedText = DOMPurify.sanitize(content.text ?? "");
	return <div className="text-sm text-gray-900">{parse(sanitizedText)}</div>;
};
