import {
	ActivityEntityType,
	ActivityTypeEnum,
	FileEntityType,
} from "@/lib/supabase/supabaseTypes";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const filterAndMapActivities = (
	activities: ActivityEntityType[],
	allSharedFiles: (FileEntityType & {
		url?: string;
	})[],
	activityType: ActivityTypeEnum
) =>
	activities
		.filter((j) => j.activity_type === activityType)
		.map((j) => {
			const files = allSharedFiles.filter((f) => j.file_id === f.id);

			return {
				file: files?.[0],
				...j,
			};
		});
