import { useEffect, useState } from "react";
import { JobEntityType } from "@/lib/supabase/supabaseTypes";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "../../store/Central";
import { useRealtimeStore } from "@/lib/store/Realtime";
import { Button } from "@mui/material";
import { Loading } from "@/components/src/animations/loading";
import { ActivityHistory } from "../../../../components/src/activity-history/activity-history.component";

export const ConnectPageLegacy = () => {
	const navigate = useNavigate();
	const { connectRelationshipId } = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
	}));
	const { activities, fetchActivities, activityCount } = useRealtimeStore(
		(state) => ({
			activities: Object.values(state.activitiesLookup).sort((a, b) => {
				if (a.last_reply_at && b.last_reply_at)
					return a.last_reply_at < b.last_reply_at ? 1 : -1;
				else return -1;
			}),
			fetchActivities: state.fetchActivities,
			activityCount: state.activityCount,
		})
	);

	const [lastActivityCursor, setLastActivityCursor] = useState<number>(9);
	const [moreActivitiesLoading, setMoreActivitiesLoading] = useState(false);
	useEffect(() => {
		if (connectRelationshipId) {
			setMoreActivitiesLoading(true);
			fetchActivities(connectRelationshipId, lastActivityCursor).then(
				() => {
					setMoreActivitiesLoading(false);
				}
			);
		}
	}, [connectRelationshipId, lastActivityCursor]);

	const addMoreActivities = () => {
		setLastActivityCursor(lastActivityCursor + 20);
	};
	const activitiesLeft = activityCount && activityCount > activities.length;

	return (
		<div className="flex flex-col gap-4 w-full h-full">
			{connectRelationshipId && (
				<>
					<div className="w-full mb-0 min-h-full flex flex-col">
						<ActivityHistory
							key={connectRelationshipId}
							activities={activities}
							onPatientClick={() => {}}
							onJobClick={(job: JobEntityType) => {
								navigate(
									`/client/${job.client_id}/job/${job.id}`
								);
							}}
						/>

						{!moreActivitiesLoading ? (
							activitiesLeft && (
								<div className="mt-20 w-full justify-center mb-20 flex">
									<Button onClick={addMoreActivities}>
										20 ältere Aktivitäten anzeigen
									</Button>
								</div>
							)
						) : (
							<div className="w-full h-40 flex items-center justify-center">
								<Loading />
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};
