import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useMemo, useState } from "react";
import { TariffForm } from "./tariff-form.component";
import { FormProvider, useForm } from "react-hook-form";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { supabase } from "@/lib/supabase";
import { useCentralStore } from "../../store/Central";
import { Logger } from "@/lib/logger/Logger";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../store/Central/selectors";
import { PageTitle } from "@/components/src/page-title/page-title.component";

export const TariffDialog = () => {
	const { organization, getTariffs } = useCentralStore((state) => ({
		tariffs: Object.values(state.tariffs),
		organization: state.organization,
		getTariffs: state.getTariffs,
	}));
	const [openDialog, setOpenDialog] = useState(false);
	const navigate = useNavigate();

	const defaultValues = useMemo(
		() => ({
			code: "",
			code_e: "",
			description_de: "",
			description_fr: "",
			description_it: "",
		}),
		[]
	);

	const methods = useForm({
		defaultValues,
	});

	const handleCreateTariff = async (data: any) => {
		if (!organization) {
			Logger.error("Organization not found in TariffDialog");
			return;
		}
		const { data: newTariff, error } = await supabase
			.from(SupabaseTableEnum.TARIFFS)
			.insert({
				...data,
				organization_id: organization.id,
			})
			.select()
			.single();
		if (error) {
			Logger.error(error, {}, "Error creating tariff");
			showNotification({
				message: "Fehler beim Erstellen des Tarifs",
				type: "error",
			});
			return;
		}
		await getTariffs();
		setOpenDialog(false);
		showNotification({
			message: "Tarif erfolgreich erstellt",
			type: "success",
		});
		navigate(`/tariff/${newTariff.code_e}`);
	};

	const handleOpenDialog = () => {
		methods.reset(defaultValues);
		setOpenDialog(true);
	};

	return (
		<>
			<Button onClick={handleOpenDialog}>Neuer Tarif</Button>
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
				<DialogContent>
					<div className="p-4">
						<PageTitle title={[{ name: "Neuer Tarif" }]} />
						{openDialog && (
							<FormProvider {...methods}>
								<TariffForm onSubmit={handleCreateTariff} />
							</FormProvider>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDialog(false)}>
						Abbrechen
					</Button>
					<Button
						onClick={methods.handleSubmit(handleCreateTariff)}
						variant="contained"
						color="primary"
					>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
