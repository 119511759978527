import { JobStatusEnum } from "@/lib/types/job";

export const colors = {
	[JobStatusEnum.IN_PROGRESS]: "bg-blue-500",
	[JobStatusEnum.COMPLETED]: "bg-green-500",
	[JobStatusEnum.REQUESTED]: "bg-gray-500",
};

export const getAllDaysInRange = (start: Date, end: Date) => {
	const days = [];
	const currentDate = new Date(start);
	while (currentDate <= end) {
		days.push(new Date(currentDate));
		currentDate.setDate(currentDate.getDate() + 1);
	}
	return days;
};

export const formatDate = (date: Date) => {
	const options = { weekday: "short" };
	// @ts-expect-error - The options object is required
	const weekday = date.toLocaleDateString("de-CH", options);
	const day = date.getDate().toString().padStart(2, "0");
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	return `${weekday} ${day}.${month}`;
};

export const checkDateInRange = (
	start: Date,
	end: Date,
	checkDate: Date
): number | null => {
	// Normalize the dates to only consider the date part (ignoring time)
	const startDate = new Date(start.toDateString());
	const endDate = new Date(end.toDateString());
	const dateToCheck = new Date(checkDate.toDateString());

	// Compare the dates
	if (dateToCheck.getTime() === startDate.getTime()) {
		return -1;
	} else if (dateToCheck.getTime() === endDate.getTime()) {
		return 1;
	} else if (dateToCheck > startDate && dateToCheck < endDate) {
		return 0;
	} else {
		return null;
	}
};
