import { useNavigate } from "react-router-dom";
import { useState } from "react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Logger } from "../../../../../../lib/logger/Logger";
import { MenuItemButton } from "../../../../../../components/src/menu-item-button/menu-item-button.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { useCentralStore } from "@/dentlab/src/store/Central";
import InventoryIcon from "@mui/icons-material/Inventory";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";

export const ClientMenu = () => {
	const navigate = useNavigate();
	const { client } = useCentralStore((state) => ({
		client: state.client,
	}));

	const baseUrl = `/client/${client?.id}`;

	const createJob = useJobStore((state) => state.createJob);

	const handleCreateJob = async () => {
		const job = await createJob();
		// TODO:(Eyob, waiting for Zustand central store) Get tax rate from organization mwst_percentage
		Logger.log("handleCreateJob", { job });
		if (job) {
			navigate(`/client/${client?.id}/job/${job.id}`);
		}
	};

	const [newMessageDialogOpen, setNewMessageDialogOpen] = useState(false);

	const handleNewMessageDialogOpen = () => {
		setNewMessageDialogOpen(true);
	};

	const handleNewMessageDialogClose = () => {
		setNewMessageDialogOpen(false);
	};

	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					paddingTop: "6px",
					paddingBottom: "6px",
					gap: 0,
				}}
			>
				<MenuItemButton
					title="Neuer Auftrag"
					iconLeft={<AddCircleIcon fontSize="small" />}
					onClick={handleCreateJob}
				/>
				<MenuItemButton
					title="dentCONNECT"
					iconLeft={<QuestionAnswerIcon fontSize="small" />}
					onClick={() => navigate(`${baseUrl}/connect`)}
				/>
				<MenuItemButton
					title="Aktivitätshistorie"
					iconLeft={<ViewTimelineIcon fontSize="small" />}
					onClick={() => navigate(`${baseUrl}/activity-history`)}
				/>
				<MenuItemButton
					title="Rechnungen"
					iconLeft={<InsertDriveFileIcon fontSize="small" />}
					onClick={() =>
						navigate(`/accounting?recipient=${client?.id}`)
					}
				/>
				<MenuItemButton
					title="Archiv"
					iconLeft={<InventoryIcon fontSize="small" />}
					onClick={() => navigate(`${baseUrl}/archive`)}
				/>
			</div>
		</>
	);
};
