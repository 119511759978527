import { SelectProps } from "../../../form-types";
import {
	cityList,
} from "@lab/src/lib/constants";
import { VirtualizedAutocomplete } from "../../../../components/virtualized-autocomplete.component";

interface CitySelectProps extends Omit<SelectProps, "onChange"> {
	onChange: (value: { postalCode: string; city: string }) => void;
}

export const CitySelect: React.FC<CitySelectProps> = (props) => {
	const { onChange, value: parentValue } = props;

	const value = parentValue ?? "";

	const handleChange = (value: string) => {
		const postalCode = value.split(" ")[0];
		const city = value.split(" ").slice(1).join(" ");
		onChange({ postalCode, city });
	};

	return (
		<>
			<VirtualizedAutocomplete
				{...props}
				options={cityList}
				onChange={handleChange}
				label={"PLZ/ORT"}
				value={value}
			/>
		</>
	);
};
